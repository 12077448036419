import createElement from './create-element';

export const createCloseButton = (adWidth: number): HTMLElement => createElement(
      'div',
      {
        class: 'bordeaux-anchored-close',
        style: {
          position: 'absolute',
          // Adjust speed of close button position movement according to ad width
          right: `max(32px, calc((${adWidth - 58}px - 100vw) / 2 + 32px))`,
          top: '4px',
        },
        onClick: event => {
          if (event.currentTarget instanceof HTMLDivElement) {
            const container = event.currentTarget?.parentNode;
            container?.parentNode?.removeChild(container);
          }
        },
      },
      `
      <svg style="cursor: pointer; width: 14px; height: 14px; background-color: rgb(247, 247, 247); border-radius: 7px" xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M1442 3678 l-222 -223 447 -447 448 -448 -448 -448 -447 -447 222 -223 223 -222 447 447 448 448 448 -448 447 -447 223 222 222 223 -447 447 -448 448 448 448 447 447 -222 223 -223 222 -447 -447 -448 -448 -448 448 -447 447 -223 -222z"/>
        </g>
      </svg>
    `,
    );