import { z } from 'zod';
import {
  DynamicSlotDefinition,
  GeneratedSlotDefinition,
  SlotDefinition,
  SlotType,
} from '../../../types/ad-framework/slot/index.types';
import { slotSchema } from './zod-schema';
import { nativeCategories } from '../../..';

const adaptSlotConfig = ({
  properties,
  size,
  ...basicSlot
}: z.output<typeof slotSchema>):  
  | SlotDefinition
  | DynamicSlotDefinition
  | GeneratedSlotDefinition => ({
  ...(properties.config.master
    ? {
        master: properties.config.master,
      }
    : {}),
  ...basicSlot,
  nativeContent: properties.config.allowList.some(category => nativeCategories.includes(category)),
  companionBounds: properties.config.companionBounds,
  ignoreExclusion: properties.config.ignoreExclusion,
  adCategoryAllowList: properties.config.allowList,
  hideFromOtherScreens: properties.config.hideFromOtherScreens,
  fluid: properties.config.fluid,
  multiple: properties.config.multiple,
  ignoreErrors: properties.config.ignoreErrors,
  width: size.w,
  height: size.h,
  additionalAvoidance: properties.config.additionalAvoidance,
  style: properties.style,
  sponsoredSlotActivationDistanceOverride:
    properties.config.sponsoredSlotActivationDistanceOverride,
  label: properties.label.backgroundColor
    ? {
        ...properties.label,
        style: (properties.label.style || '').concat(
          ' backgroundColor: ',
          properties.label.backgroundColor,
        ),
      }
    : properties.label,
  ...(properties.config.dynamicRoot
    ? {
        dynamicConfig: {
          root: properties.config.dynamicRoot || '',
        },
      }
    : {}),
  ...(basicSlot.type === SlotType.GENERATED
    ? {
        generatedConfig: {
          containerStyle: properties.generatedContainerStyle,
          spacerStyle: properties.generatedSpacerStyle,
          maximumSlots: properties.config.generatedMaximumSlots,
          minimumSlots: properties.config.generatedMinimumSlots,
          endSelector: properties.config.generatedEndSelector,
          slotGap: properties.config.generatedSlotGap,
          slotHeight: properties.config.generatedSlotHeight,
        },
      }
    : {}),
});
export default adaptSlotConfig;
