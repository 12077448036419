import DataObject from '../state/data-object';
import { Ad } from "./ad/index.types";
import {
  ActorRefFromLogic,
  AnyActorRef,
  AnyEventObject,
  CallbackActorLogic,
  EventObject,
  MetaObject,
  NonReducibleUnknown,
  ParameterizedObject,
  ProvidedActor,
  StateMachine,
  StateSchema,
} from 'xstate';
import { AnyAdManagerEvent } from './events.types';

export interface AdServerAdvert {
  s: string;
  z: Array<string | Array<number>>;
  d: string;
}

export interface SlotCallbacks {
  impressionViewable: () => void;
  slotOnload: () => void;
  slotRenderEnded: (event: googletag.events.SlotRenderEndedEvent) => void;
  slotVisibilityChanged: (percentage: number) => void;
}

export enum AD_MANAGER_ACTIONS {
  CONFIGURE = 'CONFIGURE',
  ENABLE = 'ENABLE',
  LISTEN = 'LISTEN',
  GET_GOOGLETAG = 'GET_GOOGLETAG',
  DESTROY_ADS = 'DESTROY_ADS',
  CREATE_AD = 'CREATE_AD',
  ADD_AUCTION_TO_STACK = 'ADD_AUCTION_TO_STACK',
  SET_TARGETING = 'SET_TARGETING',
  REFRESH_AUCTION = 'REFRESH_AUCTION',
  MARK_REFRESH_START = 'MARK_REFRESH_START',
  MARK_AUCTION_DONE = 'MARK_AUCTION_DONE',
  LOG_ERROR = 'LOG_ERROR',
  INCREMENT_AUCTION_ID = 'INCREMENT_AUCTION_ID',
  CREATE_NEXT_AUCTION = 'CREATE_NEXT_AUCTION',
  REPORT_AUCTION_START = 'REPORT_AUCTION_START',
  MARK_ADS_IN_AUCTION = 'MARK_ADS_IN_AUCTION',
  REPORT_AUCTION_CREATED = 'REPORT_AUCTION_CREATED',
  POP_AUCTION_STACK = 'POP_AUCTION_STACK',
}
export enum AD_MANAGER_STATES {
  CHECK_REQUIREMENTS = 'CHECK_REQUIREMENTS',
  CONFIGURATION = 'CONFIGURATION',
  ERROR = 'ERROR',
  WAIT_FOR_AUCTION = 'WAIT_FOR_AUCTION',
  AUCTION = 'AUCTION',
}
export enum AD_MANAGER_GUARDS {
  GOOGLETAG_EXISTS = 'GOOGLETAG_EXISTS',
  AUCTIONS_IN_STACK = 'AUCTIONS_IN_STACK',
  HAS_ADS = 'HAS_ADS',
}
export enum AD_MANAGER_ACTORS {
  WAIT_FOR_GOOGLE_TAG = 'WAIT_FOR_GOOGLE_TAG',
  GOOGLE_TAG_LISTENER = 'GOOGLE_TAG_LISTENER',
}

export interface AdManagerMachineInput {
  privacySettings?: {
    restrictDataProcessing?: boolean;
  };
  publisherProvidedId?: string;
}
export interface AdManagerMachineContext extends AdManagerMachineInput {
  googletag: googletag.Googletag;
  googletagListener: GoogletagListenerActor;
  gptSlots: Record<string, googletag.Slot>;
  auctionId: number;
  auctionStack: Array<Array<DataObject<Ad>>>;
  auctions: Record<
    string,
    {
      ads: Array<DataObject<Ad>>;
      id: number;
      ended: boolean;
    }
  >;
  error: string | null;
}

export type AdManagerMachineDefinition = StateMachine<
  AdManagerMachineContext,
  AnyAdManagerEvent,
  Record<string, AnyActorRef | undefined>,
  ProvidedActor,
  ParameterizedObject,
  ParameterizedObject,
  string,
  AD_MANAGER_STATES,
  string,
  AdManagerMachineInput,
  NonReducibleUnknown,
  EventObject,
  MetaObject,
  StateSchema
>;

export type GoogletagListenerActor = ActorRefFromLogic<
  CallbackActorLogic<
    AnyEventObject,
    {
      googletag: googletag.Googletag;
    },
    AnyAdManagerEvent
  >
>;
