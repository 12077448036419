import {
  Ad,
  AD_MANAGER_GUARDS,
  AdManagerGuards,
  AdManagerMachineContext,
  AnyAdManagerEvent,
  DataObject,
} from '@repo/shared-types';
import { getEnv } from '@repo/utils';
import { GuardPredicate } from 'xstate/guards';

const adManagerGuards: Record<
  AD_MANAGER_GUARDS,
  GuardPredicate<AdManagerMachineContext, AnyAdManagerEvent, any, AdManagerGuards>
> = {
  [AD_MANAGER_GUARDS.GOOGLETAG_EXISTS]: () => {
    const env = getEnv();
    const { googletag } = env;
    return Boolean(googletag);
  },
  [AD_MANAGER_GUARDS.AUCTIONS_IN_STACK]: ({ context: { auctionStack } }) =>
    auctionStack.length !== 0,
  [AD_MANAGER_GUARDS.HAS_ADS]: (_, ads: Array<DataObject<Ad>>) => ads.length !== 0,
};

export default adManagerGuards;
