import {
  AD_MANAGER_ACTIONS,
  AD_MANAGER_ACTORS,
  AdManagerMachineContext,
  AdManagerMachineDefinition,
  AdManagerMachineInput,
  GoogletagListenerActor,
  AD_MANAGER_GUARDS,
  AD_MANAGER_STATES,
  AD_MANAGER_EVENTS_IN,
  AD_MANAGER_EVENTS_OUT,
  AnyAdManagerEvent,
  GOOGLETAG_LISTENER_EVENTS,
  SlotImpressionViewableEvent,
  SlotOnLoadEvent,
  SlotRenderEndedEvent,
  SlotVisibilityChangedEvent,
} from '@repo/shared-types';
import { log } from '@repo/utils';
import { assign, enqueueActions, sendParent, setup, stateIn } from 'xstate';
import adManagerActions from './actions';
import adManagerGuards from './guards';
import adManagerActors from './actors';

const adManagerMachine: AdManagerMachineDefinition = setup({
  types: {
    context: {} as AdManagerMachineContext,
    events: {} as AnyAdManagerEvent,
    input: {} as AdManagerMachineInput,
  },
  actors: {
    ...adManagerActors,
  },
  actions: {
    ...adManagerActions,
  },
  guards: {
    ...adManagerGuards,
  },
}).createMachine({
  context: ({ input }) => ({
    googletag: {} as googletag.Googletag,
    googletagListener: {} as GoogletagListenerActor,
    gptSlots: {},
    auctionId: 0,
    auctionStack: [],
    auctions: {},
    error: null,
    ...input,
  }),
  initial: AD_MANAGER_STATES.CHECK_REQUIREMENTS,
  states: {
    [AD_MANAGER_STATES.CHECK_REQUIREMENTS]: {
      invoke: {
        src: AD_MANAGER_ACTORS.WAIT_FOR_GOOGLE_TAG,
        onDone: [
          {
            guard: AD_MANAGER_GUARDS.GOOGLETAG_EXISTS,
            actions: AD_MANAGER_ACTIONS.GET_GOOGLETAG,
            target: AD_MANAGER_STATES.CONFIGURATION,
          },
          {
            actions: assign({ error: 'googletag is unavailable, unable to initialise.' }),
            target: AD_MANAGER_STATES.ERROR,
          },
        ],
      },
    },
    [AD_MANAGER_STATES.CONFIGURATION]: {
      always: {
        actions: [
          AD_MANAGER_ACTIONS.CONFIGURE,
          AD_MANAGER_ACTIONS.LISTEN,
          AD_MANAGER_ACTIONS.ENABLE,
        ],
        target: AD_MANAGER_STATES.WAIT_FOR_AUCTION,
      },
    },
    [AD_MANAGER_STATES.WAIT_FOR_AUCTION]: {
      always: {
        guard: AD_MANAGER_GUARDS.AUCTIONS_IN_STACK,
        target: AD_MANAGER_STATES.AUCTION,
      },
    },
    [AD_MANAGER_STATES.AUCTION]: {
      always: {
        actions: [
          AD_MANAGER_ACTIONS.INCREMENT_AUCTION_ID,
          AD_MANAGER_ACTIONS.CREATE_NEXT_AUCTION,
          AD_MANAGER_ACTIONS.REPORT_AUCTION_START,
          AD_MANAGER_ACTIONS.MARK_ADS_IN_AUCTION,
          enqueueActions(
            ({
              enqueue,
              context: {
                auctionStack: [ads],
              },
            }) => {
              ads.forEach(ad => {
                enqueue({
                  type: AD_MANAGER_ACTIONS.CREATE_AD,
                  params: { ad },
                });
              });
            },
          ),
          AD_MANAGER_ACTIONS.REPORT_AUCTION_CREATED,
          AD_MANAGER_ACTIONS.POP_AUCTION_STACK,
        ],
        target: AD_MANAGER_STATES.WAIT_FOR_AUCTION,
      },
    },
    [AD_MANAGER_STATES.ERROR]: {
      entry: AD_MANAGER_ACTIONS.LOG_ERROR,
    },
  },
  on: {
    [AD_MANAGER_EVENTS_IN.AUCTION_PROCESSED]: {
      actions: [
        {
          type: AD_MANAGER_ACTIONS.REFRESH_AUCTION,
          params: ({
            event: {
              data: { auctionId },
            },
          }) => auctionId,
        },
        {
          type: AD_MANAGER_ACTIONS.MARK_AUCTION_DONE,
          params: ({
            event: {
              data: { auctionId },
            },
          }) => auctionId,
        },
      ],
    },
    [AD_MANAGER_EVENTS_IN.SET_TARGETING]: {
      actions: {
        type: AD_MANAGER_ACTIONS.SET_TARGETING,
        params: ({
          event: {
            data: { targeting },
          },
        }) => targeting,
      },
    },
    [AD_MANAGER_EVENTS_IN.AUCTION]: [
      {
        guard: stateIn(AD_MANAGER_STATES.WAIT_FOR_AUCTION),
        actions: {
          type: AD_MANAGER_ACTIONS.ADD_AUCTION_TO_STACK,
          params: ({
            event: {
              data: { ads },
            },
          }) => ({ ads }),
        },
        target: `.${AD_MANAGER_STATES.AUCTION}`,
      },
      {
        actions: {
          type: AD_MANAGER_ACTIONS.ADD_AUCTION_TO_STACK,
          params: ({
            event: {
              data: { ads },
            },
          }) => ({ ads }),
        },
      },
    ],
    [AD_MANAGER_EVENTS_IN.REFRESH]: [
      {
        guard: {
          type: AD_MANAGER_GUARDS.HAS_ADS,
          params: ({
            event: {
              data: { ads },
            },
          }) => ads,
        },
        actions: [
          {
            type: AD_MANAGER_ACTIONS.MARK_REFRESH_START,
            params: ({
              event: {
                data: { ads },
              },
            }) => ({ ads }),
          },
          {
            type: AD_MANAGER_ACTIONS.DESTROY_ADS,
            params: ({
              event: {
                data: { ads },
              },
            }) => ({ advertIds: ads.map(ad => ad.getProperty('id')) }),
          },
          {
            type: AD_MANAGER_ACTIONS.ADD_AUCTION_TO_STACK,
            params: ({
              event: {
                data: { ads },
              },
            }) => ({ ads }),
          },
        ],
      },
      {
        actions: () => {
          log.error('Called GAM API refresh with no ads.');
        },
      },
    ],
    [GOOGLETAG_LISTENER_EVENTS.SLOT_VISIBILITY_CHANGED]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: AD_MANAGER_EVENTS_OUT.SLOT_VISIBILITY_CHANGED,
            data,
          }) as SlotVisibilityChangedEvent,
      ),
    },
    [GOOGLETAG_LISTENER_EVENTS.SLOT_RENDER_ENDED]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: AD_MANAGER_EVENTS_OUT.SLOT_RENDER_ENDED,
            data,
          }) as SlotRenderEndedEvent,
      ),
    },
    [GOOGLETAG_LISTENER_EVENTS.SLOT_ON_LOAD]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: AD_MANAGER_EVENTS_OUT.SLOT_ON_LOAD,
            data,
          }) as SlotOnLoadEvent,
      ),
    },
    [GOOGLETAG_LISTENER_EVENTS.SLOT_IMPRESSION_VIEWABLE]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: AD_MANAGER_EVENTS_OUT.SLOT_IMPRESSION_VIEWABLE,
            data,
          }) as SlotImpressionViewableEvent,
      ),
    },
  },
});

export default adManagerMachine;
