import { EventObject,
  ActionArgs,
  DoneActorEvent,
  ErrorActorEvent,
} from 'xstate';

type CMPStatus = 'stub' | 'loading' | 'loaded' | 'error';

export interface PingReturn {
  gdprApplies?: boolean;
  cmpLoaded: boolean;
  cmpStatus: CMPStatus;
  displayStatus: 'visible' | 'hidden' | 'disabled';
  apiVersion: string;
  cmpVersion?: number;
  cmpId?: number;
  gvlVersion?: number;
  tcfPolicyVersion?: number;
  mocked?: boolean;
}

export interface GDPRDataResponse {
  tcString: string;
  tcfPolicyVersion: 2;
  cmpVersion?: number;
  cmpId?: number;
  gdprApplies?: boolean;
  eventStatus: 'tcloaded' | 'cmpuishown' | 'useractioncomplete';
  cmpStatus: CMPStatus;
  listenerId?: number;
  isServiceSpecific: boolean;
  useNonStandardStacks: boolean;
  publisherCC: string;
  purposeOneTreatment: boolean;
  outOfBand: {
    allowedVendors: Record<number, boolean>;
    disclosedVendors: Record<number, boolean>;
  };
  purpose: {
    consents: Record<number, boolean>;
    legitimateInterests: Record<number, boolean>;
  };
  vendor: {
    consents: Record<number, boolean>;
    legitimateInterests: Record<number, boolean>;
  };
  specialFeatureOptins: Record<number, boolean>;
  publisher: {
    consents: Record<number, boolean>;
    legitimateInterests: Record<number, boolean>;
    customPurpose: {
      consents: Record<number, boolean>;
      legitimateInterests: Record<number, boolean>;
    };
    restrictions: {
      [key: number]: {
        [key: number]: 0 | 1 | 2;
      };
    };
  };
}

export interface NonIabVendorConsentsResponse {
  gdprApplies: boolean;
  metadata: string;
  nonIabVendorConsents: object;
}
export enum GDPRConsentStatus {
  'NOT_APPLICABLE' = 'NOT APPLICABLE',
  'ACCEPT' = 'ACCEPT ALL',
  'LEGITIMATE_INTEREST' = 'LEGITIMATE INTEREST',
  'REJECT_ALL' = 'REJECT ALL',
}

export interface GDPRConsent {
  done: boolean;
  askedForConsent: boolean;
  consent: GDPRDataResponse | null;
  status: GDPRConsentStatus;
  hasEnoughConsentForAuction: boolean;
}

export enum USPConsentStatus {
  'NOT_APPLICABLE' = 'NOT APPLICABLE',
  'TRUE' = 'TRUE',
  'FALSE' = 'FALSE',
}
export interface USPDataResponse {
  version: number;
  uspString: string;
}

export interface USPConsent {
  done: boolean;
  askedForConsent: boolean;
  consent: USPDataResponse | null;
  ccpaApplies: boolean;
  status: USPConsentStatus;
}

export type __tcfapi = '__tcfapi';
export type __uspapi = '__uspapi';
export type __gpp = '__gpp';

export type TCFAPIEventTypeMap = {
  /**
   * @deprecated The method should not be used
   */
  getTCData: (data: GDPRDataResponse, success: boolean) => void;
  ping: (data: PingReturn) => void;
  addEventListener: (data: GDPRDataResponse, success: boolean) => void;
  removeEventListener: (success: boolean) => void;
  displayConsentUi: () => void;
  getNonIABVendorConsents: (data: NonIabVendorConsentsResponse, success: boolean) => void;
};

export type TCFAPI = <EventType extends keyof TCFAPIEventTypeMap>(
  event: EventType,
  version: 2,
  callback: TCFAPIEventTypeMap[EventType],
) => void;

export type USPAPI = (
  event: 'getUSPData',
  version: 1,
  callback: (data: USPDataResponse, success: boolean) => void,
) => void;

export interface GPPPingReturn {
  gppVersion: string; // must be “Version.Subversion”, current: “1.1”
  cmpStatus: string; // possible values: stub, loading, loaded, error
  cmpDisplayStatus: string; // possible values: hidden, visible, disable
  signalStatus: string; // possible values: not ready, ready
  supportedAPIs: Array<string>; // list of supported APIs (section ids and prefix strings), e.g. used while loading. Example: ["2:tcfeuv2","6:uspv1"]
  cmpId: number; // IAB assigned CMP ID, may be 0 during stub/loading. Reference the above CMP ID section for additional information.
  sectionList: Array<number>; // may be empty during loading of the CMP
  applicableSections: Array<number>; // Section ID considered to be in force for this transaction. In most cases, this field should have a single section ID. In rare occasions where such a single section ID can not be determined, the field may contain up to 2 values. During the transition period which ends on September 30, 2023, the legacy USPrivacy section may be determined as applicable along with another US section. In this case, the field may contain up to 3 values where one of the values is 6, representing the legacy USPrivacy section. The value can be 0 or a Section ID specified by the Publisher / Advertiser, during stub / load. When no section is applicable, the value will be [-1].
  gppString: string; // the complete encoded GPP string, may be empty during CMP load
  parsedSections: object; // The parsedSections property represents an object of all parsed sections of the gppString property that are supported by the API on this page (see supportedAPIs property). The object contains one property for each supported API with the name of the API as the property name and the value as a parsed representation of this section (similar to getSection command). If a section is supported but not represented in the gppString, it is omitted in the parsedSections object.
  mocked?: boolean;
}

export type GPPDataResponse = {
  eventName: string;
  listenerId: number;
  data: string | boolean;
  pingData: GPPPingReturn;
};

export type GPPAPICommandTypeMap = {
  ping: (data: GPPPingReturn, success: boolean) => void;
  addEventListener: (data: GPPDataResponse, success: boolean) => void;
};

export type GPPAPI = <CommandType extends keyof GPPAPICommandTypeMap>(
  commmand: CommandType,
  callback: GPPAPICommandTypeMap[CommandType],
  parameter?: string | number,
) => void;

export enum GPPConsentStatus {
  'NOT_APPLICABLE' = 'NOT APPLICABLE',
  'NULL_PAYLOAD' = 'NULL_PAYLOAD',
  'TRUE' = 'TRUE',
  // 'FALSE' = 'FALSE',
}
export interface GPPConsent {
  done: boolean;
  // askedForConsent: boolean;
  consent: GPPDataResponse | null;
  status: GPPConsentStatus;
}

export interface ConsentMachineContext {
  tcfapi: TCFAPI;
  result: GDPRConsent;
}

export enum GDPR_CMP_EVENTS {
  PENDING = 'GDPR_CMP_PENDING',
  MOCKED = 'GDPR_CMP_MOCKED',
  LOADED = 'GDPR_CMP_LOADED',
  FAILURE = 'GDPR_CMP_FAILURE',
}
export enum CMP_EVENTS {
  PENDING = 'CMP_PENDING',
  LOADED = 'CMP_LOADED',
  MOCKED = 'CMP_MOCKED',
}

export interface GDPRCmpPendingEvent extends EventObject {
  type: GDPR_CMP_EVENTS.PENDING;
}
export interface GDPRCmpLoadedEvent extends EventObject {
  type: GDPR_CMP_EVENTS.LOADED;
}
export interface GDPRCmpMockedEvent extends EventObject {
  type: GDPR_CMP_EVENTS.MOCKED;
}
export interface GDPRCmpFailedEvent extends EventObject {
  type: GDPR_CMP_EVENTS.FAILURE;
  data: Error;
}

export type GDPRLoadedEvent =
  | GDPRCmpPendingEvent
  | GDPRCmpLoadedEvent
  | GDPRCmpMockedEvent
  | GDPRCmpFailedEvent;

export interface CmpPendingEvent extends EventObject {
  type: CMP_EVENTS.PENDING;
}
export interface CmpLoadedEvent extends EventObject {
  type: CMP_EVENTS.LOADED;
}
export interface CmpMockedEvent extends EventObject {
  type: CMP_EVENTS.MOCKED;
}

export type AnyCmpOutEvent = CmpPendingEvent | CmpLoadedEvent | CmpMockedEvent;

export enum CMP_STATES {
  GET_TCF_API = 'GET_TCF_API',
  LOAD_API = 'LOAD_API',
  RETRIEVE_CONSENT = 'RETRIEVE_CONSENT',
  FINISHED = 'FINISHED',
}
export enum CMP_ACTORS {
  GET_TCF_API = 'GET_TCF_API',
  GET_GDPR_LOADED_MACHINE = 'GET_GDPR_LOADED_MACHINE',
  RETRIEVE_GDPR_CONSENT_MACHINE = 'RETRIEVE_GDPR_CONSENT_MACHINE',
}

export type AnyConsentEvent = DoneActorEvent<TCFAPI> | GDPRLoadedEvent | ErrorActorEvent<Error>;
export type ConsentMachineActionArgs<E extends AnyConsentEvent = AnyConsentEvent> = ActionArgs<
  ConsentMachineContext,
  E,
  AnyConsentEvent
>;