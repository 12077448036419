import { mergeDeepWith, concat } from 'ramda';
import metrics from 'metrics';
import userSync from 'user-sync';
import {
  containsExperiment,
  containsVariant,
  getVariant,
  sendUserIdEventToFreyr,
  waitForJwPLayer,
  log,
  performAdBlockTest,
  getEnv,
  getParameters,
  getRefreshTargetingValues,
  hasUserOptOutCCPA,
  sentry,
  checkAdUnitCountries,
  loadAdTool,
  timeData,
  trimSlashes,
  querySelectorAll,
} from '@repo/utils';
import enqueueActions from '../proxy/enqueueActions';
import { fallbackAction, parsedFallback } from 'config';

import {
  ActionArgs,
  ACTIONS,
  ACTORS,
  BordeauxMachineContext,
  GUARDS,
  REPORT,
  AuctionTimeouts,
  CftParameters,
  CompanionBounds,
  DataObject,
  DeviceAvoidanceDistance,
  FallbackAdServerResponses,
  GDPRConsent,
  GPPConsent,
  GPTOutput,
  HybridABTest,
  REPORT_AUCTION,
  RoadblockIncrementalCaps,
  RoadblockIncrementalChooser,
  SiteConfig,
  ThirdParty,
  TimeData,
  USPConsent,
  Ad,
  AdDefinition,
  AdUnitMode,
  AdUnitStatus,
  EVENTS,
  FEATURE,
  SetHybridABTestTargetingEvent,
  SetRoadblockEvent,
  ReportEvent,
  FullAPISetupResults,
  API_EVENTS_IN,
  INCREMENTAL_ADS_EVENTS_IN,
  IncrementalAdsRoadblockStatusEvent,
  REFRESH_EVENTS,
  AnyBordeauxEvent,
  BordeauxActions,
  BordeauxGuards,
  SlotPosition,
} from '@repo/shared-types';

import adServer from 'third-party-apis/ad-server';
import amClio from 'third-party-apis/am-clio';
import amazon from 'third-party-apis/amazon';
import euid from 'third-party-apis/euid';
import gpt from 'third-party-apis/gpt';
import ias from 'third-party-apis/ias';
import indexExchange from 'third-party-apis/index-exchange';
import liveRamp from 'third-party-apis/liveramp';
import prebid from 'third-party-apis/prebid';
import pubmatic from 'third-party-apis/pubmatic';
import pubx from 'third-party-apis/pubx';
import tmt from 'third-party-apis/the-media-trust';

import expandHeight from 'ad-features/expand-height';
import fullWidth from 'ad-features/full-width';
import multiFrame from 'ad-features/multi-frame';
import popOut, { cancelPopOut, enablePopOut } from 'ad-features/pop-out';
import getRoadblockStatus from 'ad-framework/roadblock';
import closeButton from 'ad-features/close-button';
import getTargeting, { getHybridAbTestTargeting } from 'ad-framework/targeting';
import { mergeConfigs } from 'third-party-apis/merge-configs';
import assign, { assignParams } from '../proxy/assign';
import raise from '../proxy/raise';
import sendTo from '../proxy/send-to';
import * as report from '../report';
import { SommelierResponse } from 'config/sommelier-request/sommelier-response.types';
import { BordeauxConfig } from '@repo/shared-types/src/zod-schemas';
import { getAdsToRefresh } from 'ad-framework/refresh/automatic/action';
import { EMPTY_OUTPUT } from '@repo/shared-types/src/types/ad-framework/empty-output';
import { createAdLabel } from 'ad-framework/ad/create-ad-label';
import { AD_MANAGER_EVENTS_IN } from '@repo/shared-types/src/types/ad-framework/events.types';
import raiseParams from '../proxy/raise-params';
import { ActionFunction } from 'xstate';
import handleHybridAbTestsIfAny from './handle-hybrid-ab-tests-if-any';

export const setupCustomActivations = assign({
  unrefreshableLineItems: ({ context }) => {
    const adRefreshDisabled = mergeDeepWith(
      concat,
      context.config.features.customActivations.AD_REFRESH_DISABLED,
      context.config.features.customActivations.ROADBLOCK,
    );
    if (adRefreshDisabled.LINE_ITEM) {
      return adRefreshDisabled.LINE_ITEM;
    }
    return context.unrefreshableLineItems;
  },
  unrefreshableOrders: ({ context }) => {
    const adRefreshDisabled = mergeDeepWith(
      concat,
      context.config.features.customActivations.AD_REFRESH_DISABLED,
      context.config.features.customActivations.ROADBLOCK,
    );
    if (adRefreshDisabled.ORDER) {
      return adRefreshDisabled.ORDER;
    }
    return context.unrefreshableOrders;
  },
  unrefreshableAdvertisers: ({ context }) => {
    const adRefreshDisabled = mergeDeepWith(
      concat,
      context.config.features.customActivations.AD_REFRESH_DISABLED,
      context.config.features.customActivations.ROADBLOCK,
    );
    if (adRefreshDisabled.ADVERTISER) {
      return adRefreshDisabled.ADVERTISER;
    }
    return context.unrefreshableAdvertisers;
  },
});

export const decideRefreshTime = enqueueActions(() => {
  /** Noop - no special refresh time currently in use */
});

export const setupUserSync = ({ context }: ActionArgs): void => {
  userSync(context);
};

export const handleError = (): void => {
  metrics.recordFrameworkCrash();
};

export const sendUserIdsToFreyr = ({ context }: ActionArgs): void => {
  const hybridId = [{ name: 'hybridId', id: context.hybridId }];
  const emailHash = context.queryParameters.lrh
    ? [{ name: 'email_sha256', id: context.queryParameters.lrh }]
    : [];

  sendUserIdEventToFreyr([...hybridId, ...emailHash]);
};

const TEST_KEYS = ['hybridTestID', 'hybridQATestID'];
type VariantValueMap = {
  [variantId: string]: { mobile: number; tablet: number; desktop: number };
};
const variantValues: VariantValueMap = {
  '25|95': { mobile: 3000, tablet: 3000, desktop: 3000 },
  '25|96': { mobile: 3500, tablet: 3500, desktop: 3500 },
  '25|97': { mobile: 4000, tablet: 4000, desktop: 4000 },
  '25|98': { mobile: 4500, tablet: 4500, desktop: 4500 },
  '25|99': { mobile: 5000, tablet: 5000, desktop: 5000 },
  '25|100': { mobile: 5500, tablet: 5500, desktop: 5500 },
  '25|101': { mobile: 6000, tablet: 6000, desktop: 6000 },
  '25|102': { mobile: 6500, tablet: 6500, desktop: 6500 },
  '25|103': { mobile: 7000, tablet: 7000, desktop: 7000 },
};

export const storeHybridTestSessions = ({ context }: ActionArgs): void => {
  Object.entries(context.config.targeting)
    .filter(([key]) => TEST_KEYS.includes(key))
    .forEach(([, value]) => {
      sessionStorage.setItem('force_abtest', typeof value === 'string' ? value : value.join(','));
    });
};

export const decideTestAuctionTimeouts = assign({
  auctionTimeouts: ({ context }): BordeauxMachineContext['auctionTimeouts'] => {
    const variantEntry = Object.entries(context.sommelierResponse.targeting || {})
      .reverse()
      .find(
        ([key, value]) =>
          TEST_KEYS.includes(key) &&
          ((typeof value === 'string' && variantValues[value]) ||
            (Array.isArray(value) && value.some(v => v in variantValues))),
      );
    if (!variantEntry) return context.auctionTimeouts;

    const variantValue = variantEntry[1];
    return typeof variantValue === 'string'
      ? variantValues[variantValue]
      : variantValues[variantValue.find(v => v in variantValues) as string];
  },
});

export const decidePubxAbTest = assign({
  thirdPartyApiConfigOverrides: ({
    context,
  }): BordeauxMachineContext['thirdPartyApiConfigOverrides'] => {
    if (containsExperiment(context.config, '50')) {
      return {
        ...context.thirdPartyApiConfigOverrides,
        pubx: {
          enabled: containsVariant(context.config, '50', '170'),
        },
      };
    } else if (containsExperiment(context.config, '41')) {
      return {
        ...context.thirdPartyApiConfigOverrides,
        pubx: {
          enabled: containsVariant(context.config, '41', '140'),
        },
      };
    } else {
      return context.thirdPartyApiConfigOverrides;
    }
  },
});

export const decideActivationDistanceTest = assign({
  activationDistance: ({ context }): BordeauxMachineContext['activationDistance'] => {
    if (containsExperiment(context.config, '49')) {
      const variant = getVariant(context.config, '49');
      switch (variant) {
        case '160':
          return 600;
        case '161':
          return 2000;
        default:
          return context.activationDistance;
      }
    } else {
      return context.activationDistance;
    }
  },
});

export const decideV2ActivationDistanceTest = assign({
  activationDistance: ({ context }): BordeauxMachineContext['activationDistance'] => {
    if (containsExperiment(context.config, '53')) {
      const variant = getVariant(context.config, '53');
      switch (variant) {
        case '179':
          return 1200;
        case '180':
          return 800;
        case '181':
          return 700;
        case '182':
          return 600;
        case '183':
          return 500;
        case '184':
          return 400;
        case '185':
          return 300;
        default:
          return context.activationDistance;
      }
    } else {
      return context.activationDistance;
    }
  },
});

export const decideIdsActivationTest = assign({
  thirdPartyApiConfigOverrides: ({
    context,
  }): BordeauxMachineContext['thirdPartyApiConfigOverrides'] => {
    if (containsExperiment(context.config, '43')) {
      const variant = getVariant(context.config, '43');
      switch (variant) {
        case '142':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: false,
            },
          };
        case '143':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: true,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: false,
            },
          };
        case '144':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: true,
            },
          };
        case '145':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: true,
            },
            uid2: {
              enabled: false,
            },
          };
        case '146':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: true,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: false,
            },
          };
        case '147':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: true,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: true,
            },
            liveIntent: {
              enabled: true,
            },
            uid2: {
              enabled: true,
            },
          };
        default:
          return context.thirdPartyApiConfigOverrides;
      }
    } else {
      return context.thirdPartyApiConfigOverrides;
    }
  },
});

export const customVideoBehaviourAction = ({
  context: {
    ads,
    config,
    pageParameters: { site },
  },
}: ActionArgs): void => {
  const allowedSites = ['marieclairecom-progressive', 'whowhatwear-progressive'];
  if (!allowedSites.includes(site)) {
    return;
  }

  const {
    features: { customActivations },
  } = config;

  waitForJwPLayer()
    .then(jw => {
      const gptOutputs = ads
        .getValues()
        .map(ad => ad.getProperty('gptOutput'))
        .filter(gptOutput => !!gptOutput);

      const isLineItemVideoStickyDisabled = gptOutputs
        .map(gptOutput => gptOutput.lineItem)
        .some(lineItem =>
          customActivations.VIDEO_STICKY_AUTOPLAY_DISABLED.LINE_ITEM?.includes(lineItem),
        );

      const isAdvertiserVideoStickyDisabled = gptOutputs
        .map(gptOutput => gptOutput.advertiser)
        .some(advertiser =>
          customActivations.VIDEO_STICKY_AUTOPLAY_DISABLED.ADVERTISER?.includes(advertiser),
        );

      const isOrderVideoStickyDisabled = gptOutputs
        .map(gptOutput => gptOutput.campaign)
        .some(order => customActivations.VIDEO_STICKY_AUTOPLAY_DISABLED.ORDER?.includes(order));

      const disableStatus =
        isLineItemVideoStickyDisabled ||
        isAdvertiserVideoStickyDisabled ||
        isOrderVideoStickyDisabled;

      if (disableStatus) {
        jw().remove();
      }
    })
    .catch(err => {
      log.warn(err);
    });
};

const actions: Record<
  ACTIONS,
  ActionFunction<
    BordeauxMachineContext,
    AnyBordeauxEvent,
    AnyBordeauxEvent,
    any,
    any,
    BordeauxActions,
    BordeauxGuards,
    any,
    any
  >
> = {
  [ACTIONS.CREATE_API_MACHINE]: assign({
    externalApiMachine: ({ spawn }) => spawn(ACTORS.SETUP_API),
  }),
  [ACTIONS.CREATE_AD_FEATURE_MACHINE]: assign({
    adFeatureMachine: ({ spawn }) => spawn(ACTORS.LISTEN_FOR_AD_FEATURES),
  }),
  [ACTIONS.INITIALISE_FEATURES]: assign({
    featuresInitialised: true,
  }),

  [ACTIONS.CHECK_AD_BLOCK]: assign({
    adBlocked: await performAdBlockTest(),
  }),
  [ACTIONS.READ_QUERY_PARAMETERS]: assign({
    queryParameters: () => {
      const env = getEnv();
      const query = new URLSearchParams(env.location.search);
      return {
        country: query.get('force_locale') || query.get('force_country') || query.get('CC'),
        debugTool: query.get('debug_ads'),
        forceRoadblock: query.get('force_roadblock'),
        forceTargeting: query.get('force_targeting'),
        sommelierUrl: query.get('sommelier_url'),
        forcePLCDB: query.get('force_plc_db'),
        forceABTestControl: query.get('force_abtest_control'),
        forceABTestVariant: query.get('force_abtest_variant'),
        lrh: query.get('lrh'),
      };
    },
  }),
  [ACTIONS.READ_PAGE_PARAMETERS]: assign({
    pageParameters: getParameters,
  }),
  [ACTIONS.CREATE_SHAMEFUL_MACHINE]: assign({
    arbitraryEventEmitter: ({ context, spawn }) =>
      spawn(ACTORS.ARBITRARY_EVENT_EMITTER, {
        input: { pageParameters: context.pageParameters },
      }),
  }),
  [ACTIONS.ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE]: ({ context }) => {
    const env = getEnv();
    // Allows index exchange to pick the right kind of ads depending on the current device
    env.indexExchangeDeviceType = context.pageParameters.device;
  },
  [ACTIONS.ASSIGN_BORDEAUX_ADS_PROMISE]: () => {
    const env = getEnv();
    // Resolve if page is using Bordeaux ads
    env.bordeauxAds = Promise.resolve(true);
  },
  [ACTIONS.CHECK_MULTIPLE_SCRIPTS]: enqueueActions(({ check, enqueue }) => {
    if (check(GUARDS.DUPLICATE_SCRIPTS)) enqueue(REPORT.MULTIPLE_SCRIPTS);
  }),
  [ACTIONS.CREATE_ADTOOL_TAP_OPEN_MACHINE]: assign({
    adToolTapOpenMachine: ({ spawn }) =>
      spawn(ACTORS.ELEMENT_TAP_HANDLER, {
        input: {
          element: document.body,
          fingers: 2,
          repetitions: 10,
        },
      }),
  }),
  [ACTIONS.CHECK_ADTOOL_PARAM]: enqueueActions(({ check, enqueue }) => {
    if (check(GUARDS.AD_TOOL_OPENED_FROM_URL)) enqueue(ACTIONS.RAISE_OPEN_AD_TOOL);
  }),
  [ACTIONS.CREATE_AUTOMATIC_REFRESH_MACHINE]: assign({
    automaticRefreshMachine: ({ spawn }) => spawn(ACTORS.INIT_AUTOMATIC_REFRESH),
  }),
  [ACTIONS.REPORT_IF_AD_BLOCKED]: enqueueActions(({ check, enqueue }) => {
    if (check(GUARDS.ADS_BLOCKED)) enqueue(REPORT.AD_BLOCKED);
  }),

  [ACTIONS.USE_PARSED_FALLBACK_CONFIG]: assign({
    config: parsedFallback,
  }),
  [ACTIONS.USE_FALLBACK_CONFIG]: assign({
    sommelierResponse: fallbackAction,
  }),

  [ACTIONS.RAISE_OPEN_AD_TOOL]: raise({
    type: EVENTS.OPEN_AD_TOOL,
  }),
  [ACTIONS.THIRD_PARTIES_READY]: sendTo<BordeauxMachineContext['externalApiMachine']>(
    ({ context }) => context.externalApiMachine,
    {
      type: API_EVENTS_IN.THIRD_PARTIES_READY,
    },
  ),
  [ACTIONS.DECIDE_AVOIDANCE_DISTANCE]: assign({
    avoidanceDistance: ({ context }) => {
      const configAvoidanceDistance =
        context.config.placement.settings.adDensity?.avoidanceDistance;
      const apiAvoidanceDistanceConfig = context.deviceAvoidanceDistance;

      if (apiAvoidanceDistanceConfig) {
        const { device } = context.pageParameters;
        return apiAvoidanceDistanceConfig[device];
      }
      if (configAvoidanceDistance === undefined) {
        return context.avoidanceDistance;
      }
      return configAvoidanceDistance;
    },
  }),
  [ACTIONS.DECIDE_TEST_ACTIVATION_DISTANCE]: decideActivationDistanceTest,
  [ACTIONS.DECIDE_V2_TEST_ACTIVATION_DISTANCE]: decideV2ActivationDistanceTest,
  [ACTIONS.DECIDE_TEST_PUBX]: decidePubxAbTest,
  [ACTIONS.DECIDE_TEST_ID_SERVICE_ACTIVATION]: decideIdsActivationTest,
  [ACTIONS.DECIDE_THIRD_PARTY_CONFIG]: assign({
    thirdPartyApiConfig: ({ context }) =>
      mergeConfigs(context.config.thirdPartyAPIConfig, context.thirdPartyApiConfigOverrides),
  }),
  [ACTIONS.DECIDE_LIVE_INTENT_USER_SYNC]: assign({
    liveIntentUserSyncEnabled: ({ context }) =>
      context.thirdPartyApiConfig.liveIntent.enabled && Math.random() < 0.9,
  }),
  [ACTIONS.DECIDE_PAGE_ADUNIT_PATH]: assign({
    pageAdUnitPath: ({ context }) =>
      `/${[
        context.config.placement.siteAdUnitPath,
        ...(!context.pageCategory ? [] : [context.pageCategory]),
        context.config.placement.adUnitPath,
      ]
        .map(trimSlashes)
        .join('/')}`,
  }),
  [ACTIONS.INITIALISE_SENTRY]: report.initialiseSentry,
  [ACTIONS.SEND_AB_TEST_TO_FREYR]: handleHybridAbTestsIfAny,
  [ACTIONS.SETUP_CUSTOM_ACTIVATIONS]: setupCustomActivations,
  [ACTIONS.DECIDE_REFRESH_TIME]: decideRefreshTime,
  [ACTIONS.SETUP_USER_SYNC]: setupUserSync,
  [ACTIONS.SETUP_AD_MANAGER]: assign({
    adManager: ({ spawn, context: { hybridId, uspConsent } }) =>
      spawn(ACTORS.AD_MANAGER_MACHINE, {
        id: 'adManager',
        input: {
          privacySettings: { restrictDataProcessing: hasUserOptOutCCPA(uspConsent) },
          ...(hybridId
            ? {
                publisherProvidedId: hybridId,
              }
            : {}),
        },
      }),
  }),
  [ACTIONS.HANDLE_ERROR]: handleError,
  [ACTIONS.ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING]: enqueueActions(({ check, enqueue }) => {
    if (check(GUARDS.PREBID_ENABLED)) {
      if (check(GUARDS.LIVE_INTENT_USER_SYNC_ENABLED)) {
        enqueue({
          type: ACTIONS.UPDATE_PAGE_TARGETING,
          params: {
            'li-module-enabled': ['on'],
          },
        });
      } else {
        enqueue({
          type: ACTIONS.UPDATE_PAGE_TARGETING,
          params: {
            'li-module-enabled': ['off'],
          },
        });
      }
    }
  }),
  [ACTIONS.STORE_HYBRID_TEST_SESSIONS]: storeHybridTestSessions,
  [ACTIONS.DECIDE_TEST_AUCTION_TIMEOUTS]: decideTestAuctionTimeouts,

  [ACTIONS.DECIDE_ROADBLOCK_INCREMENTALS]: assign({
    roadblockIncrementals: ({
      context: {
        roadblockIncrementalChooser,
        adUnits: { incremental: adDefinitions },
      },
    }) => {
      const incrementalChooser = roadblockIncrementalChooser;
      return adDefinitions.filter((ad: AdDefinition): boolean => {
        if (incrementalChooser) {
          const apiChooserOverride = incrementalChooser(ad);
          if (apiChooserOverride === false) return false;
          if (apiChooserOverride === true) return true;
        }
        return Boolean(ad.roadblockIncremental);
      });
    },
  }),
  [ACTIONS.DECIDE_VALID_ADUNITS]: assign({
    adUnits: ({
      context: {
        pageParameters: { country },
        config: {
          placement: { adUnits },
        },
      },
    }) => ({
      standard: adUnits.standard.filter(checkAdUnitCountries(country.toLowerCase())),
      incremental: adUnits.incremental.filter(checkAdUnitCountries(country.toLowerCase())),
    }),
  }),
  [ACTIONS.HIDE_ANCHORED]: ({ context }) => {
    context.ads
      .getValues()
      .filter(ad => ad.getProperty('mode') === AdUnitMode.ANCHORED)
      .forEach(ad => {
        const element = ad.getProperty('elements')?.outerContainer;
        if (element) {
          element.style.transform = 'translate(0, 100%)';
          element.style.transition = '.5s';
        }
      });
  },
  [ACTIONS.SHOW_ANCHORED]: ({ context }) => {
    context.ads
      .getValues()
      .filter(ad => ad.getProperty('mode') === AdUnitMode.ANCHORED)
      .forEach(ad => {
        const element = ad.getProperty('elements')?.outerContainer;
        if (element) {
          element.style.transform = 'translate(0, 0)';
          element.style.transition = '.5s';
        }
      });
  },
  [ACTIONS.AD_MANAGER_AUCTION]: sendTo('adManager', ({}, ads: Array<DataObject<Ad>>) => ({
    type: AD_MANAGER_EVENTS_IN.AUCTION,
    data: {
      ads: ads.sort((a, b) => a.getProperty('requestOrder') - b.getProperty('requestOrder')),
    },
  })),
  [ACTIONS.UPDATE_AD_MANAGER_TARGETING]: sendTo('adManager', ({ context }) => ({
    type: AD_MANAGER_EVENTS_IN.SET_TARGETING,
    data: {
      targeting: getTargeting(context),
    },
  })),
  [ACTIONS.PRE_PROCESS_AUCTION]: assignParams({
    adPreProcessors: ({ spawn, context: { adPreProcessors } }, auctionId: number) => ({
      ...adPreProcessors,
      [auctionId]: spawn(ACTORS.AD_PRE_PROCESSOR, {
        input: ({
          context,
          event: {
            data: { auctionId, ads },
          },
        }) => ({
          context,
          auctionId,
          ads,
        }),
      }),
    }),
  }),

  [ACTIONS.RAISE_AD_MANAGER_AUCTION_PROCESSED]: sendTo(
    'adManager',
    (_, { auctionId }: { auctionId: number }) => ({
      type: AD_MANAGER_EVENTS_IN.AUCTION_PROCESSED,
      data: {
        auctionId,
      },
    }),
  ),
  [ACTIONS.RAISE_AUCTION_END]: raiseParams<{ auctionId: number }>(
    (_, { auctionId }: { auctionId: number }) => ({
      type: REPORT_AUCTION.END,
      data: {
        time: timeData(),
        auction: auctionId,
      },
    }),
  ),
  [ACTIONS.RAISE_CHECK_ROADBLOCK_STATUS]: raise({
    type: EVENTS.CHECK_ROADBLOCK_STATUS,
  }),
  [ACTIONS.FINALISE_AD]: (
    { context: { ads } },
    { adId, gptOutput }: { adId: string; gptOutput: GPTOutput | typeof EMPTY_OUTPUT },
  ) => {
    const env = getEnv();
    const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
    if (!ad) {
      return;
    }
    sentry.breadcrumb({
      category: 'advert',
      message: `Ad slotRenderEnded - ${ad.getProperty('name')}`,
    });
    metrics.mark(`Ad slotRenderEnded - ${ad.getProperty('name')}`);

    if (gptOutput !== EMPTY_OUTPUT) {
      const loadTime = env.performance.now();
      ad.update({
        status: AdUnitStatus.DELIVERED,
        loadTime,
        gptOutput,
      });
      const adLabel = ad.getProperty('label');
      if (adLabel) {
        const adLabelElement = createAdLabel(ad.getProperty('id'), adLabel);
        const adElement = ad.getProperty('elements')?.element;

        if (adElement) {
          adElement.insertBefore(adLabelElement, adElement.firstChild);
        }
      }
    } else {
      ad.update({ status: AdUnitStatus.UNDELIVERED });
    }
  },
  [ACTIONS.MARK_AD_VIEWED]: ({ context: { ads } }, { adId }: { adId: string }) => {
    const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
    if (!ad) {
      return;
    }
    ad.update({ viewed: true, status: AdUnitStatus.VIEWED });
  },
  [ACTIONS.RAISE_REPORT_AD_LOAD]: raiseParams<{ adId: string }>(
    ({ context: { ads } }, { adId }: { adId: string }) => ({
      type: REPORT_AUCTION.AD_LOAD,
      data: {
        time: timeData(),
        auction: ads
          .getValues()
          .find(ad => ad.getProperty('id') === adId)!
          .getProperty('auctionId')!,
      },
    }),
  ),
  [ACTIONS.REPORT_AD_LOAD]: ({ context: { ads } }, { adId }: { adId: string }) => {
    const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
    if (!ad) {
      return;
    }
    sentry.breadcrumb({
      category: 'advert',
      message: `Ad slotOnLoad - ${ad.getProperty('name')}`,
    });
    metrics.mark(`Ad slotOnLoad - ${ad.getProperty('name')}`);
    const auctionId = ad.getProperty('auctionId');
    if (!auctionId) {
      log.error('Error handling GPT slot loaded, auctionId is undefined.');
      return;
    }
  },
  [ACTIONS.UPDATE_AD_VIEWABILITY]: (
    { context: { ads } },
    { adId, inViewPercentage }: { adId: string; inViewPercentage: number },
  ) => {
    const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
    if (!ad) {
      return;
    }

    ad.update({ inView: inViewPercentage >= 50 });
    ad.update({ inViewport: inViewPercentage > 0 });
    ad.update({ inView75Percent: inViewPercentage > 75 });
  },
  [ACTIONS.DISABLE_ANCHORED_REFRESH]: assign({
    anchoredRefreshDisabled: false,
  }),
  [ACTIONS.ENABLE_ANCHORED_REFRESH]: assign({
    anchoredRefreshDisabled: true,
  }),
  [ACTIONS.UPDATE_ADS_VIEWED_TIME]: ({ context: { ads } }) => {
    ads.getValues().forEach(ad => {
      if (ad.getProperty('inView')) {
        const viewedTime = ad.getProperty('viewedTime') || 0;
        ad.update({ viewedTime: viewedTime + 1 });
      }
    });
  },
  [ACTIONS.RAISE_AUTOMATIC_REFRESH]: raise(({ context }) => ({
    type: EVENTS.AUTOMATIC_REFRESH,
    data: getAdsToRefresh(context),
  })),
  [ACTIONS.RESET_ADS]: (_, { ads }: { ads: Array<DataObject<Ad>> }) => {
    ads.forEach(ad => {
      const targeting = ad.getProperty('targeting');
      ad.update({
        targeting: {
          ...targeting,
          ...getRefreshTargetingValues(ad, false),
        },
        inView: false,
        viewedTime: 0,
      });
    });
  },
  [ACTIONS.REFRESH_ADS]: sendTo('adManager', (_, { ads }: { ads: Array<DataObject<Ad>> }) => ({
    type: AD_MANAGER_EVENTS_IN.REFRESH,
    data: { ads },
  })),
  [ACTIONS.UPDATE_REFRESH_ROADBLOCK]: sendTo(
    ({ context }) => context.automaticRefreshMachine,
    (_, roadblock: boolean) => ({
      type: REFRESH_EVENTS.SET_ROADBLOCK,
      data: roadblock,
    }),
  ),
  [ACTIONS.UPDATE_SLOTIFY_ROADBLOCK]: sendTo(
    'slotify',
    (_, roadblock: boolean): IncrementalAdsRoadblockStatusEvent => ({
      type: INCREMENTAL_ADS_EVENTS_IN.ROADBLOCK_STATUS,
      data: roadblock,
    }),
  ),
  [ACTIONS.UPDATE_API_ROADBLOCK]: sendTo(({ context }) => context.externalApiMachine, {
    type: API_EVENTS_IN.ROADBLOCK_READY,
  }),
  [ACTIONS.SET_ROADBLOCK]: assignParams<boolean>({
    isRoadblock: (_, roadblock: boolean) => roadblock,
  }),
  [ACTIONS.CUSTOM_VIDEO_BEHAVIOUR]: customVideoBehaviourAction,
  [ACTIONS.LOAD_AD_TOOL]: loadAdTool,
  [ACTIONS.REPORT_PAGE_LOAD]: report.pageLoad,
  [ACTIONS.SET_CFT_PARAMATERS]: assignParams<CftParameters>({
    cftParameters: (_, cftParameters: CftParameters) => cftParameters,
  }),
  [ACTIONS.REPORT_PAGE_UNLOAD]: report.pageUnload,
  [ACTIONS.SET_HYBRID_ID]: assignParams<string>({
    hybridId: (_, hybridId: string) => hybridId,
  }),
  [ACTIONS.UPDATE_API_HYBRID_ID]: sendTo(({ context }) => context.externalApiMachine, {
    type: API_EVENTS_IN.HYBRID_ID_READY,
  }),
  [ACTIONS.SEND_USER_IDS_TO_FREYR]: sendUserIdsToFreyr,
  [ACTIONS.SET_HYBRID_AB_TEST_TARGETING]: assignParams<Array<HybridABTest>>({
    hybridABTestTargeting: (_, hybridABTestTargeting: Array<HybridABTest>) => hybridABTestTargeting,
  }),
  [ACTIONS.AD_FEATURE_EXPAND_HEIGHT]: expandHeight,
  [ACTIONS.AD_FEATURE_FULL_WIDTH]: fullWidth,
  [ACTIONS.AD_FEATURE_POP_OUT]: popOut,
  [ACTIONS.AD_FEATURE_MULTI_FRAME]: multiFrame,
  [ACTIONS.AD_FEATURE_CLOSE_BUTTON]: closeButton,
  [ACTIONS.DECIDE_HYBRID_AB_TEST_TARGETING]: raise(
    ({ context }): SetHybridABTestTargetingEvent => ({
      type: EVENTS.SET_HYBRID_ABTEST_TARGETING,
      data: getHybridAbTestTargeting(context),
    }),
  ),
  [ACTIONS.START_ANCHORED_LOGIC]: assign({
    anchoredMachine: ({ spawn, context }) =>
      spawn(ACTORS.ANCHORED_LOGIC, {
        input: {
          slots: context.slots,
          ads: context.ads,
          config: context.config,
          pageParameters: context.pageParameters,
        },
      }),
  }),
  [ACTIONS.SET_PAGE_STYLE_CONSTANTS]: assignParams<BordeauxMachineContext['pageStyleConstants']>({
    pageStyleConstants: (_, pageStyleConstants: BordeauxMachineContext['pageStyleConstants']) =>
      pageStyleConstants,
  }),
  [ACTIONS.DECIDE_ROADBLOCK_STATUS]: raise(
    ({ context }): SetRoadblockEvent => ({
      type: EVENTS.SET_ROADBLOCK_STATUS,
      data: getRoadblockStatus(context) as boolean,
    }),
  ),
  [ACTIONS.SET_GDPR_CONSENT]: assignParams<GDPRConsent>({
    gdprConsent: (_, gdprConsent: GDPRConsent) => gdprConsent,
  }),
  [ACTIONS.SET_GPP_CONSENT]: assignParams<GPPConsent>({
    gppConsent: (_, gppConsent: GPPConsent) => gppConsent,
  }),
  [ACTIONS.SET_USP_CONSENT]: assignParams<USPConsent>({
    uspConsent: (_, uspConsent: USPConsent) => uspConsent,
  }),
  [ACTIONS.UPDATE_PAGE_TARGETING]: assignParams<BordeauxMachineContext['pageTargeting']>({
    pageTargeting: ({ context }, pageTargeting) => ({
      ...context.pageTargeting,
      ...pageTargeting,
    }),
  }),
  [ACTIONS.UPDATE_API_CONFIG]: sendTo<BordeauxMachineContext['externalApiMachine']>(
    ({ context }) => context.externalApiMachine,
    {
      type: API_EVENTS_IN.CONFIG_READY,
    },
  ),
  [ACTIONS.SET_CONFIG]: assignParams<BordeauxConfig>({
    config: (_, config): BordeauxConfig => config,
  }),
  [ACTIONS.RAISE_CONSENT_DONE]: raise({
    type: EVENTS.CONSENT_DONE,
  }),
  [ACTIONS.REPORT_CONFIG_FAILURE]: raiseParams<Error>(
    (_, error: Error): ReportEvent<REPORT.CONFIG_FAILURE> => ({
      type: REPORT.CONFIG_FAILURE,
      data: {
        time: timeData(),
        error,
      },
    }),
  ),
  [ACTIONS.SET_SOMMELIER_RESPONSE]: assignParams<SommelierResponse>({
    sommelierResponse: (_, sommelierResponse: SommelierResponse): SommelierResponse =>
      sommelierResponse,
  }),
  [ACTIONS.REPORT_CONTENT_LOAD]: raiseParams<TimeData>(
    (_, time: TimeData): ReportEvent<REPORT.CONTENT_LOAD> => ({
      type: REPORT.CONTENT_LOAD,
      data: { time },
    }),
  ),
  [ACTIONS.CREATE_PAGE_EVENT_EMITTER]: assign({
    pageEventEmitter: ({ spawn }) => spawn(ACTORS.PAGE_EVENT_EMITTER),
  }),
  [ACTIONS.DECIDE_TIMING_ENABLED]: assign({
    timing: ({ context }) => ({
      ...context.timing,
      enabled: Math.random() < context.timing.sampleRate,
    }),
  }),
  [ACTIONS.UPDATE_API_READY]: sendTo(({ context }) => context.externalApiMachine, {
    type: API_EVENTS_IN.API_READY,
  }),
  [ACTIONS.CREATE_THIRD_PARTY_MACHINES]: assign({
    thirdPartyMachines: ({ spawn, context }) => {
      const bordeaux: Pick<
        BordeauxMachineContext,
        | 'config'
        | 'liveIntentUserSyncEnabled'
        | 'gdprConsent'
        | 'loadGptExternallyPromise'
        | 'loadGptExternally'
        | 'thirdPartyApiConfig'
        | 'sommelierResponse'
        | 'pageTargeting'
      > = {
        config: context.config,
        gdprConsent: context.gdprConsent,
        liveIntentUserSyncEnabled: context.liveIntentUserSyncEnabled,
        thirdPartyApiConfig: context.thirdPartyApiConfig,
        loadGptExternallyPromise: context.loadGptExternallyPromise,
        loadGptExternally: context.loadGptExternally,
        sommelierResponse: context.sommelierResponse,
        pageTargeting: context.pageTargeting,
      };
      return {
        [ThirdParty.AD_SERVER]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.AD_SERVER}`,
          input: { bordeaux, thirdPartyMethods: adServer },
        }),
        [ThirdParty.AM_CLIO]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.AM_CLIO}`,
          input: { bordeaux, thirdPartyMethods: amClio },
        }),
        [ThirdParty.AMAZON]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.AMAZON}`,
          input: { bordeaux, thirdPartyMethods: amazon },
        }),
        [ThirdParty.EUID]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.EUID}`,
          input: { bordeaux, thirdPartyMethods: euid },
        }),
        [ThirdParty.GPT]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.GPT}`,
          input: { bordeaux, thirdPartyMethods: gpt },
        }),
        [ThirdParty.IAS]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.IAS}`,
          input: { bordeaux, thirdPartyMethods: ias },
        }),
        [ThirdParty.INDEX_EXCHANGE]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.INDEX_EXCHANGE}`,
          input: { bordeaux, thirdPartyMethods: indexExchange },
        }),
        [ThirdParty.LIVE_RAMP]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.LIVE_RAMP}`,
          input: { bordeaux, thirdPartyMethods: liveRamp },
        }),
        [ThirdParty.PREBID]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.PREBID}`,
          input: { bordeaux, thirdPartyMethods: prebid },
        }),
        [ThirdParty.PUBMATIC]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.PUBMATIC}`,
          input: { bordeaux, thirdPartyMethods: pubmatic },
        }),
        [ThirdParty.PUBX]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.PUBX}`,
          input: { bordeaux, thirdPartyMethods: pubx },
        }),
        [ThirdParty.TMT]: spawn(ACTORS.SETUP_THIRD_PARTY_API, {
          id: `ThirdParty-${ThirdParty.TMT}`,
          input: { bordeaux, thirdPartyMethods: tmt },
        }),
      } as BordeauxMachineContext['thirdPartyMachines'];
    },
  }),
  [ACTIONS.SET_THIRD_PARTY_RESULTS]: assignParams<FullAPISetupResults>({
    thirdPartyResults: (_, thirdPartyResults) => thirdPartyResults,
  }),

  [ACTIONS.SET_ADTOOL_VERSION]: assignParams<string>({
    adToolVersion: (_, adToolVersion) => adToolVersion,
  }),
  [ACTIONS.SET_LOAD_GPT_EXTERNALLY]: assignParams<boolean>({
    loadGptExternally: (_, loadGptExternally) => loadGptExternally,
  }),
  [ACTIONS.SET_PREBID_ANALYTICS_ENABLED]: assignParams<boolean>({
    prebidAnalyticsEnabled: (_, prebidAnalyticsEnabled) => prebidAnalyticsEnabled,
  }),
  [ACTIONS.SET_AUCTION_TIMEOUTS]: assignParams<AuctionTimeouts>({
    auctionTimeouts: (_, auctionTimeouts) => auctionTimeouts,
  }),
  [ACTIONS.SET_AUTOMATIC_DYNAMIC]: assignParams<boolean>({
    automaticDynamic: (_, automaticDynamic) => automaticDynamic,
  }),
  [ACTIONS.SET_OVERRIDE_COMPANION_BOUNDS]: assignParams<CompanionBounds>({
    overrideCompanionBounds: (_, overrideCompanionBounds) => overrideCompanionBounds,
  }),

  [ACTIONS.SET_EXPERIMENT_ID]: assignParams<string>({
    experimentId: (_, experimentId) => experimentId,
  }),
  [ACTIONS.SET_PAGE_CATEGORY]: assignParams<string>({
    pageCategory: (_, pageCategory) => pageCategory,
  }),
  [ACTIONS.SET_PAGE_TEMPLATE]: assignParams<string>({
    pageTemplate: (_, pageTemplate) => pageTemplate,
  }),
  [ACTIONS.SET_REFRESH_TIME]: assignParams<number>({
    refreshTime: (_, refreshTime) => refreshTime,
  }),
  [ACTIONS.SET_ROADBLOCK_INCREMENTAL_CAPS]: assignParams<RoadblockIncrementalCaps | null>({
    roadblockIncrementalCaps: (_, roadblockIncrementalCaps) => roadblockIncrementalCaps,
  }),
  [ACTIONS.SET_ROADBLOCK_INCREMENTAL_CHOOSER]: assignParams<RoadblockIncrementalChooser | null>({
    roadblockIncrementalChooser: (_, roadblockIncrementalChooser) => roadblockIncrementalChooser,
  }),
  [ACTIONS.SET_ACTIVATION_DISTANCE]: assignParams<number>({
    activationDistance: (_, activationDistance) => activationDistance,
  }),
  [ACTIONS.SET_AVOIDANCE_DISTANCE]: assignParams<DeviceAvoidanceDistance>({
    deviceAvoidanceDistance: (_, deviceAvoidanceDistance) => deviceAvoidanceDistance,
  }),
  [ACTIONS.SET_THIRD_PARTY_API_CONFIG_OVERRIDES]: assignParams<Partial<SiteConfig>>({
    thirdPartyApiConfigOverrides: (_, thirdPartyApiConfigOverrides) => thirdPartyApiConfigOverrides,
  }),
  [ACTIONS.SET_FALLBACK_RESPONSES]: assignParams<Partial<FallbackAdServerResponses>>({
    fallbackResponses: ({ context }, fallbackResponses) => ({
      ...context.fallbackResponses,
      ...fallbackResponses,
    }),
  }),
  [ACTIONS.ADD_UNREFRESHABLE_NAMES]: assignParams<Array<string>>({
    unrefreshableNames: ({ context }, unrefreshableNames) => [
      ...context.unrefreshableNames,
      ...unrefreshableNames,
    ],
  }),
  [ACTIONS.ENABLE_FEATURE]: assignParams<FEATURE>({
    features: ({ context }, name) => ({
      ...context.features,
      [name]: true,
    }),
  }),
  [ACTIONS.DISABLE_FEATURE]: assignParams<FEATURE>({
    features: ({ context }, name) => ({
      ...context.features,
      [name]: false,
    }),
  }),

  [ACTIONS.CHANGE_BIGTOP_DISPLAY]: (
    { context: { slots } },
    { allowPopout }: { allowPopout: boolean },
  ) => {
    // Celtra Big-Tops via ad-features
    if (allowPopout) {
      enablePopOut();
    } else {
      cancelPopOut();
    }

    // FAM Big-Tops
    const slotsToHide = slots.getValues().filter(slot => slot.getProperty('hideFromOtherScreens'));
    const slotElements = slotsToHide.map(slot => {
      const slotPlacementType = slot.getProperty('position');
      return slotPlacementType === SlotPosition.APPEND || slotPlacementType === SlotPosition.PREPEND
        ? slot.getProperty('hookElement')
        : slot.getProperty('element');
    });
    slotElements.forEach(element => {
      element.style.position = allowPopout ? '' : 'static';
    });

    // GAM Big-Tops
    const thirdWay = querySelectorAll<HTMLElement>('#iframe-future-big-top-collapsed');
    [...thirdWay].forEach(element => {
      element.style.top = allowPopout ? '0' : '-1000px';
    });
  },
};
export default actions;
