import {
  SLOTIFY_ACTIONS,
  AnySlotifyEvent,
  SLOTIFY_EVENTS,
  SLOTIFY_GUARDS,
  INCREMENTAL_ADS_EVENTS_IN,
  STANDARD_ADS_EVENTS_IN,
  SLOTIFY_STATES,
  SlotifyMachineContext,
  BordeauxMachineContext,
  AdUnitMode,
  SLOTIFY_ACTORS,
  SLOTIFY_EVENTS_OUT,
  SlotifyMachineDefinition,
  SlotInViewEvent,
} from '@repo/shared-types';
import { AnyActorRef, assign, enqueueActions, not, sendParent, setup, stateIn } from 'xstate';
import replaceConstantsInSlot from 'ad-framework/slot/replace-constants';
import slotifyGuards from './guards';
import slotifyActions from './actions';
import slotifyActors from './actors';

const slotifyMachine: SlotifyMachineDefinition = setup({
  types: {} as {
    context: SlotifyMachineContext;
    events: AnySlotifyEvent;
    input: BordeauxMachineContext;
  },
  actors: {
    ...slotifyActors,
  },
  guards: {
    ...slotifyGuards,
  },
  actions: {
    ...slotifyActions,
  },
}).createMachine({
  id: 'slotify',
  context: ({ input }) => ({
    ...input,
    incrementalsStarted: false,
    roadblockIncrementalCount: 0,
    batchCounter: 0,
    adCounter: 0,
    adTypeCounters: {},
    adMatches: [],
    newAds: [],

    slotStack: [],
    slotWatchers: [],
    slotPositioners: [],
    slotPositions: {},
    slotGenerator: {} as AnyActorRef,
  }),
  initial: SLOTIFY_STATES.CREATING_STATIC_SLOTS,
  states: {
    [SLOTIFY_STATES.CREATING_STATIC_SLOTS]: {
      invoke: {
        src: SLOTIFY_ACTORS.STATIC_SLOT_GENERATOR,
        input: ({ context }) => ({
          slotDefinitions: context.config.placement.slots.static.map(
            replaceConstantsInSlot(context.pageStyleConstants),
          ),
        }),
      },
      on: {
        [SLOTIFY_EVENTS.STATIC_SLOTS_DONE]: {
          target: SLOTIFY_STATES.WAIT_FOR_STATIC_SLOTS_READY,
        },
      },
    },
    [SLOTIFY_STATES.WAIT_FOR_STATIC_SLOTS_READY]: {
      always: {
        guard: SLOTIFY_GUARDS.ALL_SLOTS_POSITIONED,
        target: SLOTIFY_STATES.WAIT_FOR_STANDARD_ADS_ENABLED,
      },
    },
    [SLOTIFY_STATES.WAIT_FOR_STANDARD_ADS_ENABLED]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(SLOTIFY_GUARDS.STANDARD_ADS_ENABLED)) {
          enqueue(SLOTIFY_ACTIONS.RAISE_ENABLE_STANDARD_ADS);
        }
      }),
      on: {
        [STANDARD_ADS_EVENTS_IN.ENABLED]: {
          target: SLOTIFY_STATES.YIELDING_STATIC_AFFINITY_ADS,
        },
      },
    },
    [SLOTIFY_STATES.YIELDING_STATIC_AFFINITY_ADS]: {
      invoke: {
        src: SLOTIFY_ACTORS.AFFINITY_AD_GENERATOR,
        input: ({ context }) => ({
          adDefinitions: context.adUnits.standard,
          slots: context.slots.getValues(),
        }),
      },
      on: {
        [SLOTIFY_EVENTS.ADS_MATCH]: {
          actions: {
            type: SLOTIFY_ACTIONS.SET_AD_MATCHES,
            params: ({ event: { data } }) =>
              data.map(({ adDefinition, slot }) => ({
                slot,
                adDefinition,
              })),
          },
          target: SLOTIFY_STATES.CREATE_ADS,
        },
      },
    },
    [SLOTIFY_STATES.WAIT_FOR_ROADBLOCK_READY]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(SLOTIFY_GUARDS.ROADBLOCK_READY)) {
          enqueue(SLOTIFY_ACTIONS.RAISE_ROADBLOCK_READY);
        } else {
          enqueue(SLOTIFY_ACTIONS.CHECK_ROADBLOCK_STATUS);
        }
      }),
      on: {
        [INCREMENTAL_ADS_EVENTS_IN.ROADBLOCK_STATUS]: {
          actions: [
            { type: SLOTIFY_ACTIONS.UPDATE_ROADBLOCK_STATUS, params: ({ event }) => event.data },
            enqueueActions(({ enqueue, check }) => {
              if (check(SLOTIFY_GUARDS.ROADBLOCK_READY)) {
                enqueue(SLOTIFY_ACTIONS.RAISE_ROADBLOCK_READY);
              } else {
                enqueue(SLOTIFY_ACTIONS.CHECK_ROADBLOCK_STATUS);
              }
            }),
          ],
        },
        [SLOTIFY_EVENTS.ROADBLOCK_READY]: [
          {
            // ADP-12918 anchored ads should be avoided if roadblock is active
            guard: SLOTIFY_GUARDS.ANCHORED_EXCLUDED_FROM_ABSENT_ROADBLOCK,
            actions: {
              type: SLOTIFY_ACTIONS.SET_AD_MATCHES,
              params: ({
                context: {
                  adUnits: { standard: adDefinitions },
                },
              }) =>
                adDefinitions
                  .filter(
                    adDefinition =>
                      adDefinition.mode === AdUnitMode.ANCHORED && !adDefinition.inRoadblock,
                  )
                  .map(adDefinition => ({
                    adDefinition,
                  })),
            },
            target: SLOTIFY_STATES.CREATE_ADS,
          },
          {
            target: SLOTIFY_STATES.WAIT_FOR_INCREMENTAL_ADS_ENABLED,
          },
        ],
      },
    },
    [SLOTIFY_STATES.WAIT_FOR_INCREMENTAL_ADS_ENABLED]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(SLOTIFY_GUARDS.INCREMENTAL_ADS_ENABLED)) {
          enqueue(SLOTIFY_ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS);
        }
      }),
      on: {
        [INCREMENTAL_ADS_EVENTS_IN.ENABLED]: {
          actions: [
            SLOTIFY_ACTIONS.ENABLE_INCREMENTAL_ADS,
            enqueueActions(({ enqueue, check }) => {
              if (check(SLOTIFY_GUARDS.INCREMENTAL_ADS_ENABLED))
                enqueue(SLOTIFY_ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS);
            }),
          ],
        },
        [SLOTIFY_EVENTS.INCREMENTAL_ADS_ENABLED]: {
          target: SLOTIFY_STATES.WATCHING_INCREMENTAL_SLOTS,
        },
      },
    },
    [SLOTIFY_STATES.WATCHING_INCREMENTAL_SLOTS]: {
      entry: [
        assign({ incrementalsStarted: true }),
        SLOTIFY_ACTIONS.CREATE_DYNAMIC_SLOT_GENERATOR,
        SLOTIFY_ACTIONS.WATCH_EXCLUSION_ZONES,
      ],
      always: {
        target: SLOTIFY_STATES.WAITING_FOR_SLOTS,
      },
    },
    [SLOTIFY_STATES.WAITING_FOR_SLOTS]: {
      always: [
        {
          // Do nothing, keep waiting
          guard: not(SLOTIFY_GUARDS.SLOTS_TO_PROCESS),
        },
        {
          // ADP-13241 Always process native slots
          guard: {
            type: SLOTIFY_GUARDS.SLOT_IS_NATIVE,
            params: ({ context: { slotStack } }) => ({ slot: slotStack[0] }),
          },
          target: SLOTIFY_STATES.PROCESSING_SLOT_STACK,
        },
        {
          // ADP-13056 Incrementals should continue when roadblocked to allow a limited number to be generated
          guard: not(SLOTIFY_GUARDS.ROADBLOCK_INCREMENTALS_FILLED),
          target: SLOTIFY_STATES.PROCESSING_SLOT_STACK,
        },
        {
          // ADP-13241 Only stop after all incrementals and native content are filled
          guard: SLOTIFY_GUARDS.NATIVE_CONTENT_FILLED,
          target: SLOTIFY_STATES.DONE,
        },
      ],
    },
    [SLOTIFY_STATES.PROCESSING_SLOT_STACK]: {
      invoke: {
        src: SLOTIFY_ACTORS.MATCH_SLOT,
        input: ({
          context: {
            slotStack,
            slots,
            overrideCompanionBounds,
            config,
            isRoadblock,
            avoidanceDistance,
            roadblockIncrementals,
            adUnits,
            ads,
          },
        }) => ({
          slots,
          overrideCompanionBounds,
          config,
          isRoadblock,
          avoidanceDistance,
          roadblockIncrementals,
          adUnits,
          ads,
          slot: slotStack[0],
        }),
        onDone: [
          {
            guard: ({ event: { output } }) => output !== null,
            actions: [
              SLOTIFY_ACTIONS.POP_SLOT_STACK,
              {
                type: SLOTIFY_ACTIONS.SET_AD_MATCHES,
                params: ({ event: { output } }) => output!,
              },
            ],
            target: SLOTIFY_STATES.CREATE_ADS,
          },
          {
            actions: SLOTIFY_ACTIONS.POP_SLOT_STACK,
            target: SLOTIFY_STATES.WAITING_FOR_SLOTS,
          },
        ],
      },
    },
    [SLOTIFY_STATES.CREATE_ADS]: {
      invoke: {
        src: SLOTIFY_ACTORS.CREATE_ADS,
        input: ({ context: { adMatches, adCounter, pageAdUnitPath, adTypeCounters, config } }) => ({
          adMatches,
          adCounter,
          pageAdUnitPath,
          adTypeCounters,
          config,
        }),
        onDone: {
          actions: [
            {
              type: SLOTIFY_ACTIONS.INCREMENT_AD_COUNTER,
              params: ({ event: { output } }) => output,
            },
            {
              type: SLOTIFY_ACTIONS.INCREMENT_ROADBLOCK_INCREMENTAL_COUNTER,
              params: ({ event: { output } }) => output,
            },
            {
              type: SLOTIFY_ACTIONS.INCREMENT_AD_TYPE_COUNTERS,
              params: ({ event: { output } }) => output,
            },
            {
              type: SLOTIFY_ACTIONS.SET_NEW_ADS,
              params: ({ event: { output } }) => output,
            },
          ],
          target: SLOTIFY_STATES.INSERT_ADS,
        },
      },
    },
    [SLOTIFY_STATES.INSERT_ADS]: {
      entry: SLOTIFY_ACTIONS.PUT_NEW_ADS_IN_STORE,
      invoke: {
        src: SLOTIFY_ACTORS.INSERT_ADS,
        input: ({ context }) => context,
        onDone: { target: SLOTIFY_STATES.REQUEST_ADS },
      },
    },
    [SLOTIFY_STATES.REQUEST_ADS]: {
      entry: [
        SLOTIFY_ACTIONS.REORDER_ADS,
        SLOTIFY_ACTIONS.REPORT_FIRST_AD_LOAD,
        SLOTIFY_ACTIONS.TRIGGER_AUCTION,
        SLOTIFY_ACTIONS.INCREMENT_BATCH_COUNTER,
      ],
      always: [
        {
          guard: not(SLOTIFY_GUARDS.ROADBLOCK_READY),
          target: SLOTIFY_STATES.WAIT_FOR_ROADBLOCK_READY,
        },
        {
          guard: not(SLOTIFY_GUARDS.INCREMENTAL_ADS_ENABLED),
          target: SLOTIFY_STATES.WAIT_FOR_INCREMENTAL_ADS_ENABLED,
        },
        {
          guard: not(SLOTIFY_GUARDS.INCREMENTALS_STARTED),
          target: SLOTIFY_STATES.WATCHING_INCREMENTAL_SLOTS,
        },
        {
          target: SLOTIFY_STATES.WAITING_FOR_SLOTS,
        },
      ],
    },
    [SLOTIFY_STATES.DONE]: {},
  },
  on: {
    [SLOTIFY_EVENTS.SLOT_HOOK_FAILED]: {
      actions: { type: SLOTIFY_ACTIONS.REPORT_SLOT_HOOK_FAILED, params: ({ event }) => event.data },
    },
    [SLOTIFY_EVENTS.SLOT_CREATED]: {
      actions: [
        { type: SLOTIFY_ACTIONS.ADD_SLOT, params: ({ event }) => event.data },
        { type: SLOTIFY_ACTIONS.POSITION_SLOT_ELEMENT, params: ({ event }) => event.data },
        {
          type: SLOTIFY_ACTIONS.CREATE_ADDITIONAL_SLOT_WATCHERS,
          params: ({ event }) => event.data,
        },
        { type: SLOTIFY_ACTIONS.CREATE_SLOT_WATCHER, params: ({ event }) => event.data },
      ],
    },
    [STANDARD_ADS_EVENTS_IN.ENABLED]: {
      actions: SLOTIFY_ACTIONS.ENABLE_STANDARD_ADS,
    },
    [INCREMENTAL_ADS_EVENTS_IN.ENABLED]: {
      actions: SLOTIFY_ACTIONS.ENABLE_INCREMENTAL_ADS,
    },
    [SLOTIFY_EVENTS.AD_AFFINITY_FAILED]: {
      actions: {
        type: SLOTIFY_ACTIONS.REPORT_AD_AFFINITY_FAILED,
        params: ({ event }) => event.data,
      },
    },

    [SLOTIFY_EVENTS.SLOT_VIEWABILITY_CHANGED]: {
      actions: enqueueActions(({ check, enqueue }) => {
        enqueue.sendParent(({ event: { data } }) => ({
          type: SLOTIFY_EVENTS_OUT.SLOT_VIEWABILITY_CHANGED,
          data,
        }));
        if (
          check({
            type: SLOTIFY_GUARDS.INTERSECTION_IN_VIEW,
            params: ({
              event: {
                data: { intersection },
              },
            }) => intersection,
          })
        )
          enqueue.raise(
            ({
              event: {
                data: { slot },
              },
            }) =>
              ({
                type: SLOTIFY_EVENTS.SLOT_IN_VIEW,
                data: slot,
              }) as SlotInViewEvent,
          );
      }),
    },

    [SLOTIFY_EVENTS.SLOT_IN_VIEW]: [
      {
        guard: not(stateIn(SLOTIFY_STATES.WAITING_FOR_SLOTS)),
        actions: {
          type: SLOTIFY_ACTIONS.ADD_SLOT_TO_STACK,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
      },
      {
        guard: {
          type: SLOTIFY_GUARDS.SLOT_IS_NATIVE,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
        actions: {
          type: SLOTIFY_ACTIONS.ADD_SLOT_TO_STACK,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
        target: `.${SLOTIFY_STATES.PROCESSING_SLOT_STACK}`,
      },
      {
        guard: not(SLOTIFY_GUARDS.ROADBLOCK_INCREMENTALS_FILLED),
        actions: {
          type: SLOTIFY_ACTIONS.ADD_SLOT_TO_STACK,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
        target: `.${SLOTIFY_STATES.PROCESSING_SLOT_STACK}`,
      },
    ],
    [SLOTIFY_EVENTS.FIND_NEW_DYNAMIC_SLOTS]: {
      actions: SLOTIFY_ACTIONS.TRIGGER_DYNAMIC_SLOT_REFRESH,
    },
    [SLOTIFY_EVENTS.SLOT_POSITIONED]: {
      actions: [
        assign({
          slotPositions: ({
            context: { slotPositions },
            event: {
              data: { slot, position },
            },
          }) => ({
            ...slotPositions,
            [slot.getProperty('id')]: position,
          }),
        }),
        sendParent(({ event: { data } }) => ({
          type: SLOTIFY_EVENTS_OUT.SLOT_POSITIONED,
          data,
        })),
      ],
    },
  },
});

export default slotifyMachine;
