import {
  Slot,
  FEATURE,
  DataObject,
  SLOTIFY_GUARDS,
  AdUnitMode,
  SlotifyMachineContext,
  AnySlotifyEvent,
  SlotifyGuards,
  SlotObserverData,
} from '@repo/shared-types';
import { GuardPredicate } from 'xstate/guards';

const slotifyGuards: Record<
  SLOTIFY_GUARDS,
  GuardPredicate<SlotifyMachineContext, AnySlotifyEvent, any, SlotifyGuards>
> = {
  [SLOTIFY_GUARDS.ROADBLOCK_READY]: ({ context: { isRoadblock } }) => isRoadblock !== null,
  [SLOTIFY_GUARDS.STANDARD_ADS_ENABLED]: ({ context: { features } }) =>
    features[FEATURE.ADS_STANDARD],
  [SLOTIFY_GUARDS.INCREMENTAL_ADS_ENABLED]: ({ context: { features } }) =>
    features[FEATURE.ADS_INCREMENTAL],
  [SLOTIFY_GUARDS.SLOTS_TO_PROCESS]: ({ context: { slotStack } }) => slotStack.length !== 0,
  [SLOTIFY_GUARDS.SLOT_IS_NATIVE]: ({}, { slot }: { slot: DataObject<Slot> }) =>
    slot.getProperty('nativeContent'),
  [SLOTIFY_GUARDS.NATIVE_CONTENT_FILLED]: ({ context: { slots } }) =>
    slots.getValues().every(slot => !slot.getProperty('nativeContent') || slot.getProperty('adID')),
  [SLOTIFY_GUARDS.ROADBLOCK_INCREMENTALS_FILLED]: ({
    context: {
      isRoadblock,
      roadblockIncrementalCaps,
      roadblockIncrementalCount,
      pageParameters: { device },
      config: {
        placement: {
          settings: { roadblock },
        },
      },
    },
  }) => {
    // Makes sure we never exceed the roadblock incremental cap

    if (!isRoadblock) return false;

    const incrementalCap = roadblockIncrementalCaps?.[device] ?? roadblock?.incrementalCap;

    if (!incrementalCap) {
      return true;
    }

    if (roadblockIncrementalCount >= incrementalCap) {
      return true;
    }

    return false;
  },
  [SLOTIFY_GUARDS.ANCHORED_EXCLUDED_FROM_ABSENT_ROADBLOCK]: ({
    context: {
      isRoadblock,
      adUnits: { standard: adDefinitions },
    },
  }) =>
    !isRoadblock &&
    adDefinitions.some(
      adDefinition => adDefinition.mode === AdUnitMode.ANCHORED && !adDefinition.inRoadblock,
    ),
  [SLOTIFY_GUARDS.INCREMENTALS_STARTED]: ({ context: { incrementalsStarted } }) =>
    incrementalsStarted,

  [SLOTIFY_GUARDS.INTERSECTION_IN_VIEW]: (_, intersection: SlotObserverData) =>
    intersection.isIntersecting && intersection.scrollPosition !== -1,
  [SLOTIFY_GUARDS.ALL_SLOTS_POSITIONED]: ({ context: { slots, slotPositions } }) =>
    slots.getValues().every(slot => slot.getProperty('id') in slotPositions),
};
export default slotifyGuards;
