import { Ad, AdDefinition } from '../ad-framework/ad/index.types';
import { SlotObserverData } from './observer/index.types';
import { Slot, SlotDefinition } from '../ad-framework/slot/index.types';
import { BordeauxMachineContext } from '../state/context.types';
import DataObject from '../state/data-object';
import { AnyActorRef, EventObject, MetaObject, NonReducibleUnknown, ParameterizedObject, ProvidedActor, StateMachine, StateSchema } from 'xstate';

export enum INCREMENTAL_ADS_EVENTS_IN {
  ENABLED = 'INCREMENTAL_ADS_ENABLED',
  ROADBLOCK_STATUS = 'INCREMENTAL_ADS_ROADBLOCK_STATUS',
}
export interface IncrementalAdsEnabledEvent extends EventObject {
  type: INCREMENTAL_ADS_EVENTS_IN.ENABLED;
}
export interface IncrementalAdsRoadblockStatusEvent extends EventObject {
  type: INCREMENTAL_ADS_EVENTS_IN.ROADBLOCK_STATUS;
  data: boolean;
}

export type IncrementalAdsInEvents =
  | IncrementalAdsEnabledEvent
  | IncrementalAdsRoadblockStatusEvent;

export enum STANDARD_ADS_EVENTS_OUT {
  FAILURE = 'STANDARD_ADS_FAILURE',
}
export enum STANDARD_ADS_EVENTS_IN {
  ENABLED = 'STANDARD_ADS_ENABLED',
}
export interface StandardAdsFailureEvent extends EventObject {
  type: STANDARD_ADS_EVENTS_OUT.FAILURE;
}
export interface StandardAdsEnabledEvent extends EventObject {
  type: STANDARD_ADS_EVENTS_IN.ENABLED;
}
export type StandardAdsOutEvents = StandardAdsFailureEvent;
export type StandardAdsInEvents = StandardAdsEnabledEvent;

export enum SLOTIFY_EVENTS_OUT {
  SLOT_VIEWABILITY_CHANGED = 'SLOTIFY_EVENTS_OUT:SLOT_VIEWABILITY_CHANGED',
  SLOT_POSITIONED = 'SLOTIFY_EVENTS_OUT:SLOT_POSITIONED',
}
export interface ExternalSlotViewabilityChangedEvent extends EventObject {
  type: SLOTIFY_EVENTS_OUT.SLOT_VIEWABILITY_CHANGED;
  data: {
    slot: DataObject<Slot>;
    intersection: SlotObserverData;
  };
}
export interface ExternalSlotPositionedEvent extends EventObject {
  type: SLOTIFY_EVENTS_OUT.SLOT_POSITIONED;
  data: { slot: DataObject<Slot>; position: { x: number; y: number } };
}

export type SloifyOutEvents = ExternalSlotViewabilityChangedEvent | ExternalSlotPositionedEvent;

export interface AdMatch {
  adDefinition: AdDefinition;
  slot?: DataObject<Slot>;
}

export enum AD_AFFINITY_FAILED_REASONS {
  ABSENT = 'ABSENT',
  NO_SLOT = 'NO_SLOT',
  SLOT_FILLED = 'SLOT_FILLED',
}

export enum SLOTIFY_STATES {
  CREATING_STATIC_SLOTS = 'CREATING_STATIC_SLOTS',
  WAIT_FOR_STATIC_SLOTS_READY = 'WAIT_FOR_STATIC_SLOTS_READY',
  WAIT_FOR_STANDARD_ADS_ENABLED = 'WAIT_FOR_STANDARD_ADS_ENABLED',
  WAIT_FOR_ROADBLOCK_READY = 'WAIT_FOR_ROADBLOCK_READY',
  WAIT_FOR_INCREMENTAL_ADS_ENABLED = 'WAIT_FOR_INCREMENTAL_ADS_ENABLED',
  YIELDING_STATIC_AFFINITY_ADS = 'YIELDING_STATIC_AFFINITY_ADS',
  WATCHING_INCREMENTAL_SLOTS = 'WATCHING_INCREMENTAL_SLOTS',
  WAITING_FOR_SLOTS = 'WAITING_FOR_SLOTS',
  PROCESSING_SLOT_STACK = 'PROCESSING_SLOT_STACK',
  CREATE_ADS = 'CREATE_ADS',
  INSERT_ADS = 'INSERT_ADS',
  REQUEST_ADS = 'REQUEST_ADS',
  DONE = 'DONE',
}

export enum SLOTIFY_ACTIONS {
  REPORT_AD_AFFINITY_FAILED = 'REPORT_AD_AFFINITY_FAILED',
  ENABLE_INCREMENTAL_ADS = 'ENABLE_INCREMENTAL_ADS',
  ENABLE_STANDARD_ADS = 'ENABLE_STANDARD_ADS',
  POSITION_SLOT_ELEMENT = 'POSITION_SLOT_ELEMENT',
  ADD_SLOT = 'ADD_SLOT',
  CREATE_ADDITIONAL_SLOT_WATCHERS = 'CREATE_ADDITIONAL_SLOT_WATCHERS',
  CREATE_SLOT_WATCHER = 'CREATE_SLOT_WATCHER',
  REPORT_SLOT_HOOK_FAILED = 'REPORT_SLOT_HOOK_FAILED',

  CREATE_DYNAMIC_SLOT_GENERATOR = 'CREATE_DYNAMIC_SLOT_GENERATOR',
  WATCH_EXCLUSION_ZONES = 'WATCH_EXCLUSION_ZONES',
  CHECK_ROADBLOCK_STATUS = 'CHECK_ROADBLOCK_STATUS',
  RAISE_ROADBLOCK_READY = 'RAISE_ROADBLOCK_READY',
  RAISE_ENABLE_INCREMENTAL_ADS = 'RAISE_ENABLE_INCREMENTAL_ADS',
  UPDATE_ROADBLOCK_STATUS = 'UPDATE_ROADBLOCK_STATUS',
  RAISE_ENABLE_STANDARD_ADS = 'RAISE_ENABLE_STANDARD_ADS',

  REORDER_ADS = 'REORDER_ADS',

  ADD_SLOT_TO_STACK = 'ADD_SLOT_TO_STACK',

  INCREMENT_AD_COUNTER = 'INCREMENT_AD_COUNTER',
  INCREMENT_ROADBLOCK_INCREMENTAL_COUNTER = 'INCREMENT_ROADBLOCK_INCREMENTAL_COUNTER',
  INCREMENT_AD_TYPE_COUNTERS = 'INCREMENT_AD_TYPE_COUNTERS',

  TRIGGER_DYNAMIC_SLOT_REFRESH = 'TRIGGER_DYNAMIC_SLOT_REFRESH',
  INCREMENT_BATCH_COUNTER = 'INCREMENT_BATCH_COUNTER',
  TRIGGER_AUCTION = 'TRIGGER_AUCTION',
  REPORT_FIRST_AD_LOAD = 'REPORT_FIRST_AD_LOAD',
  PUT_NEW_ADS_IN_STORE = 'PUT_NEW_ADS_IN_STORE',
  SET_NEW_ADS = 'SET_NEW_ADS',
  POP_SLOT_STACK = 'POP_SLOT_STACK',
  SET_AD_MATCHES = 'SET_AD_MATCHES',
}

export enum SLOTIFY_EVENTS {
  SLOT_HOOK_FAILED = 'SLOT_HOOK_FAILED',
  AD_AFFINITY_FAILED = 'AD_AFFINITY_FAILED',

  SLOT_CREATED = 'SLOT_CREATED',
  ADS_MATCH = 'ADS_MATCH',

  STATIC_SLOTS_DONE = 'STATIC_SLOTS_DONE',
  GENERATED_SLOTS_DONE = 'GENERATED_SLOTS_DONE',
  
  SLOT_VIEWABILITY_CHANGED = 'SLOT_VIEWABILITY_CHANGED',
  SLOT_POSITIONED = 'SLOT_POSITIONED',

  SLOT_IN_VIEW = 'SLOT_IN_VIEW',

  INCREMENTAL_ADS_ENABLED = 'INCREMENTAL_ADS_ENABLED_INTERNAL',
  ROADBLOCK_READY = 'ROADBLOCK_READY',

  FIND_NEW_DYNAMIC_SLOTS = 'FIND_NEW_DYNAMIC_SLOTS',
}

export enum SLOTIFY_GUARDS {
  STANDARD_ADS_ENABLED = 'STANDARD_ADS_ENABLED',
  INCREMENTAL_ADS_ENABLED = 'INCREMENTAL_ADS_ENABLED',
  SLOTS_TO_PROCESS = 'SLOTS_TO_PROCESS',
  ROADBLOCK_INCREMENTALS_FILLED = 'ROADBLOCK_INCREMENTALS_FILLED',
  ROADBLOCK_READY = 'ROADBLOCK_READY',
  SLOT_IS_NATIVE = 'SLOT_IS_NATIVE',
  NATIVE_CONTENT_FILLED = 'NATIVE_CONTENT_FILLED',
  INTERSECTION_IN_VIEW = 'INTERSECTION_IN_VIEW',
  ANCHORED_EXCLUDED_FROM_ABSENT_ROADBLOCK = 'ANCHORED_EXCLUDED_FROM_ABSENT_ROADBLOCK',
  INCREMENTALS_STARTED = 'INCREMENTALS_STARTED',
  ALL_SLOTS_POSITIONED = 'ALL_SLOTS_POSITIONED',
}

export enum SLOTIFY_ACTORS {
  STATIC_SLOT_GENERATOR = 'STATIC_SLOT_GENERATOR',
  DYNAMIC_SLOT_GENERATOR = 'DYNAMIC_SLOT_GENERATOR',
  AFFINITY_AD_GENERATOR = 'AFFINITY_AD_GENERATOR',
  SLOT_WATCHER = 'SLOT_WATCHER',
  SLOT_POSITIONER = 'SLOT_POSITIONER',
  MATCH_SLOT = 'MATCH_SLOT',
  CREATE_ADS = 'CREATE_ADS',
  INSERT_ADS = 'INSERT_ADS',
}

export interface SlotHookFailedEvent extends EventObject {
  type: SLOTIFY_EVENTS.SLOT_HOOK_FAILED;
  data: SlotDefinition;
}

export interface SlotHookedEvent extends EventObject {
  type: SLOTIFY_EVENTS.SLOT_CREATED;
  data: DataObject<Slot>;
}

export interface SlotInViewEvent extends EventObject {
  type: SLOTIFY_EVENTS.SLOT_IN_VIEW;
  data: DataObject<Slot>;
}

export interface SlotViewabilityChangedEvent extends EventObject {
  type: SLOTIFY_EVENTS.SLOT_VIEWABILITY_CHANGED;
  data: {
    slot: DataObject<Slot>;
    intersection: SlotObserverData;
  };
}

export interface StaticSlotsDoneEvent extends EventObject {
  type: SLOTIFY_EVENTS.STATIC_SLOTS_DONE;
}
export interface GeneratedSlotsDoneEvent extends EventObject {
  type: SLOTIFY_EVENTS.GENERATED_SLOTS_DONE;
}

export interface IncrementalAdsInternalEvent extends EventObject {
  type: SLOTIFY_EVENTS.INCREMENTAL_ADS_ENABLED;
}
export interface RoadblockReadyEvent extends EventObject {
  type: SLOTIFY_EVENTS.ROADBLOCK_READY;
}

export interface AdAffinityFailedEvent extends EventObject {
  type: SLOTIFY_EVENTS.AD_AFFINITY_FAILED;
  data: {
    adDefinition: AdDefinition;
    reason: AD_AFFINITY_FAILED_REASONS;
  };
}
export interface AdMatchEvent extends EventObject {
  type: SLOTIFY_EVENTS.ADS_MATCH;
  data: Array<AdMatch>;
}

export interface SlotPositionedEvent extends EventObject {
  type: SLOTIFY_EVENTS.SLOT_POSITIONED;
  data: { slot: DataObject<Slot>; position: { x: number; y: number } };
}
export interface SlotInViewEvent extends EventObject {
  type: SLOTIFY_EVENTS.SLOT_IN_VIEW;
  data: DataObject<Slot>;
}

export interface SlotifyMachineContext extends BordeauxMachineContext {
  slotGenerator: AnyActorRef;

  adCounter: number;
  batchCounter: number;
  roadblockIncrementalCount: number;
  adTypeCounters: Record<string, number>;

  slotStack: Array<DataObject<Slot>>;
  adMatches: Array<AdMatch>;
  newAds: Array<DataObject<Ad>>;

  slotWatchers: Array<AnyActorRef>;
  slotPositioners: Array<AnyActorRef>;
  slotPositions: Record<string, { x: number; y: number }>;
  incrementalsStarted: boolean;
}
export type AnySlotifyEvent =
  | SlotHookFailedEvent
  | SlotHookedEvent
  | SlotPositionedEvent
  | StaticSlotsDoneEvent
  | StandardAdsInEvents
  | AdAffinityFailedEvent
  | AdMatchEvent
  | SlotViewabilityChangedEvent
  | IncrementalAdsInternalEvent
  | RoadblockReadyEvent
  | IncrementalAdsRoadblockStatusEvent
  | SlotInViewEvent;

export type SlotifyMachineDefinition = StateMachine<
  SlotifyMachineContext,
  AnySlotifyEvent,
  Record<string, AnyActorRef | undefined>,
  ProvidedActor,
  ParameterizedObject,
  ParameterizedObject,
  string,
  SLOTIFY_STATES,
  string,
  BordeauxMachineContext,
  NonReducibleUnknown,
  EventObject,
  MetaObject,
  StateSchema
>