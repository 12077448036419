import { BordeauxConfig } from '../../zod-schemas';
import { SommelierResponse } from '../sommelier/sommelier-response/index.types';

export enum METRICS {
  CONFIG = 'CONFIG',
  AUCTIONS = 'AUCTIONS',
  THIRD_PARTY = 'THIRD_PARTY',
  THIRD_PARTY_SCRIPTS = 'THIRD_PARTY_SCRIPTS',
}

export enum GUARDS {
  TIMING_COLLECTION_ENABLED = 'TIMING_COLLECTION_ENABLED',
  AUCTION_IS_UNSENT = 'AUCTION_IS_UNSENT',
  ERROR_IS_TIMEOUT = 'ERROR_IS_TIMEOUT',
  DUPLICATE_SCRIPTS = 'DUPLICATE_SCRIPTS',
  HAS_ENOUGH_CONSENT_FOR_AUCTION = 'HAS_ENOUGH_CONSENT_FOR_AUCTION',
  ROADBLOCK_UNDECIDED = 'ROADBLOCK_UNDECIDED',
  ROADBLOCK_IS_DECIDABLE = 'ROADBLOCK_IS_DECIDABLE',
  FEATURES_INITIALISED = 'FEATURES_INITIALISED',
  FEATURE_ENABLED = 'FEATURE_ENABLED',
  HAS_EXPERIMENT_ID = 'HAS_EXPERIMENT_ID',
  AD_TOOL_OPENED_FROM_URL = 'AD_TOOL_OPENED_FROM_URL',
  ADS_BLOCKED = 'ADS_BLOCKED',
  PREBID_ENABLED = 'PREBID_ENABLED',
  LIVE_INTENT_USER_SYNC_ENABLED = 'LIVE_INTENT_USER_SYNC_ENABLED',
  CONSENT_DONE = 'CONSENT_DONE',
  HAS_ADS = 'HAS_ADS',
  SCROLLED_PAST_FIRST_TANDEM = 'SCROLLED_PAST_FIRST_TANDEM',
  SLOT_IS_BEFORE_FIRST_TANDEM = 'SLOT_IS_BEFORE_FIRST_TANDEM',
}
export enum STATES {
  INITIALISING = 'INITIALISING',
  SCRAPING_PARAMATERS = 'SCRAPING_PARAMATERS',
  FETCHING_CONFIG = 'FETCHING_CONFIG',
  CHECKING_CONFIG = 'CHECKING_CONFIG',
  PARSING_CONFIG = 'PARSING_CONFIG',
  RETRIEVING_CONSENT = 'RETRIEVING_CONSENT',
  LOADING_THIRD_PARTIES = 'LOADING_THIRD_PARTIES',
  DECIDING_PAGE_STYLE_CONSTANTS = 'DECIDING_PAGE_STYLE_CONSTANTS',

  HANDLING_SLOTS = 'HANDLING_SLOTS',

  ERROR = 'ERROR',
  WAIT_FOR_INITIALISATION = 'WAIT_FOR_INITIALISATION',
}

export enum ACTIONS {
  USE_PARSED_FALLBACK_CONFIG = 'USE_PARSED_FALLBACK_CONFIG',
  USE_FALLBACK_CONFIG = 'USE_FALLBACK_CONFIG',

  RAISE_OPEN_AD_TOOL = 'RAISE_OPEN_AD_TOOL',
  CREATE_API_MACHINE = 'CREATE_API_MACHINE',
  INITIALISE_FEATURES = 'INITIALISE_FEATURES',

  INITIALISE_SENTRY = 'INITIALISE_SENTRY',

  THIRD_PARTIES_READY = 'THIRD_PARTIES_READY',
  SEND_AB_TEST_TO_FREYR = 'SEND_AB_TEST_TO_FREYR',
  DECIDE_AVOIDANCE_DISTANCE = 'DECIDE_AVOIDANCE_DISTANCE',
  DECIDE_THIRD_PARTY_CONFIG = 'DECIDE_THIRD_PARTY_CONFIG',
  DECIDE_PAGE_ADUNIT_PATH = 'DECIDE_PAGE_ADUNIT_PATH',
  DECIDE_VALID_ADUNITS = 'DECIDE_VALID_ADUNITS',
  SETUP_CUSTOM_ACTIVATIONS = 'SETUP_CUSTOM_ACTIVATIONS',
  DECIDE_REFRESH_TIME = 'DECIDE_REFRESH_TIME',
  DECIDE_ROADBLOCK_INCREMENTALS = 'DECIDE_ROADBLOCK_INCREMENTALS',
  SETUP_USER_SYNC = 'SETUP_USER_SYNC',
  SETUP_AD_MANAGER = 'SETUP_AD_MANAGER',
  HANDLE_ERROR = 'HANDLE_ERROR',

  CHECK_AD_BLOCK = 'CHECK_AD_BLOCK',
  READ_QUERY_PARAMETERS = 'READ_QUERY_PARAMETERS',
  READ_PAGE_PARAMETERS = 'READ_PAGE_PARAMETERS',
  CREATE_SHAMEFUL_MACHINE = 'CREATE_SHAMEFUL_MACHINE',
  ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE = 'ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE',
  ASSIGN_BORDEAUX_ADS_PROMISE = 'ASSIGN_BORDEAUX_ADS_PROMISE',
  CHECK_MULTIPLE_SCRIPTS = 'CHECK_MULTIPLE_SCRIPTS',
  CREATE_ADTOOL_TAP_OPEN_MACHINE = 'CREATE_ADTOOL_TAP_OPEN_MACHINE',
  CHECK_ADTOOL_PARAM = 'CHECK_ADTOOL_PARAM',
  CREATE_AUTOMATIC_REFRESH_MACHINE = 'CREATE_AUTOMATIC_REFRESH_MACHINE',
  CREATE_AD_FEATURE_MACHINE = 'CREATE_AD_FEATURE_MACHINE',
  REPORT_IF_AD_BLOCKED = 'REPORT_IF_AD_BLOCKED',
  DECIDE_LIVE_INTENT_USER_SYNC = 'DECIDE_LIVE_INTENT_USER_SYNC',
  DECIDE_TEST_ACTIVATION_DISTANCE = 'DECIDE_TEST_ACTIVATION_DISTANCE',
  DECIDE_V2_TEST_ACTIVATION_DISTANCE = 'DECIDE_V2_TEST_ACTIVATION_DISTANCE',
  DECIDE_TEST_PUBX = 'DECIDE_TEST_PUBX',
  DECIDE_TEST_ID_SERVICE_ACTIVATION = 'DECIDE_TEST_ID_SERVICE_ACTIVATION',
  ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING = 'ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING',

  RAISE_AD_MANAGER_AUCTION_PROCESSED = 'RAISE_AD_MANAGER_AUCTION_PROCESSED',
  RAISE_AUCTION_END = 'RAISE_AUCTION_END',
  RAISE_CHECK_ROADBLOCK_STATUS = 'RAISE_CHECK_ROADBLOCK_STATUS',
  FINALISE_AD = 'FINALISE_AD',
  MARK_AD_VIEWED = 'MARK_AD_VIEWED',
  RAISE_REPORT_AD_LOAD = 'RAISE_REPORT_AD_LOAD',
  REPORT_AD_LOAD = 'REPORT_AD_LOAD',
  UPDATE_AD_VIEWABILITY = 'UPDATE_AD_VIEWABILITY',
  DISABLE_ANCHORED_REFRESH = 'DISABLE_ANCHORED_REFRESH',
  ENABLE_ANCHORED_REFRESH = 'ENABLE_ANCHORED_REFRESH',
  UPDATE_ADS_VIEWED_TIME = 'UPDATE_ADS_VIEWED_TIME',
  RAISE_AUTOMATIC_REFRESH = 'RAISE_AUTOMATIC_REFRESH',
  RESET_ADS = 'RESET_ADS',
  REFRESH_ADS = 'REFRESH_ADS',
  UPDATE_REFRESH_ROADBLOCK = 'UPDATE_REFRESH_ROADBLOCK',
  UPDATE_SLOTIFY_ROADBLOCK = 'UPDATE_SLOTIFY_ROADBLOCK',
  UPDATE_API_ROADBLOCK = 'UPDATE_API_ROADBLOCK',
  SET_ROADBLOCK = 'SET_ROADBLOCK',
  CUSTOM_VIDEO_BEHAVIOUR = 'CUSTOM_VIDEO_BEHAVIOUR',
  LOAD_AD_TOOL = 'LOAD_AD_TOOL',
  REPORT_PAGE_LOAD = 'REPORT_PAGE_LOAD',
  REPORT_PAGE_UNLOAD = 'REPORT_PAGE_UNLOAD',
  SET_CFT_PARAMATERS = 'SET_CFT_PARAMATERS',
  SET_HYBRID_ID = 'SET_HYBRID_ID',
  UPDATE_API_HYBRID_ID = 'UPDATE_API_HYBRID_ID',
  SEND_USER_IDS_TO_FREYR = 'SEND_USER_IDS_TO_FREYR',
  SET_HYBRID_AB_TEST_TARGETING = 'SET_HYBRID_AB_TEST_TARGETING',
  AD_FEATURE_EXPAND_HEIGHT = 'AD_FEATURE_EXPAND_HEIGHT',
  AD_FEATURE_FULL_WIDTH = 'AD_FEATURE_FULL_WIDTH',
  AD_FEATURE_POP_OUT = 'AD_FEATURE_POP_OUT',
  AD_FEATURE_MULTI_FRAME = 'AD_FEATURE_MULTI_FRAME',
  AD_FEATURE_CLOSE_BUTTON = 'AD_FEATURE_CLOSE_BUTTON',
  DECIDE_HYBRID_AB_TEST_TARGETING = 'DECIDE_HYBRID_AB_TEST_TARGETING',
  START_ANCHORED_LOGIC = 'START_ANCHORED_LOGIC',
  SET_PAGE_STYLE_CONSTANTS = 'SET_PAGE_STYLE_CONSTANTS',
  DECIDE_ROADBLOCK_STATUS = 'DECIDE_ROADBLOCK_STATUS',
  UPDATE_PAGE_TARGETING = 'UPDATE_PAGE_TARGETING',
  SET_GDPR_CONSENT = 'SET_GDPR_CONSENT',
  SET_GPP_CONSENT = 'SET_GPP_CONSENT',
  SET_USP_CONSENT = 'SET_USP_CONSENT',
  UPDATE_API_CONFIG = 'UPDATE_API_CONFIG',
  SET_CONFIG = 'SET_CONFIG',
  RAISE_CONSENT_DONE = 'RAISE_CONSENT_DONE',
  REPORT_CONFIG_FAILURE = 'REPORT_CONFIG_FAILURE',
  SET_SOMMELIER_RESPONSE = 'SET_SOMMELIER_RESPONSE',
  REPORT_CONTENT_LOAD = 'REPORT_CONTENT_LOAD',
  CREATE_PAGE_EVENT_EMITTER = 'CREATE_PAGE_EVENT_EMITTER',
  DECIDE_TIMING_ENABLED = 'DECIDE_TIMING_ENABLED',
  UPDATE_API_READY = 'UPDATE_API_READY',
  CREATE_THIRD_PARTY_MACHINES = 'CREATE_THIRD_PARTY_MACHINES',
  SET_THIRD_PARTY_RESULTS = 'SET_THIRD_PARTY_RESULTS',
  ENABLE_FEATURE = 'ENABLE_FEATURE',
  DISABLE_FEATURE = 'DISABLE_FEATURE',

  SET_ADTOOL_VERSION = 'SET_ADTOOL_VERSION',
  SET_LOAD_GPT_EXTERNALLY = 'SET_LOAD_GPT_EXTERNALLY',
  SET_PREBID_ANALYTICS_ENABLED = 'SET_PREBID_ANALYTICS_ENABLED',
  SET_AUCTION_TIMEOUTS = 'SET_AUCTION_TIMEOUTS',
  SET_AUTOMATIC_DYNAMIC = 'SET_AUTOMATIC_DYNAMIC',
  SET_OVERRIDE_COMPANION_BOUNDS = 'SET_OVERRIDE_COMPANION_BOUNDS',
  SET_EXPERIMENT_ID = 'SET_EXPERIMENT_ID',
  SET_PAGE_CATEGORY = 'SET_PAGE_CATEGORY',
  SET_PAGE_TEMPLATE = 'SET_PAGE_TEMPLATE',
  SET_REFRESH_TIME = 'SET_REFRESH_TIME',
  SET_ROADBLOCK_INCREMENTAL_CAPS = 'SET_ROADBLOCK_INCREMENTAL_CAPS',
  SET_ROADBLOCK_INCREMENTAL_CHOOSER = 'SET_ROADBLOCK_INCREMENTAL_CHOOSER',
  SET_ACTIVATION_DISTANCE = 'SET_ACTIVATION_DISTANCE',
  SET_AVOIDANCE_DISTANCE = 'SET_AVOIDANCE_DISTANCE',
  SET_THIRD_PARTY_API_CONFIG_OVERRIDES = 'SET_THIRD_PARTY_API_CONFIG_OVERRIDES',
  SET_FALLBACK_RESPONSES = 'SET_FALLBACK_RESPONSES',
  ADD_UNREFRESHABLE_NAMES = 'ADD_UNREFRESHABLE_NAMES',

  STORE_HYBRID_TEST_SESSIONS = 'STORE_HYBRID_TEST_SESSIONS',
  DECIDE_TEST_AUCTION_TIMEOUTS = 'DECIDE_TEST_AUCTION_TIMEOUTS',

  AD_MANAGER_AUCTION = 'AD_MANAGER_AUCTION',
  UPDATE_AD_MANAGER_TARGETING = 'UPDATE_AD_MANAGER_TARGETING',
  PRE_PROCESS_AUCTION = 'PRE_PROCESS_AUCTION',

  SHOW_ANCHORED = 'SHOW_ANCHORED',
  HIDE_ANCHORED = 'HIDE_ANCHORED',
  
  CHANGE_BIGTOP_DISPLAY = 'CHANGE_BIGTOP_DISPLAY',
}

export enum ACTORS {
  ARBITRARY_EVENT_EMITTER = 'ARBITRARY_EVENT_EMITTER',
  ELEMENT_TAP_HANDLER = 'ELEMENT_TAP_HANDLER',
  INIT_AUTOMATIC_REFRESH = 'INIT_AUTOMATIC_REFRESH',
  PAGE_EVENT_EMITTER = 'PAGE_EVENT_EMITTER',
  GET_ENV_DOM_CONTENT_LOADED = 'GET_ENV_DOM_CONTENT_LOADED',
  SETUP_API = 'SETUP_API',
  FETCH_CONFIG = 'FETCH_CONFIG',
  CHECK_CONFIG = 'CHECK_CONFIG',
  PARSE_CONFIG = 'PARSE_CONFIG',
  GET_GDPR_CONSENT_MACHINE = 'GET_GDPR_CONSENT_MACHINE',
  GET_USP_CONSENT = 'GET_USP_CONSENT',
  GET_GPP_CONSENT = 'GET_GPP_CONSENT',

  SETUP_THIRD_PARTY_API = 'SETUP_THIRD_PARTY_API',
  LISTEN_FOR_AD_FEATURES = 'LISTEN_FOR_AD_FEATURES',
  
  WAIT_FOR_ALL_THIRD_PARTIES = 'WAIT_FOR_ALL_THIRD_PARTIES',
  READ_PAGE_STYLES = 'READ_PAGE_STYLES',

  ANCHORED_LOGIC = 'ANCHORED_LOGIC',
  SLOTIFY_MACHINE = 'SLOTIFY_MACHINE',

  AD_MANAGER_MACHINE = 'AD_MANAGER_MACHINE',
  AD_PRE_PROCESSOR = 'AD_PRE_PROCESSOR',
}

export enum PAGE_STYLE_CONSTANTS {
  PAGE_BACKGROUND = 'page_background',
  PAGE_TEXT = 'page_text',
}

export interface BordeauxMachineConfigEvent {
  type: string;
  data: BordeauxConfig;
}
export interface BordeauxMachineSommelierResponseEvent {
  type: string;
  data: SommelierResponse;
}
