import { log } from '@repo/utils';
import { fastdom } from '@repo/utils';
import { ExpandHeightAdFeatureEvent, ActionArgs } from '@repo/shared-types';

const collectElements = (
  element: HTMLElement | null,
  predicate: (el: HTMLElement) => boolean,
  elements: HTMLElement[] = [],
): HTMLElement[] => {
  if (!element) return elements;

  if (!predicate(element)) {
    elements.push(element);
  }

  if (element.firstElementChild) {
    collectElements(element.firstElementChild as HTMLElement, predicate, elements);
  }

  if (element.nextElementSibling) {
    collectElements(element.nextElementSibling as HTMLElement, predicate, elements);
  }

  return elements;
};

const setHeights = (elements: HTMLElement[], height: number): void => {
  elements.forEach(element => {
    element.style.height = `${height}px`;
    element.setAttribute('height', height.toString());
  });
};

const setChildElementHeight = (rootElement: HTMLElement | null, height: number): void => {
  const elements = collectElements(rootElement, el => el.id.includes('label'));
  setHeights(elements, height);
};

const expandHeight = ({ context }: ActionArgs, data: ExpandHeightAdFeatureEvent['data']): void => {
  const slot = context.slots.getValues().find(slot => slot.getProperty('id') === data.slotID);
  if (!slot) {
    log.error(`Could not make ad full width, slot with ID ${data} does not exist.`);
    return;
  }

  const adID = slot.getProperty('adID');
  if (!adID) {
    log.error(`Could not make ad full width, slot does not have an ad associated with it.`);
    return;
  }

  const ad = context.ads.getValues().find(ad => ad.getProperty('id') === adID);
  if (!ad) {
    log.error(`Could not make ad full width, ad with ID ${adID} does not exist.`);
    return;
  }

  fastdom.mutate(() => {
    slot.getProperty('element').style.height = `${parseInt(data.height, 10) + 40}px`;
    if (data.expandChildren) {
      setChildElementHeight(
        slot.getProperty('element').firstElementChild as HTMLElement,
        parseInt(data.height, 10),
      );
    }
  });

  data.source.postMessage({
    type: 'bordeaux-ad-feature-response',
    height: data.height,
  });
};
export default expandHeight;
