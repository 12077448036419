import { log } from '@repo/utils';
import {
  EnableFeatureApiEvent,
  SetExperimentIdApiEvent,
  DisableFeatureApiEvent,
  GptLoadedExternallyApiEvent,
  FEATURE,
  ActionArgs,
  BordeauxMachineContext,
  API_EVENTS_OUT,
  SLOTIFY_EVENTS,
  INCREMENTAL_ADS_EVENTS_IN,
  STANDARD_ADS_EVENTS_IN,
  REFRESH_EVENTS,
  SetRefreshPausedApiEvent,
  SetRefreshPausedEvent as SetExternalRefreshPausedEvent,
  ACTIONS,
  AnyBordeauxEvent,
  BordeauxActions,
  GUARDS,
  ACTIONS_RECORD,
} from '@repo/shared-types';
import assign from './proxy/assign';
import GuardArgs from './proxy/guard-args.types';
import sendTo from './proxy/send-to';
import { EventObject, MetaObject, ProvidedActor, TransitionsConfig } from 'xstate';
import forwardEventData from './proxy/forward-event-data';

const apiEvents: TransitionsConfig<
  BordeauxMachineContext,
  AnyBordeauxEvent,
  ProvidedActor,
  BordeauxActions,
  any,
  string,
  EventObject,
  MetaObject
> = {
  [API_EVENTS_OUT.SET_ADTOOL_VERSION]: {
    actions: forwardEventData(ACTIONS.SET_ADTOOL_VERSION),
  },
  [API_EVENTS_OUT.SET_LOAD_GPT_EXTERNALLY]: {
    actions: forwardEventData(ACTIONS.SET_LOAD_GPT_EXTERNALLY),
  },
  [API_EVENTS_OUT.GPT_LOADED_EXTERNALLY]: {
    actions: ({ context, event }: ActionArgs<GptLoadedExternallyApiEvent>): void => {
      context.loadGptExternallyPromise.resolve(event.data);
    },
  },
  [API_EVENTS_OUT.SET_PREBID_ANALYTICS_ENABLED]: {
    actions: forwardEventData(ACTIONS.SET_PREBID_ANALYTICS_ENABLED),
  },
  [API_EVENTS_OUT.SET_AUCTION_TIMEOUTS]: {
    actions: forwardEventData(ACTIONS.SET_AUCTION_TIMEOUTS),
  },
  [API_EVENTS_OUT.SET_AUTOMATIC_DYNAMIC]: {
    actions: forwardEventData(ACTIONS.SET_AUTOMATIC_DYNAMIC),
  },
  [API_EVENTS_OUT.SET_COMPANION_BOUNDS]: {
    actions: forwardEventData(ACTIONS.SET_OVERRIDE_COMPANION_BOUNDS),
  },
  [API_EVENTS_OUT.SET_EXPERIMENT_ID]: [
    {
      guard: GUARDS.HAS_EXPERIMENT_ID,
      actions: ({ context, event }: ActionArgs<SetExperimentIdApiEvent>): void => {
        log.warn(
          `Bordeaux experiment id has already been set to ${context.experimentId}, not setting ${event.data}`,
        );
      },
    },
    {
      actions: [
        forwardEventData(ACTIONS.SET_EXPERIMENT_ID),
        assign<SetExperimentIdApiEvent>({
          timing: ({ context }) => ({
            ...context.timing,
            enabled: true,
          }),
        }),
        forwardEventData(ACTIONS_RECORD.EXPERIMENT_ID),
      ],
    },
  ],
  [API_EVENTS_OUT.SET_PAGE_CATEGORY]: {
    actions: forwardEventData(ACTIONS.SET_PAGE_CATEGORY),
  },
  [API_EVENTS_OUT.SET_PAGE_TEMPLATE]: {
    actions: forwardEventData(ACTIONS.SET_PAGE_TEMPLATE),
  },
  [API_EVENTS_OUT.SET_REFRESH_TIME]: {
    actions: forwardEventData(ACTIONS.SET_REFRESH_TIME),
  },
  [API_EVENTS_OUT.SET_ROADBLOCK_INCREMENTAL_CAPS]: {
    actions: forwardEventData(ACTIONS.SET_ROADBLOCK_INCREMENTAL_CAPS),
  },
  [API_EVENTS_OUT.SET_ROADBLOCK_INCREMENTAL_CHOOSER]: {
    actions: forwardEventData(ACTIONS.SET_ROADBLOCK_INCREMENTAL_CHOOSER),
  },
  [API_EVENTS_OUT.SET_ACTIVATION_DISTANCE]: {
    actions: forwardEventData(ACTIONS.SET_ACTIVATION_DISTANCE),
  },
  [API_EVENTS_OUT.SET_AVOIDANCE_DISTANCE]: {
    actions: forwardEventData(ACTIONS.SET_AVOIDANCE_DISTANCE),
  },
  [API_EVENTS_OUT.SET_THIRD_PARTY_API_CONFIG]: {
    actions: forwardEventData(ACTIONS.SET_THIRD_PARTY_API_CONFIG_OVERRIDES),
  },
  [API_EVENTS_OUT.SET_PAGE_TARGETING]: {
    actions: forwardEventData(ACTIONS.UPDATE_PAGE_TARGETING),
  },
  [API_EVENTS_OUT.ENABLE_FEATURE]: [
    {
      guard: forwardEventData(GUARDS.FEATURE_ENABLED),
      // Do nothing if feature is already enabled
    },
    {
      guard: ({ event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
        event.data === FEATURE.ADS_REFRESH,
      actions: [
        forwardEventData(ACTIONS.ENABLE_FEATURE),
        sendTo<BordeauxMachineContext['automaticRefreshMachine'], EnableFeatureApiEvent>(
          ({ context }) => context.automaticRefreshMachine,
          {
            type: REFRESH_EVENTS.SET_FEATURE_ENABLED,
            data: true,
          },
        ),
      ],
    },
    {
      guard: ({ event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
        event.data === FEATURE.ADS_STANDARD,
      actions: [
        forwardEventData(ACTIONS.ENABLE_FEATURE),
        sendTo('slotify', {
          type: STANDARD_ADS_EVENTS_IN.ENABLED,
        }),
      ],
    },
    {
      guard: ({ event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
        event.data === FEATURE.ADS_INCREMENTAL,
      actions: [
        forwardEventData(ACTIONS.ENABLE_FEATURE),
        sendTo('slotify', {
          type: INCREMENTAL_ADS_EVENTS_IN.ENABLED,
        }),
      ],
    },
  ],
  [API_EVENTS_OUT.DISABLE_FEATURE]: [
    {
      guard: GUARDS.FEATURES_INITIALISED,
      actions: ({ event }: ActionArgs<DisableFeatureApiEvent>): void => {
        log.warn(`Cannot disable the ${event.data} feature after Bordeaux has initialised.`);
      },
    },
    {
      guard: ({ event }: GuardArgs<DisableFeatureApiEvent>): boolean =>
        event.data === FEATURE.ADS_REFRESH,
      actions: [
        forwardEventData(ACTIONS.DISABLE_FEATURE),
        sendTo<BordeauxMachineContext['automaticRefreshMachine'], DisableFeatureApiEvent>(
          ({ context }) => context.automaticRefreshMachine,
          {
            type: REFRESH_EVENTS.SET_FEATURE_ENABLED,
            data: false,
          },
        ),
      ],
    },
    {
      actions: forwardEventData(ACTIONS.DISABLE_FEATURE),
    },
  ],
  [API_EVENTS_OUT.SET_REFRESH_PAUSED]: {
    actions: sendTo<BordeauxMachineContext['automaticRefreshMachine'], SetRefreshPausedApiEvent>(
      ({ context }) => context.automaticRefreshMachine,
      ({ event }): SetExternalRefreshPausedEvent => ({
        type: REFRESH_EVENTS.SET_REFRESH_PAUSED,
        data: event.data,
      }),
    ),
  },
  [API_EVENTS_OUT.SET_FALLBACK_RESPONSES]: {
    actions: forwardEventData(ACTIONS.SET_FALLBACK_RESPONSES),
  },
  [API_EVENTS_OUT.REQUEST_HANDLE_DYNAMIC_SLOTS]: {
    actions: sendTo('slotify', {
      type: SLOTIFY_EVENTS.FIND_NEW_DYNAMIC_SLOTS,
    }),
  },
  [API_EVENTS_OUT.ADD_UNREFRESHABLE_NAMES]: {
    actions: forwardEventData(ACTIONS.ADD_UNREFRESHABLE_NAMES),
  },
};

export default apiEvents;
