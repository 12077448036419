import { ACTIONS, ACTIONS_RECORD, GUARDS } from '@repo/shared-types';
import { EventObject } from 'xstate';

const forwardEventData = <
  E extends EventObject & { data: unknown },
  A extends ACTIONS | ACTIONS_RECORD | GUARDS,
>(
  type: A,
) => ({ type, params: ({ event }: { event: E }): E['data'] => event.data });

export default forwardEventData;
