import { DataObject, Ad, DeviceOptions } from '@repo/shared-types';
import { AdElementGroup } from '@repo/shared-types/src/types/ad-framework/ad/index.types';
import { createElement, createCloseButton, getDeviceSize } from '@repo/utils';

const createAnchoredMarkup = (ad: DataObject<Ad>): AdElementGroup => {
  const isNotMobile = getDeviceSize() !== DeviceOptions.MOBILE;
  const hasCloseButton = isNotMobile || ad.getProperty('closeButton');

  const closeButton = hasCloseButton ? createCloseButton(ad.getProperty('width')) : undefined;

  const element = createElement('div', {
    id: ad.getProperty('id'),
    ariaHidden: 'true',
  });

  const innerContainer = createElement(
    'div',
    {
      id: 'bordeaux_slot_anchored',
      class: 'bordeaux-anchored-inner mobile-leaderboard-320-50 bordeaux-slot',
      style: {
        width: 'auto',
        flexShrink: '0',
        position: 'relative',
      },
    },
    element,
    closeButton || null,
  );

  const outerContainer = createElement(
    'div',
    {
      class: 'bordeaux-anchored-container',
      style: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'fixed',
        bottom: '0',
        left: '0',
        height: 'auto',
        zIndex: '9995',
      },
    },
    innerContainer,
  );

  return {
    element,
    outerContainer,
    innerContainer,
    closeButton,
  };
};

export default createAnchoredMarkup;
