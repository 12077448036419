import {
  Slot,
  DataObject,
  SLOTIFY_EVENTS,
  SlotifyMachineContext,
  CompanionBounds,
  Ad,
  AdDefinition,
  AdUnitMode,
  Targeting,
  SlotLabelPosition,
  AdMatch,
  SLOTIFY_ACTORS,
  SlotViewabilityChangedEvent,
  SlotPositionedEvent,
} from '@repo/shared-types';
import {
  applyStyles,
  getEnv,
  getOnMountSlotStyles,
  log,
  querySelector,
  stringToStyle,
  selectFloorPrice,
  floorPriceTargeting,
  containsVariant,
  fastdom,
} from '@repo/utils';
import { EventObject, fromCallback, fromPromise } from 'xstate';
import { overlapsExclusionZones } from '../exclusion';
import SlotObserver from '../observer';
import staticSlotGenerator from '../generator/static-slots';
import dynamicSlotGenerator from '../generator/dynamic-slots';
import affinityAdGenerator from '../generator/affinity-ads';
import findBestAdForSlot from '../find-best-ad-for-slot';
import { passesAdditionalAvoidance, passesAvoidanceRules } from '../avoidance';
import autoIncrementTargeting from 'ad-framework/ad/auto-increment-targeting';
import createAd from 'ad-framework/ad';
import createSlotifyMarkup from 'ad-framework/ad/handle-create/slotify';
import createAnchoredMarkup from 'ad-framework/ad/handle-create/anchored';
import createOutOfPageMarkup from 'ad-framework/ad/handle-create/out-of-page';
import createSkyscraperMarkup from 'ad-framework/ad/handle-create/skyscraper';
import { createSlotLabel, LABEL_STYLE } from 'ad-framework/ad/create-ad-label';
import { isEmpty } from 'ramda';
import positionElement from 'ad-framework/slot/position-element';

const slotWatcher = fromCallback<
  EventObject,
  {
    slot: DataObject<Slot>;
    activationDistance: number;
  }
>(({ input: { slot, activationDistance }, sendBack }) => {
  const element = slot.getProperty('element');

  const slotBuffer =
    slot.getProperty('genericName') === 'sponsored'
      ? slot.getProperty('sponsoredSlotActivationDistanceOverride') || activationDistance
      : activationDistance;

  const slotName = slot.getProperty('name');

  const slotObserver = new SlotObserver(element, slotName, slotBuffer);

  slotObserver.observe(event => {
    sendBack({
      type: SLOTIFY_EVENTS.SLOT_VIEWABILITY_CHANGED,
      data: {
        slot,
        intersection: event,
      },
    } as SlotViewabilityChangedEvent);
  });
});

const slotPositioner = fromCallback<
  EventObject,
  {
    slot: DataObject<Slot>;
  }
>(({ input: { slot }, sendBack }) => {
  fastdom
    .mutate(() => {
      positionElement(
        slot.getProperty('element'),
        slot.getProperty('position'),
        slot.getProperty('hookElement'),
      );
    })
    .then(() =>
      fastdom.measure(() => {
        const env = getEnv();
        const scrollX = env.scrollX;
        const scrollY = env.scrollY;
        const bounds = slot.getProperty('element').getBoundingClientRect();
        return {
          x: bounds.x + scrollX,
          y: bounds.y + scrollY,
        };
      }),
    )
    .then(position => {
      sendBack({
        type: SLOTIFY_EVENTS.SLOT_POSITIONED,
        data: {
          slot,
          position,
        },
      } as SlotPositionedEvent);
    });
});

const matchSlot = fromPromise<
  null | Array<AdMatch>,
  { slot: DataObject<Slot> } & Pick<
    SlotifyMachineContext,
    | 'slots'
    | 'overrideCompanionBounds'
    | 'config'
    | 'isRoadblock'
    | 'avoidanceDistance'
    | 'roadblockIncrementals'
    | 'adUnits'
    | 'ads'
  >
>(async ({ input }) => {
  const { slot, slots, overrideCompanionBounds, config } = input;

  const masterName = slot.getProperty('genericName');
  const companionDefinitions = config.placement.slots.static.filter(
    slotDefinition => slotDefinition.master === masterName,
  );
  return fastdom.measure(() => {
    if (companionDefinitions.length) {
      const potentialCompanions = slots
        .getValues()
        .filter(slot => slot.getProperty('master') === masterName && !slot.getProperty('masterID'));
      const companionSets = companionDefinitions.map(slotDefinition =>
        potentialCompanions.filter(
          companion => companion.getProperty('genericName') === slotDefinition.name,
        ),
      );

      const comanionsAvailable = companionSets.every(companionSet => companionSet.length > 0);
      if (comanionsAvailable) {
        const env = getEnv();
        const defaultPageBounds: CompanionBounds = { above: 1, below: 800 };
        const pageBounds: CompanionBounds = {
          ...defaultPageBounds,
          ...overrideCompanionBounds,
          ...slot.getProperty('companionBounds'),
        };

        const bounds = slot.getProperty('element').getBoundingClientRect();
        const lowerBound = pageBounds.below === 'screenheight' ? env.innerHeight : pageBounds.below;
        const upperBound = pageBounds.above === 'screenheight' ? env.innerHeight : pageBounds.above;

        const closeCompanions = companionSets.map(companionSet => {
          return companionSet.find(otherSlot => {
            const otherBounds = otherSlot.getProperty('element').getBoundingClientRect();
            if (otherBounds.top - bounds.top > lowerBound) return false;
            if (bounds.top - otherBounds.bottom > upperBound) return false;
            return true;
          });
        });
        const allCompanionsValid = closeCompanions.every(
          (otherSlot): otherSlot is DataObject<Slot> => Boolean(otherSlot),
        );
        if (allCompanionsValid) {
          // Tandem Slots
          closeCompanions.forEach(companionSlot => {
            companionSlot.update({ masterID: slot.getProperty('id') });
          });
          const allSlots = [slot, ...closeCompanions];
          return allSlots.reduce((matches: null | Array<AdMatch>, tandemSlot) => {
            if (!matches) return matches; // If any failed, short-circuit: return null

            const adDefinition = findAdDefinition(tandemSlot, input);
            if (!adDefinition) return null;

            matches.push({
              slot: tandemSlot,
              adDefinition,
            });
            return matches;
          }, []);
        }
      }
    } else if (!slot.getProperty('master')) {
      // Normal slot
      const adDefinition = findAdDefinition(slot, input);
      if (adDefinition) {
        return [
          {
            slot,
            adDefinition,
          },
        ];
      }
    }
    return null;
  });
});

const createAds = fromPromise<
  Array<DataObject<Ad>>,
  Pick<
    SlotifyMachineContext,
    'adMatches' | 'adCounter' | 'pageAdUnitPath' | 'adTypeCounters' | 'config'
  >
>(async ({ input: { adMatches, adCounter, pageAdUnitPath, adTypeCounters, config } }) =>
  adMatches.map(({ slot, adDefinition }, index, list) => {
    const adID = `bordeaux-ad-${adCounter + index}`;
    if (slot) {
      slot.update({ adID });
    }

    let targeting: Targeting = {};
    if (
      (containsVariant(config, '50', '163') || containsVariant(config, '50', '164')) &&
      config.bidFloors?.floorPrices
    ) {
      const floorPrice = selectFloorPrice(config, adDefinition.sizes);
      if (floorPrice) {
        targeting = {
          ...targeting,
          flrp: floorPriceTargeting(floorPrice),
        };
      }
    }
    if (slot) {
      targeting = {
        ...targeting,
        _slot: slot.getProperty('name'),
        _slot_type: slot.getProperty('genericName'),
      };
    }
    if (adDefinition.incremental) {
      targeting = {
        ...targeting,
        ...autoIncrementTargeting(adDefinition),
        adUnitName: [adDefinition.name],
      };
    }

    return createAd(adDefinition, {
      id: adID,
      targeting,
      ...(slot
        ? {
            slotID: slot.getProperty('id'),
          }
        : {}),
      ...(adDefinition.incremental
        ? {
            // ADP-10451: "limit the ad unit path on incremental ad units to a maximum of ten"
            adUnitPath: `${pageAdUnitPath}/${adDefinition.name}-${Math.min(10, list.slice(0, index).reduce((counter, item) => counter + (item.adDefinition.name === adDefinition.name ? 1 : 0), 0) + (adTypeCounters[adDefinition.name] || 0))}`,
          }
        : {
            adUnitPath: `${pageAdUnitPath}/${adDefinition.name}`,
          }),
    });
  }),
);

const createAdElements = (ad: DataObject<Ad>) => {
  switch (ad.getProperty('mode')) {
    case AdUnitMode.SLOTIFY:
      return createSlotifyMarkup(ad);
    case AdUnitMode.ANCHORED:
      return createAnchoredMarkup(ad);
    case AdUnitMode.OOP:
      return createOutOfPageMarkup(ad);
    case AdUnitMode.SKYSCRAPER:
      return createSkyscraperMarkup(ad);
    default:
      return null;
  }
};

const inlineLeftRightSlotsStore: Map<string, number> = new Map();

const computeAndStoreNbOccurencesOfCurrentSlot = (genericSlotName: string | undefined): number => {
  let nbSlotOccurences = 0;
  if (genericSlotName) {
    if (inlineLeftRightSlotsStore.get(genericSlotName) !== undefined) {
      nbSlotOccurences = (inlineLeftRightSlotsStore.get(genericSlotName) || 0) + 1;
    }
    inlineLeftRightSlotsStore.set(genericSlotName, nbSlotOccurences);
  }
  return nbSlotOccurences;
};

const insertAds = fromPromise<void, SlotifyMachineContext>(
  async ({ input: { newAds, slots } }): Promise<void> => {
    const env = getEnv();
    const results = await Promise.allSettled(
      newAds.map(async ad => {
        const mode = ad.getProperty('mode');
        const elements = createAdElements(ad);
        if (!elements) {
          throw new Error(
            'Ad cannot be inserted into the DOM because its elements could not be created',
          );
        }
        ad.update({ elements });
        switch (mode) {
          case AdUnitMode.OOP:
          case AdUnitMode.ANCHORED: {
            const container = elements?.outerContainer;
            if (!container) {
              throw new Error(
                'Ad cannot be inserted into the DOM because it has no outer container',
              );
            }
            await fastdom.mutate(() => {
              env.document.body.appendChild(container);
            });
            break;
          }
          case AdUnitMode.SKYSCRAPER: {
            const container = elements?.outerContainer;
            if (!container) {
              throw new Error(
                'Ad cannot be inserted into the DOM because it has no outer container',
              );
            }
            const env = getEnv();
            const main = env.document.getElementById('main');
            if (!main) {
              throw new Error('Error in skyscraper setup, #main element not found');
            }
            const { width: contentWidth } = await fastdom.measure(() =>
              main.getBoundingClientRect(),
            );

            const skyscraperContainer =
              querySelector<HTMLElement>('.skyscraper-container') ||
              env.document.createElement('div');

            skyscraperContainer.classList.add('skyscraper-container');
            const skyscraperContainerStyle = {
              width: `${contentWidth}px`,
              height: 0,
              margin: `auto`,
              position: 'sticky',
              top: 0,
              'pointer-events': 'none',
            };
            Object.assign(skyscraperContainer.style, skyscraperContainerStyle);

            await fastdom.mutate(() => {
              if (!main.parentNode) {
                throw new Error('Error in skyscraper setup, #main element has no parent');
              }
              skyscraperContainer.appendChild(container);
              main.parentNode.insertBefore(skyscraperContainer, main);
            });
            break;
          }
          case AdUnitMode.SLOTIFY: {
            const adElement = elements?.element;
            if (!adElement) {
              throw new Error('Ad cannot be inserted into the DOM because it has no element');
            }
            const slotID = ad.getProperty('slotID');
            const slot = slots.getValues().find(slot => slot.getProperty('id') === slotID);
            if (!(slotID && slot)) {
              throw new Error('Ad cannot be inserted into the DOM because it has no slot');
            }
            const slotElement = slot.getProperty('element');
            const slotLabel = slot.getProperty('label');
            const slotHasLabel = !(!slotLabel || isEmpty(slotLabel) || slotLabel.applyLabelToAds);
            const labelStyle = stringToStyle(slotLabel?.style || LABEL_STYLE);
            const slotStyles = getOnMountSlotStyles(
              slot,
              (slotHasLabel && labelStyle.height) || '0',
              computeAndStoreNbOccurencesOfCurrentSlot(slot.getProperty('genericName')),
            );

            const applyLabelToAds =
              slotLabel.applyLabelToAds && slotLabel.applyLabelToAds === 'true';
            if (applyLabelToAds) {
              ad.update({ label: slotLabel });
            }

            await fastdom.mutate(() => {
              applyStyles(slotElement, slotStyles);

              slotElement.classList.add('bordeaux-filled-slot');
              slotElement.ariaHidden = 'true';

              slotElement.appendChild(adElement);

              if (slotHasLabel) {
                const labelElement = createSlotLabel(slotID, slotLabel);
                if (slotLabel.position === SlotLabelPosition.ABOVE) {
                  slotElement.insertBefore(labelElement, slotElement.firstChild);
                }
                if (slotLabel.position === SlotLabelPosition.BELOW) {
                  slotElement.style.justifyContent = 'space-between';
                  slotElement.lastChild?.after(labelElement);
                }
              }
            });
            break;
          }
          default:
            throw new Error(`Ad insertion issue, ad has unrecognised mode: ${mode}`);
        }
      }),
    );
    const failed = results.filter(r => r.status === 'rejected');
    if (failed.length) {
      log.error('Ad elements could not be inserted', failed);
    }
  },
);

const slotifyActors = {
  [SLOTIFY_ACTORS.STATIC_SLOT_GENERATOR]: staticSlotGenerator,
  [SLOTIFY_ACTORS.DYNAMIC_SLOT_GENERATOR]: dynamicSlotGenerator,
  [SLOTIFY_ACTORS.AFFINITY_AD_GENERATOR]: affinityAdGenerator,
  [SLOTIFY_ACTORS.SLOT_WATCHER]: slotWatcher,
  [SLOTIFY_ACTORS.MATCH_SLOT]: matchSlot,
  [SLOTIFY_ACTORS.CREATE_ADS]: createAds,
  [SLOTIFY_ACTORS.INSERT_ADS]: insertAds,
  [SLOTIFY_ACTORS.SLOT_POSITIONER]: slotPositioner,
};
export default slotifyActors;

const findAdDefinition = (
  slot: DataObject<Slot>,
  context: Pick<
    SlotifyMachineContext,
    'isRoadblock' | 'avoidanceDistance' | 'roadblockIncrementals' | 'adUnits' | 'slots' | 'ads'
  >,
): null | AdDefinition => {
  const {
    isRoadblock,
    avoidanceDistance,
    roadblockIncrementals,
    adUnits: { incremental: adDefinitions },
  } = context;
  const selectedAdDefinitions =
    isRoadblock && !slot.getProperty('nativeContent') ? roadblockIncrementals : adDefinitions;

  if (selectedAdDefinitions.length === 0) {
    return null;
  }

  if (slot.getProperty('adID') !== undefined) {
    return null;
  }
  if (!passesAdditionalAvoidance(avoidanceDistance, slot)) {
    return null;
  }
  if (!slot.getProperty('ignoreExclusion') && overlapsExclusionZones(slot)) {
    return null;
  }
  const adDefinition = selectedAdDefinitions.reduce(findBestAdForSlot(slot), null);

  if (
    adDefinition &&
    (adDefinition.ignoreCategoryAvoidance || passesAvoidanceRules(context, slot, adDefinition))
  ) {
    return adDefinition;
  }
  return null;
};
