import { getEnv, TimeoutError } from '@repo/utils';
import {
  DataObject,
  ThirdParty,
  Ad,
  GUARDS,
  BordeauxMachineContext,
  AnyBordeauxEvent,
  BordeauxGuards,
  FEATURE,
  Slot,
} from '@repo/shared-types';
import getRoadblockStatus from 'ad-framework/roadblock';
import { GuardPredicate } from 'xstate/guards';

const guards: Record<
  GUARDS,
  GuardPredicate<BordeauxMachineContext, AnyBordeauxEvent, any, BordeauxGuards>
> = {
  [GUARDS.TIMING_COLLECTION_ENABLED]: ({ context }) => context.timing.enabled,
  [GUARDS.AUCTION_IS_UNSENT]: ({ context }, auctionId: number) =>
    context.timing.unsentAuctions.includes(auctionId),
  [GUARDS.ERROR_IS_TIMEOUT]: (_, error: Error) => error instanceof TimeoutError,
  [GUARDS.DUPLICATE_SCRIPTS]: () => {
    const env = getEnv();
    return (
      (
        env.document.querySelectorAll('script[src="https://bordeaux.futurecdn.net/bordeaux.js"]') ||
        []
      ).length > 1
    );
  },
  [GUARDS.HAS_ENOUGH_CONSENT_FOR_AUCTION]: ({
    context: {
      gdprConsent: { hasEnoughConsentForAuction },
    },
  }) => hasEnoughConsentForAuction,
  [GUARDS.ROADBLOCK_UNDECIDED]: ({ context }) => context.isRoadblock === null,
  [GUARDS.ROADBLOCK_IS_DECIDABLE]: ({ context }) => getRoadblockStatus(context) !== null,
  [GUARDS.FEATURES_INITIALISED]: ({ context }): boolean => context.featuresInitialised,
  [GUARDS.HAS_EXPERIMENT_ID]: ({ context }): boolean => context.experimentId.length !== 0,
  [GUARDS.AD_TOOL_OPENED_FROM_URL]: ({ context }) => context.queryParameters.debugTool !== null,
  [GUARDS.ADS_BLOCKED]: ({ context }) => context.adBlocked,
  [GUARDS.PREBID_ENABLED]: ({ context }): boolean =>
    context.thirdPartyResults[ThirdParty.PREBID].config.enabled &&
    context.thirdPartyResults[ThirdParty.PREBID].success,
  [GUARDS.LIVE_INTENT_USER_SYNC_ENABLED]: ({ context }) => context.liveIntentUserSyncEnabled,
  [GUARDS.CONSENT_DONE]: ({ context }) => context.uspConsent.done && context.gdprConsent.done,
  [GUARDS.HAS_ADS]: (_, ads: Array<DataObject<Ad>>) => ads.length !== 0,

  [GUARDS.FEATURE_ENABLED]: ({ context }, featureName: FEATURE): boolean =>
    Boolean(context.features[featureName]),
  [GUARDS.SCROLLED_PAST_FIRST_TANDEM]: ({ context: { firstTandemAdPosition } }) => {
    const env = getEnv();
    const scroll = env.scrollY;
    const height = env.innerHeight;
    return scroll + height > firstTandemAdPosition;
  },
  [GUARDS.SLOT_IS_BEFORE_FIRST_TANDEM]: (
    { context: { firstTandemAdPosition, config } },
    { slot, position }: { slot: DataObject<Slot>; position: { x: number; y: number } },
  ) => {
    const masterName = slot.getProperty('genericName');
    const companionDefinitions = config.placement.slots.static.filter(
      slotDefinition => slotDefinition.master === masterName,
    );
    if (!slot.getProperty('masterID') && companionDefinitions.length === 0) {
      return false;
    }
    return position.y < firstTandemAdPosition;
  },
};
export default guards;
