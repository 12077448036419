import getEnv from '../env';
import {
  AnyEventObject,
  assign,
  DoneActorEvent,
  fromCallback,
  fromPromise,
  sendParent,
  setup,
} from 'xstate';
import {
  TCFAPI,
  USPAPI,
  PingReturn,
  GDPRDataResponse,
  GDPRConsent,
  USPDataResponse,
  USPConsent,
  GDPRConsentStatus,
  USPConsentStatus,
  ConsentMachineContext,
  GDPR_CMP_EVENTS,
  CMP_EVENTS,
  GPPAPI,
  GPPConsent,
  GPPConsentStatus,
  GPPDataResponse,
  CMP_ACTORS,
  AnyConsentEvent,
  CMP_STATES,
  ConsentMachineActionArgs,
} from '@repo/shared-types';
import getTCFAPI from './api/tcfapi';
import getUSPAPI from './api/uspapi';
import getGPPAPI from './api/gpp';
import { log } from '../log';

const getGDPRLoadedMachine = fromCallback<AnyEventObject, Pick<ConsentMachineContext, 'tcfapi'>>(
  ({ sendBack, input: context }) => {
    const env = getEnv();
    const pingInterval = env.setInterval(() => {
      try {
        (env.__tcfapi || context.tcfapi)('ping', 2, (response: PingReturn) => {
          if (response.cmpStatus === 'stub') {
            sendBack({ type: GDPR_CMP_EVENTS.PENDING });
          } else if (response.cmpStatus === 'loaded' && response.mocked) {
            sendBack({ type: GDPR_CMP_EVENTS.MOCKED });
          } else if (response.cmpStatus === 'loaded') {
            sendBack({ type: GDPR_CMP_EVENTS.LOADED });
          }
        });
      } catch (error) {
        sendBack({
          type: GDPR_CMP_EVENTS.FAILURE,
          data: error as Error,
        });
      }
    }, 50);
    return (): void => {
      env.clearInterval(pingInterval);
    };
  },
);

const retrieveGDPRConsentMachine = fromPromise<
  GDPRDataResponse,
  Pick<ConsentMachineContext, 'tcfapi'>
>(
  ({ input: context }) =>
    new Promise<GDPRDataResponse>((resolve, reject) => {
      const env = getEnv();
      (env.__tcfapi || context.tcfapi)(
        'addEventListener',
        2,
        (data: GDPRDataResponse, success: boolean) => {
          if (!success) {
            reject(
              new Error(
                `Failed to get GDPR consent data, __tcfapi addEventListener request failed`,
              ),
            );
          } else if (data.eventStatus === 'tcloaded' || data.eventStatus === 'useractioncomplete') {
            resolve(data);
          }
        },
      );
    }),
);

export const getUSPConsent = fromPromise(async (): Promise<USPConsent> => {
  const uspDataResponse = await new Promise<USPDataResponse | null>((resolve, reject) => {
    getUSPAPI().then((uspapi: USPAPI) => {
      uspapi('getUSPData', 1, (response: USPDataResponse | null, success: boolean) => {
        if (response && success && Object.keys(response).includes('uspString')) {
          resolve(response);
        } else {
          reject(new Error(`Failed to get USP consent data, __uspapi getUSPData request failed`));
        }
      });
    });
  }).catch((error: Error) => {
    log.error(`Error retrieving USP consent. ${error.message}`);
    return null;
  });

  if (uspDataResponse === null) {
    return {
      done: true,
      askedForConsent: false,
      consent: null,
      ccpaApplies: false,
      status: USPConsentStatus.NOT_APPLICABLE,
    };
  }

  if (uspDataResponse.uspString.includes('1---')) {
    return {
      done: true,
      askedForConsent: true,
      consent: null,
      ccpaApplies: false,
      status: USPConsentStatus.NOT_APPLICABLE,
    };
  }

  return {
    done: true,
    askedForConsent: true,
    consent: uspDataResponse,
    ccpaApplies: true,
    status: uspDataResponse.uspString[1] === 'Y' ? USPConsentStatus.TRUE : USPConsentStatus.FALSE,
  };
});

export const getGPPConsent = fromPromise(async (): Promise<GPPConsent> => {
  const gppDataResponse = await new Promise<GPPDataResponse | null>((resolve, reject) => {
    getGPPAPI().then((gppAPI: GPPAPI) => {
      gppAPI('addEventListener', (response: GPPDataResponse | null, success: boolean) => {
        if (
          success &&
          response &&
          response.pingData.signalStatus === 'ready' &&
          Object.keys(response.pingData).includes('gppString') &&
          response.pingData.gppString !== ''
        ) {
          resolve(response);
        } else if (success && response && response.pingData.signalStatus !== 'ready') {
          // GPP signals not ready.
        } else {
          reject(
            new Error(`Failed to get GPP consent data, __gpp addEventListener request failed`),
          );
        }
      });
    });
  }).catch((error: Error) => {
    log.error(`Error retrieving GPP consent. ${error.message}`);
    return null;
  });

  if (gppDataResponse === null) {
    return {
      done: true,
      // askedForConsent: false,
      consent: null,
      status: GPPConsentStatus.NULL_PAYLOAD,
    };
  }

  if (
    (arr => Array.isArray(arr) && arr.length === 1 && arr[0] === -1)(
      gppDataResponse.pingData.applicableSections,
    )
  ) {
    return {
      done: true,
      // askedForConsent: true,
      consent: gppDataResponse,
      status: GPPConsentStatus.NOT_APPLICABLE,
    };
  }

  return {
    done: true,
    // askedForConsent: true,
    consent: gppDataResponse,
    status: GPPConsentStatus.TRUE,
  };
});

export const hasEnoughConsentForAuction = (consent: GDPRDataResponse): boolean => {
  const { gdprApplies, purpose } = consent;

  if (!gdprApplies) {
    return true;
  }
  return (
    purpose.consents[2] ||
    purpose.legitimateInterests[2] ||
    purpose.consents[7] ||
    purpose.legitimateInterests[7] ||
    purpose.consents[9] ||
    purpose.legitimateInterests[9] ||
    purpose.consents[10] ||
    purpose.legitimateInterests[10] ||
    false
  );
};

export const hasUserConsentedVendorGDPR = (
  response: GDPRConsent,
  iabID?: string | number,
): boolean => {
  if (response.consent === null) {
    return false;
  }

  const { gdprApplies, vendor } = response.consent;
  const vendorConsented: boolean =
    iabID !== undefined && vendor && vendor.consents && vendor.consents[iabID];

  return !gdprApplies || vendorConsented;
};

export const hasUserOptOutCCPA = (uspConsent: USPConsent): boolean => {
  if (
    !uspConsent ||
    !uspConsent.ccpaApplies ||
    !uspConsent.consent ||
    uspConsent.consent.uspString.length < 3
  ) {
    return false;
  }

  return uspConsent.consent.uspString[2] === 'Y';
};

export const getGDPRConsentMachine = setup({
  types: {} as {
    context: ConsentMachineContext;
    events: AnyConsentEvent;
    output: GDPRConsent;
  },
  actors: {
    [CMP_ACTORS.GET_TCF_API]: fromPromise(getTCFAPI),
    [CMP_ACTORS.GET_GDPR_LOADED_MACHINE]: getGDPRLoadedMachine,
    [CMP_ACTORS.RETRIEVE_GDPR_CONSENT_MACHINE]: retrieveGDPRConsentMachine,
  },
}).createMachine({
  id: 'consent',
  initial: CMP_STATES.GET_TCF_API,
  context: {
    tcfapi: {},
    result: {
      done: false,
      askedForConsent: false,
      consent: null,
      status: GDPRConsentStatus.NOT_APPLICABLE,
      hasEnoughConsentForAuction: false,
    },
  } as ConsentMachineContext,
  output: ({ context }): GDPRConsent => ({
    ...context.result,
    done: true,
  }),
  states: {
    [CMP_STATES.GET_TCF_API]: {
      invoke: {
        src: CMP_ACTORS.GET_TCF_API,
        onDone: {
          actions: assign({
            tcfapi: ({ event }: ConsentMachineActionArgs<DoneActorEvent<TCFAPI>>) => event.output,
          }),
          target: CMP_STATES.LOAD_API,
        },
        onError: {
          actions: ({ event: { error } }) => {
            const errorMessage = error instanceof Error ? error.message : error;
            log.error(`Error getting TCF API. ${errorMessage}`);
          },
          target: CMP_STATES.FINISHED,
        },
      },
    },
    [CMP_STATES.LOAD_API]: {
      invoke: {
        src: CMP_ACTORS.GET_GDPR_LOADED_MACHINE,
        input: ({ context }) => ({
          tcfapi: context.tcfapi,
        }),
      },
      on: {
        [GDPR_CMP_EVENTS.PENDING]: {
          actions: sendParent({ type: CMP_EVENTS.PENDING }),
        },
        [GDPR_CMP_EVENTS.LOADED]: {
          actions: sendParent({ type: CMP_EVENTS.LOADED }),
          target: CMP_STATES.RETRIEVE_CONSENT,
        },
        [GDPR_CMP_EVENTS.MOCKED]: {
          actions: sendParent({ type: CMP_EVENTS.MOCKED }),
          target: CMP_STATES.RETRIEVE_CONSENT,
        },
        [GDPR_CMP_EVENTS.FAILURE]: {
          actions: ({ event }) =>
            log.error(`Error retrieving GDPR loaded. ${event.data?.message || ''}`),
          target: CMP_STATES.FINISHED,
        },
      },
    },
    [CMP_STATES.RETRIEVE_CONSENT]: {
      invoke: {
        src: CMP_ACTORS.RETRIEVE_GDPR_CONSENT_MACHINE,
        input: ({ context }) => ({
          tcfapi: context.tcfapi,
        }),
        onError: {
          actions: ({ event: { error } }) => {
            const errorMessage = error instanceof Error ? error.message : error;
            log.error(`Error retrieving GDPR consent. ${errorMessage}`);
          },
          target: CMP_STATES.FINISHED,
        },
        onDone: {
          actions: assign({
            result: ({ context, event }) => {
              const consent = event.output;
              let { status } = context.result;
              if (consent && consent.gdprApplies) {
                const hasVendors = Object.keys(consent.vendor.consents || {}).length;
                const hasInterests = Object.keys(consent.vendor.legitimateInterests || {}).length;
                if (hasInterests && hasVendors) {
                  status = GDPRConsentStatus.ACCEPT;
                } else if (hasInterests) {
                  status = GDPRConsentStatus.LEGITIMATE_INTEREST;
                } else {
                  status = GDPRConsentStatus.REJECT_ALL;
                }
              } else {
                status = GDPRConsentStatus.NOT_APPLICABLE;
              }
              return {
                done: true,
                askedForConsent: true,
                consent,
                status,
                hasEnoughConsentForAuction: consent ? hasEnoughConsentForAuction(consent) : false,
              };
            },
          }),
          target: CMP_STATES.FINISHED,
        },
      },
    },
    [CMP_STATES.FINISHED]: {
      type: 'final',
    },
  },
});
