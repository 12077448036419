import {
  getGDPRConsentMachine,
  getGPPConsent,
  getUSPConsent,
  elementTapHandler,
  getEnvDOMContentLoaded,
} from '@repo/utils';
import { checkConfig, fetchConfig, parseConfig } from 'config';

import { setupThirdPartyAPI } from 'third-party-apis/index';

import listenForAdFeatures from 'ad-features/post-message-listener';
import anchoredLogic from 'ad-framework/ad/anchored-logic';
import { ACTORS } from '@repo/shared-types';
import initAutomaticRefresh from 'ad-framework/refresh/automatic';
import setupAPI from 'api';
import slotifyMachine from 'slotify';
import arbitraryEventEmitter from './arbitrary-event-emitter';
import pageEventEmitter from './page-event-emitter';
import readPageStyles from './read-page-styles';
import waitForAllThirdParties from './wait-for-all-third-parties';
import adManagerMachine from 'ad-framework/ad-manager';
import { adPreProcessor } from 'ad-framework/ad-manager/pre-process-ads';

const actors = {
  [ACTORS.ARBITRARY_EVENT_EMITTER]: arbitraryEventEmitter,
  [ACTORS.ELEMENT_TAP_HANDLER]: elementTapHandler,
  [ACTORS.INIT_AUTOMATIC_REFRESH]: initAutomaticRefresh,
  [ACTORS.PAGE_EVENT_EMITTER]: pageEventEmitter,
  [ACTORS.GET_ENV_DOM_CONTENT_LOADED]: getEnvDOMContentLoaded,
  [ACTORS.SETUP_API]: setupAPI,
  [ACTORS.FETCH_CONFIG]: fetchConfig,
  [ACTORS.CHECK_CONFIG]: checkConfig,
  [ACTORS.PARSE_CONFIG]: parseConfig,
  [ACTORS.GET_GDPR_CONSENT_MACHINE]: getGDPRConsentMachine,
  [ACTORS.GET_USP_CONSENT]: getUSPConsent,
  [ACTORS.GET_GPP_CONSENT]: getGPPConsent,

  [ACTORS.SETUP_THIRD_PARTY_API]: setupThirdPartyAPI,

  [ACTORS.LISTEN_FOR_AD_FEATURES]: listenForAdFeatures,
  [ACTORS.WAIT_FOR_ALL_THIRD_PARTIES]: waitForAllThirdParties,
  [ACTORS.READ_PAGE_STYLES]: readPageStyles,
  [ACTORS.ANCHORED_LOGIC]: anchoredLogic,
  [ACTORS.SLOTIFY_MACHINE]: slotifyMachine,
  [ACTORS.AD_MANAGER_MACHINE]: adManagerMachine,
  [ACTORS.AD_PRE_PROCESSOR]: adPreProcessor,
};
export default actors;
