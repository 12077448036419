import { and, setup } from 'xstate';

import { cookies } from '@repo/utils';

import {
  Ad,
  AdUnitCategory,
  AdUnitMode,
  AdUnitStatus,
  Slot,
  EVENTS,
  FEATURE,
  AuctionPayload,
  Payload,
  DataObjectStore,
  BordeauxMachineContext,
  ProvideBordeauxContextEvent,
  ProvideBordeauxDataEvent,
  AnyBordeauxEvent,
  RequestBordeauxContextEvent,
  RequestBordeauxDataEvent,
  ACTIONS,
  GUARDS,
  STATES,
  ACTORS,
  METRICS,
  REPORT,
  REPORT_THIRD_PARTY_SCRIPT,
  ThirdPartyReportEvent,
  FullAPISetupResults,
  AD_FEATURES_EVENTS,
  ThirdPartyEvent,
  API_EVENTS_OUT,
  STANDARD_ADS_EVENTS_OUT,
  ACTIONS_RECORD,
  CMP_EVENTS,
  GDPRConsentStatus,
  GPPConsentStatus,
  USPConsentStatus,
} from '@repo/shared-types';

import defaultFallbacks from 'config/fallback';

import { accessObjectPath, getEnv, timeData } from '@repo/utils';
import userSyncPixels from '../user-sync/user-sync-config';
import actions from './actions';
import apiEvents from './api';
import raise from './proxy/raise';
import sendTo from './proxy/send-to';
import * as report from './report';
import { SommelierResponse } from 'config/sommelier-request/sommelier-response.types';
import { BordeauxConfig } from '@repo/shared-types/src/zod-schemas';
import { BordeauxMachineDefinition } from '@repo/shared-types';
import { SLOTIFY_EVENTS_OUT } from '@repo/shared-types';
import { AD_MANAGER_EVENTS_OUT } from '@repo/shared-types/src/types/ad-framework/events.types';
import forwardEventData from './proxy/forward-event-data';
import guards from './guards';
import actors from './actors';
import assign from './proxy/assign';

const bordeauxMachine: BordeauxMachineDefinition = setup({
  types: {} as {
    context: BordeauxMachineContext;
    events: AnyBordeauxEvent;
  },
  actors: {
    ...actors,
  },
  guards: {
    ...guards,
  },
  actions: {
    ...report.recordActions,
    ...report.reportActions,
    ...actions,
  },
}).createMachine({
  id: 'bordeaux',
  initial: STATES.INITIALISING,
  context: {
    config: {} as BordeauxConfig,
    fallbackResponses: defaultFallbacks,
    sommelierResponse: {} as SommelierResponse,
    slots: new DataObjectStore<Slot>(),
    ads: new DataObjectStore<Ad>(),
    adUnits: { incremental: [], standard: [] },
    adManager: {} as BordeauxMachineContext['adManager'],
    adPreProcessors: {},
    anchoredRefreshDisabled: false,
    timing: {
      payload: {} as Payload,
      auctionPayloads: {} as Record<number, AuctionPayload>,
      endpoint: `https://eventsproxy.gargantuan.futureplc.com/future.adtech.bordeaux.v1.AdRequestCompletedEvent`,
      unsentAuctions: [],
      sampleRate: 1,
      enabled: false,
    },
    thirdPartyMachines: {} as BordeauxMachineContext['thirdPartyMachines'],
    thirdPartyResults: {} as FullAPISetupResults,
    metrics: {
      [METRICS.THIRD_PARTY_SCRIPTS]: {},
      [METRICS.AUCTIONS]: {},
    },
    auctions: {},
    gdprConsent: {
      done: false,
      askedForConsent: false,
      consent: null,
      status: GDPRConsentStatus.NOT_APPLICABLE,
      hasEnoughConsentForAuction: false,
    },
    uspConsent: {
      done: false,
      askedForConsent: false,
      consent: null,
      ccpaApplies: false,
      status: USPConsentStatus.NOT_APPLICABLE,
    },
    gppConsent: {
      done: false,
      consent: null,
      status: GPPConsentStatus.NOT_APPLICABLE,
    },
    experimentId: '',
    hybridABTestTargeting: [],
    hybridId: '',
    gaSessionId: cookies.gaSessionId(),
    arbitraryEventEmitter: {} as BordeauxMachineContext['arbitraryEventEmitter'],
    adFeatureMachine: {} as BordeauxMachineContext['adFeatureMachine'],
    pageEventEmitter: {} as BordeauxMachineContext['pageEventEmitter'],
    cftParameters: {
      labelName: 'UNDECIDED',
    } as BordeauxMachineContext['cftParameters'],
    pageParameters: {} as BordeauxMachineContext['pageParameters'],
    queryParameters: {} as BordeauxMachineContext['queryParameters'],
    adToolTapOpenMachine: {} as BordeauxMachineContext['adToolTapOpenMachine'],
    automaticRefreshMachine: {} as BordeauxMachineContext['automaticRefreshMachine'],
    externalApiMachine: {} as BordeauxMachineContext['externalApiMachine'],
    pageStyleConstants: {} as BordeauxMachineContext['pageStyleConstants'],
    anchoredMachine: {} as BordeauxMachineContext['anchoredMachine'],
    adBlocked: false,
    isRoadblock: null,
    roadblockIncrementals: [],
    loadGptExternallyPromise: ((): BordeauxMachineContext['loadGptExternallyPromise'] => {
      let resolve;
      const promise = new Promise<boolean>(res => {
        resolve = res;
      });
      return {
        promise,
        resolve,
      };
    })(),
    userSyncPixels,
    slotViewability: {},
    firstTandemAdPosition: Infinity,
    // API
    overrideCompanionBounds: {},
    unrefreshableNames: [],
    unrefreshableLineItems: [],
    unrefreshableOrders: [],
    unrefreshableAdvertisers: [],
    unrefreshableStatuses: [AdUnitStatus.PENDING],
    unrefreshableModes: [AdUnitMode.OOP],
    unrefreshableCategories: [AdUnitCategory.SPONSORED_POST],
    adToolVersion: '',
    loadGptExternally: false,
    prebidAnalyticsEnabled: false,
    auctionTimeouts: undefined,
    automaticDynamic: true,
    pageCategory: undefined,
    pageTemplate: undefined,
    refreshTime: 0,
    roadblockIncrementalCaps: null,
    roadblockIncrementalChooser: null,
    activationDistance: 1200,
    deviceAvoidanceDistance: undefined,
    avoidanceDistance: 400,
    thirdPartyApiConfig: {} as BordeauxMachineContext['thirdPartyApiConfig'],
    thirdPartyApiConfigOverrides: {},
    pageTargeting: {},
    pageAdUnitPath: 'undecided',
    features: {
      [FEATURE.ADS_INCREMENTAL]: true,
      [FEATURE.ADS_STANDARD]: true,
      [FEATURE.ADS_REFRESH]: true,
    },
    featuresInitialised: false,
    liveIntentUserSyncEnabled: false,
  },
  states: {
    [STATES.INITIALISING]: {
      entry: [
        ACTIONS.CREATE_AD_FEATURE_MACHINE,
        ACTIONS.DECIDE_TIMING_ENABLED,
        ACTIONS.CREATE_PAGE_EVENT_EMITTER,
      ],
      invoke: {
        src: ACTORS.GET_ENV_DOM_CONTENT_LOADED,
        onDone: {
          actions: {
            type: ACTIONS.REPORT_CONTENT_LOAD,
            params: ({ event }) => event.output,
          },
          target: STATES.SCRAPING_PARAMATERS,
        },
        onError: STATES.ERROR,
      },
    },
    [STATES.SCRAPING_PARAMATERS]: {
      entry: [
        ACTIONS.CHECK_AD_BLOCK,
        ACTIONS.READ_QUERY_PARAMETERS,
        ACTIONS.READ_PAGE_PARAMETERS,
        ACTIONS_RECORD.INITIALISE_PAYLOAD,
        ACTIONS.CREATE_SHAMEFUL_MACHINE,
        ACTIONS.ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE,
        ACTIONS.ASSIGN_BORDEAUX_ADS_PROMISE,
        ACTIONS.CHECK_MULTIPLE_SCRIPTS,
        ACTIONS.CREATE_ADTOOL_TAP_OPEN_MACHINE,
        ACTIONS.CHECK_ADTOOL_PARAM,
        ACTIONS.CREATE_AUTOMATIC_REFRESH_MACHINE,
        ACTIONS.REPORT_IF_AD_BLOCKED,
        ACTIONS.CREATE_API_MACHINE,
      ],
      on: {
        [EVENTS.SET_EXTERNAL_API]: {
          actions: [
            ({ event }): void => {
              const env = getEnv();
              // Casting to any to allow us to attach to the env without types which would
              // cause circular reference warnings.
              (env as any).bordeaux = event.data; // eslint-disable-line @typescript-eslint/no-explicit-any
              (env as any).bdx = event.data; // eslint-disable-line @typescript-eslint/no-explicit-any
            },
            ACTIONS.UPDATE_API_READY,
          ],
        },
        [API_EVENTS_OUT.COMMANDS_RUN]: [
          {
            guard: ({
              event: {
                data: { initialised },
              },
            }) => initialised,
            target: STATES.FETCHING_CONFIG,
          },
          {
            target: STATES.WAIT_FOR_INITIALISATION,
          },
        ],
      },
    },
    [STATES.WAIT_FOR_INITIALISATION]: {
      on: {
        [API_EVENTS_OUT.INITIALISE]: {
          target: STATES.FETCHING_CONFIG,
        },
      },
    },
    [STATES.FETCHING_CONFIG]: {
      entry: [
        REPORT.FRAMEWORK_REQUEST,
        ACTIONS.INITIALISE_SENTRY,
        ACTIONS.INITIALISE_FEATURES,
        REPORT.CONFIG_REQUEST,
      ],
      invoke: {
        src: ACTORS.FETCH_CONFIG,
        input: ({ context }) => ({
          pageTemplate: context.pageTemplate,
          gaSessionId: context.gaSessionId,
        }),
        onDone: {
          actions: [
            REPORT.CONFIG_LOAD,
            {
              type: ACTIONS.SET_SOMMELIER_RESPONSE,
              params: ({ event }): SommelierResponse => event.output,
            },
          ],
          target: STATES.CHECKING_CONFIG,
        },
        onError: {
          actions: [
            { type: ACTIONS.REPORT_CONFIG_FAILURE, params: ({ event }) => event.error as Error },
            ACTIONS.USE_FALLBACK_CONFIG,
          ],
          target: STATES.PARSING_CONFIG,
        },
      },
    },
    [STATES.CHECKING_CONFIG]: {
      invoke: {
        src: ACTORS.CHECK_CONFIG,
        input: ({ context }) => ({ sommelierResponse: context.sommelierResponse }),
        onDone: STATES.PARSING_CONFIG,
        onError: {
          actions: [REPORT.CONFIG_EMPTY, ACTIONS.USE_FALLBACK_CONFIG],
          target: STATES.PARSING_CONFIG,
        },
      },
    },
    [STATES.PARSING_CONFIG]: {
      invoke: {
        src: ACTORS.PARSE_CONFIG,
        input: ({ context }) => ({
          sommelierResponse: context.sommelierResponse,
        }),
        onDone: {
          actions: [
            { type: ACTIONS.SET_CONFIG, params: ({ event }): BordeauxConfig => event.output },
            REPORT.CONFIG_SUCCESS,
            ACTIONS.SEND_AB_TEST_TO_FREYR,
            ACTIONS.DECIDE_TEST_ACTIVATION_DISTANCE,
            ACTIONS.DECIDE_V2_TEST_ACTIVATION_DISTANCE,
            ACTIONS.DECIDE_AVOIDANCE_DISTANCE,
            ACTIONS.DECIDE_TEST_PUBX,
            ACTIONS.DECIDE_TEST_ID_SERVICE_ACTIVATION,
            ACTIONS.DECIDE_THIRD_PARTY_CONFIG,
            ACTIONS.DECIDE_PAGE_ADUNIT_PATH,
            ACTIONS.DECIDE_VALID_ADUNITS,
            ACTIONS.DECIDE_ROADBLOCK_INCREMENTALS,
            ACTIONS.UPDATE_API_CONFIG,
            ACTIONS.STORE_HYBRID_TEST_SESSIONS,
            ACTIONS.DECIDE_TEST_AUCTION_TIMEOUTS,
          ],
          target: STATES.RETRIEVING_CONSENT,
        },
        onError: {
          actions: [
            ACTIONS.USE_PARSED_FALLBACK_CONFIG,
            { type: REPORT.CONFIG_PARSE_FAILURE, params: ({ event }) => event.error as Error },
            ACTIONS.SEND_AB_TEST_TO_FREYR,
            ACTIONS.DECIDE_TEST_ACTIVATION_DISTANCE,
            ACTIONS.DECIDE_AVOIDANCE_DISTANCE,
            ACTIONS.DECIDE_THIRD_PARTY_CONFIG,
            ACTIONS.DECIDE_PAGE_ADUNIT_PATH,
            ACTIONS.DECIDE_VALID_ADUNITS,
            ACTIONS.DECIDE_ROADBLOCK_INCREMENTALS,
            ACTIONS.UPDATE_API_CONFIG,
          ],
          target: STATES.RETRIEVING_CONSENT,
        },
      },
    },
    [STATES.RETRIEVING_CONSENT]: {
      entry: [
        ACTIONS.SETUP_CUSTOM_ACTIVATIONS,
        ACTIONS.DECIDE_REFRESH_TIME,
        REPORT.CONSENT_REQUEST,
      ],
      invoke: [
        {
          src: ACTORS.GET_USP_CONSENT,
          onDone: {
            actions: [
              { type: ACTIONS.SET_USP_CONSENT, params: ({ event }) => event.output },
              {
                type: ACTIONS.UPDATE_PAGE_TARGETING,
                params: ({ event }) => ({
                  _usp_status: event.output.status,
                }),
              },
              ACTIONS.RAISE_CONSENT_DONE,
            ],
          },
          onError: {
            target: STATES.ERROR,
          },
        },
        {
          src: ACTORS.GET_GDPR_CONSENT_MACHINE,
          onDone: {
            actions: [
              REPORT.CONSENT_SUCCESS,
              { type: ACTIONS.SET_GDPR_CONSENT, params: ({ event }) => event.output },
              {
                type: ACTIONS.UPDATE_PAGE_TARGETING,
                params: ({ event }) => ({
                  _gdpr_status: event.output.status,
                }),
              },
              ACTIONS.RAISE_CONSENT_DONE,
            ],
          },
          onError: {
            actions: REPORT.CONSENT_FAILURE,
            target: STATES.ERROR,
          },
        },
        {
          src: ACTORS.GET_GPP_CONSENT,
          onDone: {
            actions: [
              { type: ACTIONS.SET_GPP_CONSENT, params: ({ event }) => event.output },
              {
                type: ACTIONS.UPDATE_PAGE_TARGETING,
                params: ({ event }) => ({
                  _gpp_status: event.output.status,
                }),
              },
              ACTIONS.RAISE_CONSENT_DONE,
            ],
          },
          onError: {
            target: STATES.ERROR,
          },
        },
      ],
      on: {
        [CMP_EVENTS.PENDING]: {
          actions: REPORT.CONSENT_PENDING,
        },
        [CMP_EVENTS.LOADED]: {
          actions: REPORT.CONSENT_LOADED,
        },
        [CMP_EVENTS.MOCKED]: {
          actions: REPORT.CONSENT_MOCKED,
        },
        [EVENTS.CONSENT_DONE]: {
          guard: GUARDS.CONSENT_DONE,
          target: STATES.LOADING_THIRD_PARTIES,
        },
      },
    },
    [STATES.LOADING_THIRD_PARTIES]: {
      entry: [
        REPORT.THIRD_PARTY_REQUEST,
        ACTIONS.DECIDE_LIVE_INTENT_USER_SYNC,
        ACTIONS.CREATE_THIRD_PARTY_MACHINES,
      ],
      invoke: {
        src: ACTORS.WAIT_FOR_ALL_THIRD_PARTIES,
        input: ({ context }) => ({ thirdPartyMachines: context.thirdPartyMachines }),
        onDone: {
          actions: [
            REPORT.THIRD_PARTY_SUCCESS,
            { type: ACTIONS.SET_THIRD_PARTY_RESULTS, params: ({ event }) => event.output },
            ACTIONS.ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING,
            ACTIONS.THIRD_PARTIES_READY,
          ],
          target: STATES.DECIDING_PAGE_STYLE_CONSTANTS,
        },
        onError: STATES.ERROR,
      },
      on: {
        [ThirdPartyEvent.THIRD_PARTY_REQUEST]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.REQUEST> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.REQUEST,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyEvent.THIRD_PARTY_SUCCESS]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.SUCCESS> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.SUCCESS,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyEvent.THIRD_PARTY_TIMEOUT]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.TIMEOUT> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.TIMEOUT,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyEvent.THIRD_PARTY_FAILURE]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.FAILURE> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.FAILURE,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
      },
    },
    [STATES.DECIDING_PAGE_STYLE_CONSTANTS]: {
      invoke: {
        src: ACTORS.READ_PAGE_STYLES,
        onDone: {
          actions: { type: ACTIONS.SET_PAGE_STYLE_CONSTANTS, params: ({ event }) => event.output },
          target: STATES.HANDLING_SLOTS,
        },
      },
    },

    [STATES.HANDLING_SLOTS]: {
      entry: [
        ACTIONS.SETUP_USER_SYNC,
        ACTIONS.DECIDE_HYBRID_AB_TEST_TARGETING,
        ACTIONS.START_ANCHORED_LOGIC,
        ACTIONS.SETUP_AD_MANAGER,
      ],
      invoke: {
        src: ACTORS.SLOTIFY_MACHINE,
        id: 'slotify',
        input: ({ context }) => context,
        onError: STATES.ERROR,
      },
      on: {
        [STANDARD_ADS_EVENTS_OUT.FAILURE]: {
          target: STATES.ERROR,
        },
      },
    },
    [STATES.ERROR]: {
      entry: ACTIONS.HANDLE_ERROR,
    },
  },
  on: {
    ...report.reportEvents,
    ...apiEvents,
    [AD_FEATURES_EVENTS.FULL_WIDTH]: {
      actions: forwardEventData(ACTIONS.AD_FEATURE_FULL_WIDTH),
    },
    [AD_FEATURES_EVENTS.POP_OUT]: {
      actions: forwardEventData(ACTIONS.AD_FEATURE_POP_OUT),
    },
    [AD_FEATURES_EVENTS.MULTI_FRAME]: {
      actions: forwardEventData(ACTIONS.AD_FEATURE_MULTI_FRAME),
    },
    [AD_FEATURES_EVENTS.EXPAND_HEIGHT]: {
      actions: forwardEventData(ACTIONS.AD_FEATURE_EXPAND_HEIGHT),
    },
    [AD_FEATURES_EVENTS.CLOSE_BUTTON]: {
      actions: forwardEventData(ACTIONS.AD_FEATURE_CLOSE_BUTTON),
    },
    [EVENTS.SET_HYBRID_ABTEST_TARGETING]: {
      actions: [
        forwardEventData(ACTIONS.SET_HYBRID_AB_TEST_TARGETING),
        forwardEventData(ACTIONS_RECORD.HYBRID_ABTEST_TARGETING),
      ],
    },
    [EVENTS.SET_HYBRID_ID]: {
      actions: [
        forwardEventData(ACTIONS.SET_HYBRID_ID),
        forwardEventData(ACTIONS_RECORD.HYBRID_ID),
        ACTIONS.UPDATE_API_HYBRID_ID,
        ACTIONS.SEND_USER_IDS_TO_FREYR,
      ],
    },
    [EVENTS.SET_CFT_PARAMETERS]: {
      actions: [
        forwardEventData(ACTIONS.SET_CFT_PARAMATERS),
        forwardEventData(ACTIONS_RECORD.CFT_PARAMETERS),
      ],
    },
    [EVENTS.PAGE_UNLOAD]: {
      guard: GUARDS.TIMING_COLLECTION_ENABLED,
      actions: forwardEventData(ACTIONS.REPORT_PAGE_UNLOAD),
    },
    [EVENTS.PAGE_LOAD]: {
      actions: forwardEventData(ACTIONS.REPORT_PAGE_LOAD),
    },
    [EVENTS.OPEN_AD_TOOL]: {
      actions: ACTIONS.LOAD_AD_TOOL,
    },
    [EVENTS.CHECK_ROADBLOCK_STATUS]: {
      guard: and([GUARDS.ROADBLOCK_UNDECIDED, GUARDS.ROADBLOCK_IS_DECIDABLE]),
      actions: [ACTIONS.DECIDE_ROADBLOCK_STATUS],
    },
    [EVENTS.SET_ROADBLOCK_STATUS]: {
      actions: [
        forwardEventData(ACTIONS.SET_ROADBLOCK),
        ACTIONS.CUSTOM_VIDEO_BEHAVIOUR,
        forwardEventData(ACTIONS.UPDATE_REFRESH_ROADBLOCK),
        forwardEventData(ACTIONS.UPDATE_SLOTIFY_ROADBLOCK),
        forwardEventData(ACTIONS.UPDATE_API_ROADBLOCK),
      ],
    },
    [EVENTS.AUTOMATIC_REFRESH]: [
      {
        guard: {
          type: GUARDS.HAS_ADS,
          params: ({ event: { data: ads } }) => ads,
        },
        actions: [
          {
            type: ACTIONS.RESET_ADS,
            params: ({ event: { data: ads } }) => ({ ads }),
          },
          {
            type: ACTIONS.REFRESH_ADS,
            params: ({ event: { data: ads } }) => ({ ads }),
          },
        ],
      },
    ],
    [EVENTS.TRIGGER_AUTOMATIC_REFRESH]: {
      actions: [ACTIONS.UPDATE_ADS_VIEWED_TIME, ACTIONS.RAISE_AUTOMATIC_REFRESH],
    },

    [EVENTS.REQUEST_BORDEAUX_DATA]: {
      actions: sendTo<BordeauxMachineContext['externalApiMachine'], RequestBordeauxDataEvent>(
        ({ context }) => context.externalApiMachine,
        ({ context, event }): ProvideBordeauxDataEvent => ({
          type: EVENTS.PROVIDE_BORDEAUX_DATA,
          data: {
            value: accessObjectPath(context, event.data.property),
            requestId: event.data.requestId,
          },
        }),
      ),
    },

    [EVENTS.REQUEST_BORDEAUX_CONTEXT]: {
      actions: sendTo<BordeauxMachineContext['externalApiMachine'], RequestBordeauxContextEvent>(
        ({ context }) => context.externalApiMachine,
        ({ context, event }): ProvideBordeauxContextEvent => ({
          type: EVENTS.PROVIDE_BORDEAUX_CONTEXT,
          data: {
            value: context,
            requestId: event.data.requestId,
          },
        }),
      ),
    },
    [EVENTS.HIDE_ANCHORED_ADS]: [
      {
        guard: ({ event }) => Boolean(event.data?.infiniteScrollIntersection),
        actions: [ACTIONS.HIDE_ANCHORED, ACTIONS.ENABLE_ANCHORED_REFRESH],
      },
      {
        actions: [ACTIONS.HIDE_ANCHORED],
      },
    ],
    [EVENTS.SHOW_ANCHORED_ADS]: [
      {
        guard: ({ event }) => Boolean(event.data?.infiniteScrollIntersection),
        actions: [ACTIONS.SHOW_ANCHORED, ACTIONS.DISABLE_ANCHORED_REFRESH],
      },
      {
        actions: [ACTIONS.SHOW_ANCHORED],
      },
    ],
    [AD_MANAGER_EVENTS_OUT.SLOT_VISIBILITY_CHANGED]: {
      actions: forwardEventData(ACTIONS.UPDATE_AD_VIEWABILITY),
    },
    [AD_MANAGER_EVENTS_OUT.SLOT_ON_LOAD]: {
      actions: [
        forwardEventData(ACTIONS.REPORT_AD_LOAD),
        forwardEventData(ACTIONS.RAISE_REPORT_AD_LOAD),
      ],
    },
    [AD_MANAGER_EVENTS_OUT.SLOT_IMPRESSION_VIEWABLE]: {
      actions: forwardEventData(ACTIONS.MARK_AD_VIEWED),
    },
    [AD_MANAGER_EVENTS_OUT.SLOT_RENDER_ENDED]: {
      actions: [forwardEventData(ACTIONS.FINALISE_AD), ACTIONS.RAISE_CHECK_ROADBLOCK_STATUS],
    },
    [EVENTS.REQUEST_AUCTION]: {
      actions: {
        type: ACTIONS.AD_MANAGER_AUCTION,
        params: ({
          event: {
            data: { ads },
          },
        }) => ads,
      },
    },
    [AD_MANAGER_EVENTS_OUT.AUCTION_CREATED]: [
      {
        guard: GUARDS.HAS_ENOUGH_CONSENT_FOR_AUCTION,
        actions: [
          ACTIONS.UPDATE_AD_MANAGER_TARGETING,
          {
            type: ACTIONS.PRE_PROCESS_AUCTION,
            params: ({
              event: {
                data: { auctionId },
              },
            }) => auctionId,
          },
        ],
      },
      {
        actions: [ACTIONS.UPDATE_AD_MANAGER_TARGETING, forwardEventData(ACTIONS.RAISE_AUCTION_END)],
      },
    ],
    [EVENTS.AUCTION_PROCESSED]: {
      actions: [
        forwardEventData(ACTIONS.RAISE_AUCTION_END),
        forwardEventData(ACTIONS.RAISE_AD_MANAGER_AUCTION_PROCESSED),
      ],
    },

    [EVENTS.HIDE_BIGTOP_ADS]: {
      actions: { type: ACTIONS.CHANGE_BIGTOP_DISPLAY, params: { allowPopout: false } },
    },
    [EVENTS.SHOW_BIGTOP_ADS]: {
      actions: { type: ACTIONS.CHANGE_BIGTOP_DISPLAY, params: { allowPopout: true } },
    },
    [EVENTS.CHECK_BIGTOP_ALLOWED]: [
      {
        guard: GUARDS.SCROLLED_PAST_FIRST_TANDEM,
        actions: raise({ type: EVENTS.HIDE_BIGTOP_ADS }),
      },
      {
        actions: raise({ type: EVENTS.SHOW_BIGTOP_ADS }),
      },
    ],

    [SLOTIFY_EVENTS_OUT.SLOT_POSITIONED]: {
      guard: forwardEventData(GUARDS.SLOT_IS_BEFORE_FIRST_TANDEM),
      actions: [
        assign({
          firstTandemAdPosition: ({
            event: {
              data: { position },
            },
          }) => position.y,
        }),
        raise({
          type: EVENTS.CHECK_BIGTOP_ALLOWED,
        }),
      ],
    },
    [SLOTIFY_EVENTS_OUT.SLOT_VIEWABILITY_CHANGED]: {
      actions: raise({
        type: EVENTS.CHECK_BIGTOP_ALLOWED,
      }),
    },
  },
});

export default bordeauxMachine;
