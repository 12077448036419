import { assign, fromCallback, fromPromise, raise, setup, sendParent, not, and } from 'xstate';

import { supportsPassive, getEnv, debounce } from '@repo/utils';

import {
  AnyAutomaticRefreshEvent,
  AnyUserActivityEvent,
  AutomaticRefreshGuardArgs,
  REFRESH_GUARDS,
  RefreshMachineContext,
  REFRESH_EVENTS,
  REFRESH_STATES,
  USER_ACTIVITY_EVENTS,
  TriggerAutomaticRefreshEvent,
  EVENTS,
  REFRESH_ACTORS,
  RefreshMachineDefinition,
} from '@repo/shared-types';

const userActivityMachine = fromCallback<AnyUserActivityEvent>(({ sendBack }) => {
  const env = getEnv();

  const sendMouseMoveEvent = debounce(
    (timestamp: number) =>
      sendBack({
        type: USER_ACTIVITY_EVENTS.MOUSE_MOVE,
        data: timestamp,
      }),
    100,
  );
  const sendScrollEvent = debounce(
    (timestamp: number) =>
      sendBack({
        type: USER_ACTIVITY_EVENTS.SCROLL,
        data: timestamp,
      }),
    100,
  );

  env.document.addEventListener(
    'visibilitychange',
    () => {
      sendBack({
        type: USER_ACTIVITY_EVENTS.DOCUMENT_VISIBILITY,
        data: !document.hidden,
      });
    },
    false,
  );

  env.addEventListener('mousemove', (): void => {
    sendMouseMoveEvent(Date.now());
  });

  env.addEventListener(
    'scroll',
    (): void => {
      sendScrollEvent(Date.now());
    },
    supportsPassive ? { passive: true } : false,
  );
});

const featureEnabled = ({ context }: AutomaticRefreshGuardArgs): boolean => {
  if (context.isRoadblocked) return false;
  if (context.refreshPaused) return false;

  return context.featureEnabled;
};
const userActiveRecently = ({ context }: AutomaticRefreshGuardArgs): boolean => {
  if (!context.documentVisible) return false;
  const timestamp = Date.now();
  if (timestamp - context.mouseMoved < context.activityTimeout) return true;
  if (timestamp - context.scrolled < context.activityTimeout) return true;
  return false;
};

const waitInterval = fromPromise<
  void,
  {
    updateInterval: number;
  }
>(({ input }) => new Promise(resolve => setTimeout(resolve, input.updateInterval)));

export enum REFRESH_ACTIONS {
  CREATE_USER_ACTIVITY_MACHINE = 'CREATE_USER_ACTIVITY_MACHINE',
  TRIGGER_FEATURE_CHECK = 'TRIGGER_FEATURE_CHECK',
  SET_DOCUMENT_VISIBLE = 'SET_DOCUMENT_VISIBLE',
  SET_FEATURE_ENABLED = 'SET_FEATURE_ENABLED',
  SET_MOUSE_MOVED = 'SET_MOUSE_MOVED',
  SET_SCROLLED = 'SET_SCROLLED',
  SET_ROADBLOCK = 'SET_ROADBLOCK',
  SET_REFRESH_PAUSED = 'SET_REFRESH_PAUSED',
  TRIGGER_REFRESH = 'TRIGGER_REFRESH',
}

const refreshMachine: RefreshMachineDefinition = setup({
  actors: {
    [REFRESH_ACTORS.USER_ACTIVITY]: userActivityMachine,
    [REFRESH_ACTORS.WAIT_INTERVAL]: waitInterval,
  },
  actions: {
    [REFRESH_ACTIONS.CREATE_USER_ACTIVITY_MACHINE]: assign({
      userActivityMachine: ({ spawn }) =>
        spawn(REFRESH_ACTORS.USER_ACTIVITY, { id: 'userActivityMachine' }),
    }),
    [REFRESH_ACTIONS.TRIGGER_FEATURE_CHECK]: raise({
      type: REFRESH_EVENTS.CHECK,
    }),
    [REFRESH_ACTIONS.SET_DOCUMENT_VISIBLE]: assign({
      documentVisible: (_, value: boolean) => value,
    }),
    [REFRESH_ACTIONS.SET_FEATURE_ENABLED]: assign({
      featureEnabled: (_, value: boolean) => value,
    }),
    [REFRESH_ACTIONS.SET_MOUSE_MOVED]: assign({
      mouseMoved: (_, value: number) => value,
    }),
    [REFRESH_ACTIONS.SET_SCROLLED]: assign({
      scrolled: (_, value: number) => value,
    }),
    [REFRESH_ACTIONS.SET_ROADBLOCK]: assign({
      isRoadblocked: (_, value: boolean) => value,
    }),
    [REFRESH_ACTIONS.SET_REFRESH_PAUSED]: assign({
      refreshPaused: (_, value: boolean) => value,
    }),
    [REFRESH_ACTIONS.TRIGGER_REFRESH]: sendParent({
      type: EVENTS.TRIGGER_AUTOMATIC_REFRESH,
    } as TriggerAutomaticRefreshEvent),
  },
  types: {} as {
    context: RefreshMachineContext;
    events: AnyAutomaticRefreshEvent;
  },
  guards: {
    [REFRESH_GUARDS.USER_ACTIVE_RECENTLY]: userActiveRecently,
    [REFRESH_GUARDS.FEATURE_DISABLED]: actionArgs => !featureEnabled(actionArgs),
    [REFRESH_GUARDS.FEATURE_ENABLED]: featureEnabled,
  },
}).createMachine({
  context: {
    activityTimeout: 60000,
    updateInterval: 1000,

    documentVisible: true,
    mouseMoved: 0,
    scrolled: 0,

    refreshPaused: false,
    isRoadblocked: false,
    featureEnabled: true,

    userActivityMachine: {} as RefreshMachineContext['userActivityMachine'],
  },
  initial: REFRESH_STATES.SETUP,
  states: {
    [REFRESH_STATES.SETUP]: {
      entry: REFRESH_ACTIONS.CREATE_USER_ACTIVITY_MACHINE,
      always: [
        {
          guard: REFRESH_GUARDS.FEATURE_ENABLED,
          target: REFRESH_STATES.WAIT,
        },
        { target: REFRESH_STATES.STOP },
      ],
    },
    [REFRESH_STATES.WAIT]: {
      invoke: {
        src: REFRESH_ACTORS.WAIT_INTERVAL,
        input: ({ context }) => ({ updateInterval: context.updateInterval }),
        onDone: REFRESH_STATES.UPDATE,
      },
      on: {
        [REFRESH_EVENTS.CHECK]: {
          guard: REFRESH_GUARDS.FEATURE_DISABLED,
          target: REFRESH_STATES.STOP,
        },
      },
    },
    [REFRESH_STATES.UPDATE]: {
      always: [
        {
          guard: and([REFRESH_GUARDS.FEATURE_ENABLED, REFRESH_GUARDS.USER_ACTIVE_RECENTLY]),
          actions: REFRESH_ACTIONS.TRIGGER_REFRESH,
          target: REFRESH_STATES.WAIT,
        },
        {
          guard: not(REFRESH_GUARDS.FEATURE_ENABLED),
          target: REFRESH_STATES.STOP,
        },
      ],
    },
    [REFRESH_STATES.STOP]: {
      on: {
        [REFRESH_EVENTS.CHECK]: {
          guard: REFRESH_GUARDS.FEATURE_ENABLED,
          target: REFRESH_STATES.WAIT,
        },
      },
    },
  },
  on: {
    [USER_ACTIVITY_EVENTS.DOCUMENT_VISIBILITY]: {
      actions: {
        type: REFRESH_ACTIONS.SET_DOCUMENT_VISIBLE,
        params: ({ event: { data } }) => data,
      },
    },
    [USER_ACTIVITY_EVENTS.MOUSE_MOVE]: {
      actions: { type: REFRESH_ACTIONS.SET_MOUSE_MOVED, params: ({ event: { data } }) => data },
    },
    [USER_ACTIVITY_EVENTS.SCROLL]: {
      actions: { type: REFRESH_ACTIONS.SET_SCROLLED, params: ({ event: { data } }) => data },
    },
    [REFRESH_EVENTS.SET_ROADBLOCK]: {
      actions: [
        { type: REFRESH_ACTIONS.SET_ROADBLOCK, params: ({ event: { data } }) => data },
        REFRESH_ACTIONS.TRIGGER_FEATURE_CHECK,
      ],
    },
    [REFRESH_EVENTS.SET_REFRESH_PAUSED]: {
      actions: [
        { type: REFRESH_ACTIONS.SET_REFRESH_PAUSED, params: ({ event: { data } }) => data },
        REFRESH_ACTIONS.TRIGGER_FEATURE_CHECK,
      ],
    },
    [REFRESH_EVENTS.SET_FEATURE_ENABLED]: {
      actions: [
        { type: REFRESH_ACTIONS.SET_FEATURE_ENABLED, params: ({ event: { data } }) => data },
        REFRESH_ACTIONS.TRIGGER_FEATURE_CHECK,
      ],
    },
  },
});

export default refreshMachine;
