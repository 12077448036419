import {
  Slot,
  FEATURE,
  DataObject,
  EVENTS as BDX_EVENTS,
  SLOTIFY_ACTIONS,
  AD_AFFINITY_FAILED_REASONS,
  SLOTIFY_EVENTS,
  STANDARD_ADS_EVENTS_IN,
  Ad,
  AdDefinition,
  SlotDefinition,
  SlotifyActions,
  SlotifyGuards,
  AdMatch,
  AnySlotifyEvent,
  SLOTIFY_ACTORS,
  SlotifyMachineContext,
  AdditionalAvoidance,
} from '@repo/shared-types';
import { log, querySelectorAll } from '@repo/utils';
import { ActionFunction, sendParent } from 'xstate';
import replaceConstantsInSlot from 'ad-framework/slot/replace-constants';
import { watchExclusionZones } from '../exclusion';
import { adsLoaded } from 'state/report';
import { reorderAds } from './reorderAds';
import assign, { assignParams } from '../proxy/assign';
import raise from '../proxy/raise';
import sendTo from '../proxy/send-to';

const addAdditionalWatchers = (slot: DataObject<Slot>): void => {
  const additionalAvoidance = slot.getProperty('additionalAvoidance');

  additionalAvoidance.forEach((additionalAvoidanceConfig: AdditionalAvoidance) => {
    const elementsToAvoid = querySelectorAll<HTMLElement>(additionalAvoidanceConfig.hook);
    elementsToAvoid.forEach(element => {
      additionalAvoidanceConfig.elements.push(element);
    });
  });
};

const slotifyActions: Record<
  SLOTIFY_ACTIONS,
  ActionFunction<
    SlotifyMachineContext,
    AnySlotifyEvent,
    AnySlotifyEvent,
    any,
    any,
    SlotifyActions,
    SlotifyGuards,
    any,
    any
  >
> = {
  [SLOTIFY_ACTIONS.REPORT_AD_AFFINITY_FAILED]: (
    {
      context: {
        config: {
          placement: {
            slots: { static: slotDefinitions },
          },
        },
      },
    },
    { adDefinition, reason }: { adDefinition: AdDefinition; reason: AD_AFFINITY_FAILED_REASONS },
  ) => {
    switch (reason) {
      case AD_AFFINITY_FAILED_REASONS.ABSENT:
        log.error(`Slotify standard handling error - Affinity undefined for ${adDefinition.name}`);
        break;
      case AD_AFFINITY_FAILED_REASONS.NO_SLOT: {
        const slotDefinition = slotDefinitions.find(
          searchSlot => searchSlot.name === adDefinition.affinitySlotID,
        );
        if (slotDefinition) {
          if (!slotDefinition.ignoreErrors) {
            log.error(
              `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but the slot could not be created.`,
            );
          }
        } else {
          log.error(
            `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but no matching slot was configured.`,
          );
        }
        break;
      }
      case AD_AFFINITY_FAILED_REASONS.SLOT_FILLED:
        log.error(`Slotify standard handling error - Slot already filled for ${adDefinition.name}`);
        break;
      default:
        log.error(
          `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but something went wrong.`,
        );
    }
  },
  [SLOTIFY_ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS]: raise({
    type: SLOTIFY_EVENTS.INCREMENTAL_ADS_ENABLED,
  }),
  [SLOTIFY_ACTIONS.RAISE_ROADBLOCK_READY]: raise({ type: SLOTIFY_EVENTS.ROADBLOCK_READY }),

  [SLOTIFY_ACTIONS.RAISE_ENABLE_STANDARD_ADS]: raise({ type: STANDARD_ADS_EVENTS_IN.ENABLED }),
  [SLOTIFY_ACTIONS.ENABLE_INCREMENTAL_ADS]: assign({
    features: ({ context: { features } }) => ({
      ...features,
      [FEATURE.ADS_INCREMENTAL]: true,
    }),
  }),
  [SLOTIFY_ACTIONS.ENABLE_STANDARD_ADS]: assign({
    features: ({ context: { features } }) => ({
      ...features,
      [FEATURE.ADS_STANDARD]: true,
    }),
  }),
  [SLOTIFY_ACTIONS.CHECK_ROADBLOCK_STATUS]: sendParent({
    type: BDX_EVENTS.CHECK_ROADBLOCK_STATUS,
  }),
  [SLOTIFY_ACTIONS.UPDATE_ROADBLOCK_STATUS]: assignParams<boolean>({
    isRoadblock: (_, newStatus) => newStatus,
  }),

  [SLOTIFY_ACTIONS.POSITION_SLOT_ELEMENT]: assignParams<DataObject<Slot>>({
    slotPositioners: ({ context, spawn }, slot) => [
      ...context.slotPositioners,
      spawn(SLOTIFY_ACTORS.SLOT_POSITIONER, {
        input: {
          slot,
        },
      }),
    ],
  }),
  [SLOTIFY_ACTIONS.ADD_SLOT]: assignParams<DataObject<Slot>>({
    slots: ({ context: { slots } }, slot) => {
      slots.push(slot);
      return slots;
    },
  }),
  [SLOTIFY_ACTIONS.CREATE_ADDITIONAL_SLOT_WATCHERS]: (_, slot: DataObject<Slot>) => {
    addAdditionalWatchers(slot);
  },
  [SLOTIFY_ACTIONS.CREATE_SLOT_WATCHER]: assignParams<DataObject<Slot>>({
    slotWatchers: ({ context, spawn }, slot) => [
      ...context.slotWatchers,
      spawn(SLOTIFY_ACTORS.SLOT_WATCHER, {
        id: `slotWatcher-${slot.getProperty('id')}`,
        input: {
          slot,
          activationDistance: context.activationDistance,
        },
      }),
    ],
  }),
  [SLOTIFY_ACTIONS.REPORT_SLOT_HOOK_FAILED]: (_, slotDefinition: SlotDefinition) => {
    if (slotDefinition.ignoreErrors) return;
    if (slotDefinition.multiple) {
      log.error(
        `Static slot ${slotDefinition.name} could not find any elements with hook '${slotDefinition.hook}'.`,
      );
    } else {
      log.error(
        `Static slot ${slotDefinition.name} could not find an element with hook '${slotDefinition.hook}'.`,
      );
    }
  },
  [SLOTIFY_ACTIONS.CREATE_DYNAMIC_SLOT_GENERATOR]: assign({
    slotGenerator: ({ spawn, context }) =>
      spawn(SLOTIFY_ACTORS.DYNAMIC_SLOT_GENERATOR, {
        id: 'dynamicSlotMachine',
        input: {
          generatedSlotDefinitions: context.config.placement.slots.generated.map(
            replaceConstantsInSlot(context.pageStyleConstants),
          ),
          dynamicSlotDefinitions: context.config.placement.slots.dynamic.map(
            replaceConstantsInSlot(context.pageStyleConstants),
          ),
          automaticDynamic: context.automaticDynamic,
        },
      }),
  }),
  [SLOTIFY_ACTIONS.WATCH_EXCLUSION_ZONES]: watchExclusionZones,

  [SLOTIFY_ACTIONS.INCREMENT_AD_TYPE_COUNTERS]: assignParams<Array<DataObject<Ad>>>({
    adTypeCounters: ({ context: { adTypeCounters } }, newAds) =>
      newAds.reduce(
        (adTypeCounters, ad) => ({
          ...adTypeCounters,
          [ad.getProperty('name')]: 1 + (adTypeCounters[ad.getProperty('name')] || 0),
        }),
        adTypeCounters,
      ),
  }),
  [SLOTIFY_ACTIONS.INCREMENT_AD_COUNTER]: assignParams<Array<DataObject<Ad>>>({
    adCounter: ({ context: { adCounter } }, newAds) => adCounter + newAds.length,
  }),
  [SLOTIFY_ACTIONS.INCREMENT_ROADBLOCK_INCREMENTAL_COUNTER]: assignParams<Array<DataObject<Ad>>>({
    roadblockIncrementalCount: ({ context: { isRoadblock, roadblockIncrementalCount } }, newAds) =>
      roadblockIncrementalCount +
      (isRoadblock ? newAds.filter(ad => !(ad && ad.getProperty('nativeContent'))).length : 0),
  }),
  [SLOTIFY_ACTIONS.ADD_SLOT_TO_STACK]: assignParams<{ slot: DataObject<Slot> }>({
    slotStack: ({ context: { slotStack } }, { slot }) => [...slotStack, slot],
  }),
  [SLOTIFY_ACTIONS.REORDER_ADS]: assign({ newAds: reorderAds }),
  [SLOTIFY_ACTIONS.TRIGGER_DYNAMIC_SLOT_REFRESH]: sendTo('dynamicSlotMachine', {
    type: 'refresh',
  }),
  [SLOTIFY_ACTIONS.INCREMENT_BATCH_COUNTER]: assign({
    adMatches: [],
    newAds: [],
    batchCounter: ({ context: { batchCounter } }) => batchCounter + 1,
  }),
  [SLOTIFY_ACTIONS.TRIGGER_AUCTION]: sendParent(({ context: { newAds } }) => ({
    type: BDX_EVENTS.REQUEST_AUCTION,
    data: { ads: newAds },
  })),
  [SLOTIFY_ACTIONS.REPORT_FIRST_AD_LOAD]: ({ context: { batchCounter } }) => {
    if (batchCounter !== 0) return;
    adsLoaded();
  },
  [SLOTIFY_ACTIONS.PUT_NEW_ADS_IN_STORE]: ({ context: { ads, newAds } }) => {
    newAds.forEach(ad => {
      ads.push(ad);
    });
  },
  [SLOTIFY_ACTIONS.SET_NEW_ADS]: assignParams<Array<DataObject<Ad>>>({
    newAds: (_, ads) => ads,
  }),
  [SLOTIFY_ACTIONS.POP_SLOT_STACK]: assign({
    slotStack: ({ context: { slotStack } }) => slotStack.slice(1),
  }),
  [SLOTIFY_ACTIONS.SET_AD_MATCHES]: assignParams<Array<AdMatch>>({
    adMatches: (_, adMatches) => adMatches,
  }),
};
export default slotifyActions;
