export enum EVENTS {
    SET_HYBRID_ABTEST_TARGETING = 'SET_HYBRID_ABTEST_TARGETING',
    SET_HYBRID_ID = 'SET_HYBRID_ID',
    SET_CFT_PARAMETERS = 'SET_CFT_PARAMETERS',
    SET_ROADBLOCK_STATUS = 'SET_ROADBLOCK_STATUS',
    PAGE_UNLOAD = 'PAGE_UNLOAD',
    PAGE_LOAD = 'PAGE_LOAD',
    OPEN_AD_TOOL = 'OPEN_AD_TOOL',
    AUTOMATIC_REFRESH = 'AUTOMATIC_REFRESH',
    TRIGGER_AUTOMATIC_REFRESH = 'TRIGGER_AUTOMATIC_REFRESH',
    CHECK_ROADBLOCK_STATUS = 'CHECK_ROADBLOCK_STATUS',
    SET_EXTERNAL_API = 'SET_EXTERNAL_API',
  
    REQUEST_BORDEAUX_DATA = 'REQUEST_BORDEAUX_DATA',
    PROVIDE_BORDEAUX_DATA = 'PROVIDE_BORDEAUX_DATA',
  
    REQUEST_BORDEAUX_CONTEXT = 'REQUEST_BORDEAUX_CONTEXT',
    PROVIDE_BORDEAUX_CONTEXT = 'PROVIDE_BORDEAUX_CONTEXT',
  
    CONSENT_DONE = 'CONSENT_DONE',
  
    HIDE_ANCHORED_ADS = 'HIDE_ANCHORED_ADS',
    SHOW_ANCHORED_ADS = 'SHOW_ANCHORED_ADS',

    HIDE_BIGTOP_ADS = 'HIDE_BIGTOP_ADS',
    SHOW_BIGTOP_ADS = 'SHOW_BIGTOP_ADS',
    CHECK_BIGTOP_ALLOWED = 'CHECK_BIGTOP_ALLOWED',

    REQUEST_AUCTION = 'REQUEST_AUCTION',
    AUCTION_PROCESSED = 'AUCTION_PROCESSED',
}
